import React, { useContext, useEffect,useState } from 'react'
import { useParams, Navigate,redirect } from "react-router-dom";
import DataContext from "../../Data/DataContext";
import moment from 'moment'
import axios from 'axios';
import notFoundDoc from './../../images/not-found.png'

function RecordHistory() {
    const params = useParams();
    const dataC = useContext(DataContext)

    const [bill, setBill] = useState({})

    useEffect(() => {
        getHistoryById()
    }, [])

    const getHistoryById = async () =>{
        // console.log(encodeURIComponent(p))
        const response = await axios.get(`${dataC.ApiURL}/order/id/${params.bill_id}`,{},
            {
                header:{'Content-Type' : 'application/json'}
            }
        ).then(response => {
            console.log(response.data.data)
            setBill(response.data.data)
            
            
        }).catch(error=>{
            console.log(error)
        });
    }
    
    return (
        <section id="history" className="hi-100" >
            <div className="container ">
            {Object.keys(bill).length>0 ?
                <div className=" p-2 pt-2 mt-2 rounded text-start bg-history-card">
                    <div className="row">
                        <div className="col col-6">  
                            <div className="row">
                                <div className="col col-12">
                                    <small className="text-light my-0 ps-2">โพยหวยเลขที่ #{bill.o_id} </small> 
                                </div><br/>
                                {bill.o_name?
                                    <div className="col col-12">
                                        <span className="pe-2 ps-2">ชื่อ : {bill.o_name}</span>
                                    </div>
                                :<></>}
                            </div>
                        </div>
                        <div className="col col-6">
                            <i  className="bi bi-bookmark-fill float-end"></i>
                        </div>  
                    </div>
                    <div className='bg-light text-dark rounded'>
                        <div className='row p-2'>
                            <div className='col-12'>{bill.lz_name}</div>
                            <div className='col-6'>
                                <div className='text-secondary text-sm'>ปิดขาย</div>
                                <div className='text-secondary text-sm'>ซื้อเมื่อ</div>
                                <div className='text-secondary text-sm'>ส่วนลด</div>
                            </div>
                            <div className='col-6 text-end'>
                                <div className='text-secondary text-sm'>{ moment(bill.o_time_open).format('DD-MM-YYYY HH:mm:ss') }</div>
                                <div className='text-dark text-sm'>{ moment(bill.o_time_bet).format('DD-MM-YYYY HH:mm:ss') }</div>
                                <div className='text-success text-sm'>฿ {dataC.money.format(bill.o_dis)} บาท</div>
                            </div>
                        </div>
                        <hr className='m-0'/>
                        <div className='row p-2 text-center'>
                            <div className={(bill.o_status==1?'col-3':'col-2')+' '}>
                                <div className='fs-5'><span  className={"badge "+(bill.o_status==1||bill.o_status==6?'bg-warning':(bill.o_status==2||bill.o_status==3?'bg-danger':(bill.o_status==4?'bg-success':'')))}>{bill.o_status_name}</span></div>
                            </div>
                            <div className={(bill.o_status==1?'col-6':'col-5')+' '}>
                                <div className='text-secondary text-sm'>เงินเดิมพัน</div>
                                <div className='text-dark fw-bold fs-6'>฿ {dataC.money.format(bill.o_total)} </div>
                            </div>
                            <div className={bill.o_status==1?'col-3':'col-5'}>
                                <div className='text-secondary text-sm'>ผลได้เสีย</div>
                                <div className='text-dark fw-bold fs-6'>฿ {dataC.money.format(bill.o_bonus)} </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-1'>
                        <button className='btn btn-sm btn-warning w-100' type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+bill.o_id} aria-expanded="false" aria-controls={"collapse"+bill.o_id} >
                            {/* onClick={(e)=>{fetchPlayHistoryDetails(bill.o_id)}} */}
                            แสดงรายละเอียด
                        </button>
                        <div  className="collapse" id={"collapse"+bill.o_id}>
                            <div className=" p-2 pt-2 mt-1 rounded text-start bg-light-gray" style={{}}>
                            {/* <div className='text-center text-dark my-0 ps-2 fw-bold'>2 ตัวบน </div> */}
                                <div className='bg-light text-dark rounded '>
                                    <div className='row'>
                                        {bill['list_number'].map((detail,i)=>{
                                            return (
                                                <div className='col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                                                    <div  className="card border m-2">
                                                        <div  className="card-body text-center row">
                                                            <div className='col-4'>
                                                                <div className='text-dark text-md'>{detail.lt_name}</div>
                                                                <div className='text-success fw-bold fs-3'>{detail.od_number} </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className='text-secondary text-sm'>ราคาแทง</div>
                                                                <div className='text-dark fw-bold text-sm'> ฿{dataC.money.format(detail.od_total)} </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className='text-secondary text-sm'>ราคาจ่าย</div>
                                                                <div className='text-dark fw-bold text-sm'> ฿{dataC.money.format(detail.od_pay)} </div>
                                                            </div>
                                                        </div>
                                                        <div className='card-footer bg-light'>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='fs-6'><span  className={"badge "+(detail.od_status==1||detail.od_status==6||detail.od_status==7?'bg-warning':(detail.od_status==2||detail.od_status==3?'bg-danger':(detail.od_status==4?'bg-success':'')))}>{detail.od_status_name}</span></div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='fs-6 fw-bold text-end text-success'>฿ {dataC.money.format(detail.od_bonus)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className='pt-5 mt-5 text-center'>
                <div>
                    <img src={notFoundDoc} style={{width:'75px'}} alt=""/>
                </div>
                <h3 className='mt-4'>ไม่พบรายการที่ค้นหา</h3>
            </div>}
        </div>
    </section>
    )
}

export default RecordHistory
import React,{useContext,useState,useEffect} from 'react'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import DataContext from '../../Data/DataContext';
import AssetsService from "../../Services/Assets.service";
import TransferService from '../../Services/Transfer.service';
import CreditBalance from './CreditBalance';
import Swal from 'sweetalert2';

function WithdrawComponent() {
    const money = Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2
    })
    const transferService = new TransferService()
    const dataConsumer = useContext(DataContext);
    const assetsService = new AssetsService()

    const navigate = useNavigate();

    const [amount, setAmount] = useState(dataConsumer.m_money)
    const bank = dataConsumer.arrBank.filter((bank,i)=>{
        return bank.id == dataConsumer.mb_bank;
    })[0]

    useEffect(()=>{
        getCredit()
    },[])
    
    const getCredit= (e)=>{
        assetsService.get_credit().then((result)=>{
          if(result.success){
            dataConsumer.setCredit(result.data.data.m_money,result.data.data.m_money_af,result.data.data.m_money_af_total)
          }
        })
    }

    const makeWithdraw = (e) => {
        e.preventDefault()
        const p_amount = e.target['withdraw-amount'].value
        dataConsumer.blockUI(true)
        transferService.withdrawing({p_amount:p_amount}).then((result)=>{
            dataConsumer.blockUI(false)
            if(result.success){
                Swal.fire({
                    text: result.message,
                    icon:'success',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'green'
                }).then((result)=>{
                    if(result.isConfirmed){
                        navigate("/transfer/withdraw") // window.location.replace("/transfer");
                    }
                })
            }else{
                Swal.fire({
                    text: result.message,
                    icon:'warning',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'red'
                })
            }
        })
    }

  return (
    <section id="withdraw" className="mt-5 pt-5">
        <div className="container">
            <CreditBalance />
            <hr/>
            <div className='form-withdraw'>
                <div className='row'>
                    <div className='col-sm-3'/>
                    <div className='col col-sm-6 text-on-background'>
                        <h5>ทำรายการถอนเงิน</h5>
                        <form onSubmit={makeWithdraw}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">จำนวนเงินที่ต้องการถอน</label>
                                <input type="number" className="form-control" id="withdraw-amount"/>
                                <div id="emailHelp" className="form-text text-danger">*จำนวนเงินถอนขั้นต่ำ 100 บาท</div>
                            </div>
                            <div className="mb-3 row btn-range ">
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(100)}}>100</span>
                                </div>
                                <div className='col col-3'> 
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(500)}}>500</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(1000)}}>1,000</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(3000)}}>3,000</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(parseInt(amount*0.2))}}>20%</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(parseInt(amount*0.5))}}>50%</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(parseInt(amount*0.8))}}>80%</span>
                                </div>
                                <div className='col col-3'>
                                    <span className="btn btn-outline-light w-100 mb-2" onClick={()=>{$('#withdraw-amount').val(parseInt(amount))}}>ทั้งหมด</span>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-spotlight-1 mb-5 w-100" id="btn-withdraw">ยืนยันการถอน</button>
                        </form>
                    </div>
                    <div className='col-sm-3'/>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WithdrawComponent
import React,{ useContext,useState,useEffect } from 'react'
import $ from 'jquery'
import LottoService  from "../../Services/Lotto.service"; 
import HistoryService  from "../../Services/History.service"; 
import DataContext from "../../Data/DataContext";
// import './../css/PlayHistory.css'
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

function History() {
    const dataC = useContext(DataContext)
    const lottoService = new LottoService()
    const historyService = new HistoryService()
    const [lotopen , setLotOpen] = useState([])

    const [pageOffset,setPageOffset] = useState(0)
    const [billPlay , setBillPlay] = useState([])
    const [billPlayDetails,setBillPlayDetails] = useState({})
    const [refresh,setRefresh] = useState()

    useEffect(()=>{
        fetchLotOpen()
    },[])

    const fetchLotOpen = ()=>{
        lottoService.listing_lot_open().then((result)=>{
            // console.log(result)
            if (result.success){
                setLotOpen(result.data)
            }
        })
    }

    useEffect(()=>{
        fetchPlayHistory()
    },[lotopen])

    useEffect(()=>{
        // console.log(billPlayDetails)
    },[billPlayDetails])

    const fetchPlayHistory = ( offset = 0 , zone = 0 , key_poy = 1 )=>{
        const payload = {
            p_offset : offset,
            p_lz_key : parseInt(zone),
            p_key_poy : parseInt(key_poy)
          }
        dataC.blockUI(true)
        historyService.listting_history(payload).then((result)=>{
            dataC.blockUI(false)
            if (result.success){
                setBillPlay(result.data)
                let billDetails = billPlayDetails
                result.data.map((bill)=>{
                    billDetails[bill.o_id] = []
                })
                setBillPlayDetails(billDetails)
                setPageOffset(result.offset)
                $('.loaded').removeClass('loaded')
                $('.collapse').removeClass('show')
                $('.btn-details').attr('disabled',false)
            }
        })
    }
    const fetchPlayHistoryMore = ( offset = 0 , zone = 0 , key_poy = 1 )=>{
        const payload = {
            p_offset : offset,
            p_lz_key : parseInt(zone),
            p_key_poy : parseInt(key_poy)
          }
        dataC.blockUI(true)
        historyService.listting_history(payload).then((result)=>{
            dataC.blockUI(false)
            if (result.success){
                let billP = billPlay
                billP = [...billP,...result.data]
                setBillPlay(billP)
                let billDetails = billPlayDetails
                result.data.map((bill)=>{
                    billDetails[bill.o_id] = []
                })
                setBillPlayDetails(billDetails)
                setPageOffset(result.offset)
                if(result.data.length<=0){
                    $('#btn-bill').attr('disabled',true)
                }
            }
        })
    }
    const fetchPlayHistoryDetails = (o_id,offset = 0)=>{
        if($('#collapse'+o_id).hasClass('loaded') && offset === 0){
            return;
        }
        const payload = {
            p_o_id: o_id,
            p_offset:offset
        }
        dataC.blockUI(true)
        historyService.listting_history_detail(payload).then((result)=>{
            dataC.blockUI(false)
            if (result.success){
                let billDetails = billPlayDetails
                if(offset>0){
                    billDetails[o_id] = [...billDetails[o_id],...result.data]
                }else{
                    billDetails[o_id] = result.data
                }
                setBillPlayDetails(billDetails)
                $('#collapse'+o_id).addClass('loaded')
                $('#btn-'+o_id).val(result.offset)
                if(result.data.length<=0){
                    $('#btn-'+o_id).attr('disabled',true)
                }
                setRefresh(uuidv4())
            }
        })
    }
  return (
    <section id="play-history" className="mt-5 pt-5">
        <div className="container">
            <div className="card mb-3 bg-dark-skin" >
                <div className="card-body">
                    <span><i className="bi bi-list-stars"></i> ประวัติการเล่น</span>
                </div>
            </div>
            <div className=" rounded py-2 mt-2 px-2 bg-head-card">
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-5 col-12 mt-2'>
                        <select className='form-select form-select-sm' id="lzone">
                            <option value="0">หวยทั้งหมด</option>
                            {lotopen.map((lp,i)=>{
                                return <option value={lp.lz_key} op={lp.op_id} key={i}>{lp.lz_name}</option>
                            })}
                        </select>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-5 col-12  mt-2'>
                        <select className='form-select form-select-sm' id="key_poy">
                            <option value="1">โพยหวยทั้งหมด</option>
                            <option value="3">โพยหวยที่ออกผลแล้ว</option>
                            <option value="2">โพยหวยที่ยังไม่ออกผล</option>
                        </select>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-5 col-6  mt-2'>
                        <button className='btn btn-sm btn-warning w-100 rounded' onClick={()=>{fetchPlayHistory(0,$('#lzone').val(),$('#key_poy').val())}}><i className="bi bi-search"></i> ค้นหา</button>
                    </div>
                </div>
            </div>

            {billPlay.length > 0?
            <>
                {billPlay.map((bill,i)=>{
                    return (
                        <div key={i} >
                        <div className=" p-2 pt-2 mt-2 rounded text-start bg-history-card">
                        <div className="row">
                                <div className="col col-6">  
                                    <div className="row">
                                        <div className="col col-12">
                                            <small className="text-light my-0 ps-2">โพยหวยเลขที่ #{bill.o_id} </small> 
                                        </div><br/>
                                        {bill.o_name?
                                            <div className="col col-12">
                                                <span className="pe-2 ps-2">ชื่อ : {bill.o_name}</span>
                                            </div>
                                        :<></>}
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <i  className="bi bi-bookmark-fill float-end"></i>
                                </div>  
                            </div>
                            <div className='bg-light text-dark rounded'>
                                <div className='row p-2'>
                                    <div className='col-12'>{bill.lz_name}</div>
                                    <div className='col-6'>
                                        <div className='text-secondary text-sm'>ปิดขาย</div>
                                        <div className='text-secondary text-sm'>ซื้อเมื่อ</div>
                                        <div className='text-secondary text-sm'>ส่วนลด</div>
                                    </div>
                                    <div className='col-6 text-end'>
                                        <div className='text-secondary text-sm'>{ moment(bill.o_time_open).format('DD-MM-YYYY HH:mm:ss') }</div>
                                        <div className='text-dark text-sm'>{ moment(bill.o_time_bet).format('DD-MM-YYYY HH:mm:ss') }</div>
                                        <div className='text-success text-sm'>฿ {dataC.money.format(bill.o_dis)} บาท</div>
                                    </div>
                                </div>
                                <hr className='m-0'/>
                                <div className='row p-2 text-center'>
                                    <div className={(bill.o_status==1?'col-3':'col-2')+' '}>
                                        <div className='fs-5'><span  className={"badge "+(bill.o_status==1||bill.o_status==6?'bg-warning':(bill.o_status==2||bill.o_status==3?'bg-danger':(bill.o_status==4?'bg-success':'')))}>{bill.o_status_name}</span></div>
                                    </div>
                                    <div className={(bill.o_status==1?'col-6':'col-5')+' '}>
                                        <div className='text-secondary text-sm'>เงินเดิมพัน</div>
                                        <div className='text-dark fw-bold fs-6'>฿ {dataC.money.format(bill.o_total)} </div>
                                    </div>
                                    <div className={bill.o_status==1?'col-3':'col-5'}>
                                        <div className='text-secondary text-sm'>ผลได้เสีย</div>
                                        <div className='text-dark fw-bold fs-6'>฿ {dataC.money.format(bill.o_bonus)} </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-1'>
                                <button className='btn btn-sm btn-warning w-100' type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+bill.o_id} aria-expanded="false" aria-controls={"collapse"+bill.o_id} onClick={(e)=>{fetchPlayHistoryDetails(bill.o_id)}}>
                                    แสดงรายละเอียด
                                </button>
                                <div  className="collapse" id={"collapse"+bill.o_id}>
                                    <div className=" p-2 pt-2 mt-1 rounded text-start bg-light-gray" style={{}}>
                                    {/* <div className='text-center text-dark my-0 ps-2 fw-bold'>2 ตัวบน </div> */}
                                        <div className='bg-light text-dark rounded '>
                                            <div className='row'>
                                                {billPlayDetails[bill.o_id].map((detail,i)=>{
                                                    return (
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                                                            <div  className="card border m-2">
                                                                <div  className="card-body text-center row">
                                                                    <div className='col-4'>
                                                                        <div className='text-dark text-md'>{detail.lt_name}</div>
                                                                        <div className='text-success fw-bold fs-3'>{detail.od_number} </div>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <div className='text-secondary text-sm'>ราคาแทง</div>
                                                                        <div className='text-dark fw-bold text-sm'> ฿{dataC.money.format(detail.od_total)} </div>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <div className='text-secondary text-sm'>ราคาจ่าย</div>
                                                                        <div className='text-dark fw-bold text-sm'> ฿{dataC.money.format(detail.od_pay)} </div>
                                                                    </div>
                                                                </div>
                                                                <div className='card-footer bg-light'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='fs-6'><span  className={"badge "+(detail.od_status==1||detail.od_status==6||detail.od_status==7?'bg-warning':(detail.od_status==2||detail.od_status==3?'bg-danger':(detail.od_status==4?'bg-success':'')))}>{detail.od_status_name}</span></div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='fs-6 fw-bold text-end text-success'>฿ {dataC.money.format(detail.od_bonus)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='col-12 text-center'>
                                                    <button className="btn btn-sm btn-warning mb-2 col-lg-4 col-md-4 col-sm-4 col-8 btn-details" id={'btn-'+bill.o_id} defaultValue={0} onClick={(e)=>{fetchPlayHistoryDetails(bill.o_id,e.target.value)}}>แสดงเพิ่มเติม</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

                <button className='btn btn-warning mt-2 mb-4'  id={'btn-bill'} onClick={()=>{fetchPlayHistoryMore(pageOffset,$('#lzone').val(),$('#key_poy').val())}}>แสดงโพยหวยเพิ่มเติม</button> 
            </>
            :
            <>
                <h4 className='mt-4 text-on-background'>ไม่พบโพยหวย</h4>
            </>
            }
            
        </div>
    </section>
  )
}

export default History
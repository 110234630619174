const config = {
    ApiURL: process.env.REACT_APP_API_HOST,
    AppName: process.env.REACT_APP_NAME,
    arrBank :  [
        {id:1, b_name: "ธนาคารกสิกรไทย", b_code:'kbank'},
        {id:2, b_name: "ธนาคารไทยพาณิชย์", b_code:'scb'},
        {id:3, b_name: "ธนาคารกรุงไทย", b_code:'ktb'},
        {id:4, b_name: "ธนาคารกรุงเทพ", b_code:'bbl'},
        {id:5, b_name: "ธนาคารกรุงศรีอยุธยา", b_code:'bay'},
        {id:6, b_name: "ธนาคารทหารไทยธนชาต", b_code:'ttb'},
        {id:8, b_name: "ธนาคารแลนด์ แอนด์ เฮ้าส์ ", b_code:'lhb'},
        {id:9, b_name: "ธนาคารอิสลาม", b_code:'ibank'},
        {id:10, b_name: "ธนาคารยูโอบี", b_code:'uob'},
        {id:11, b_name: "ธนาคารออมสิน", b_code:'gsb'},
        {id:12, b_name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", b_code:'baac'},
        {id:13, b_name: "ธนาคารซีไอเอ็มบีไทย", b_code:'cimb'},
        {id:14, b_name: "ธนาคารเกียรตินาคินภัทร", b_code:'kk'},
        {id:15, b_name: "ธนาคารอาคารสงเคราะห์", b_code:'ghb' }
    ],
    money:Intl.NumberFormat("th-TH", {
        minimumFractionDigits:2
    }),
    monthThai:{
        '01':'มกราคม',
        '02':'กุมภาพันธ์',
        '03':'มีนาคม',
        '04':'เมษายน',
        '05':'พฤษภาคม',
        '06':'มิถุนายน',
        '07':'กรกฎาคม',
        '08':'สิงหาคม',
        '09':'กันยายน',
        '10':'ตุลาคม',
        '11':'พฤศจิกายน',
        '12':'ธันวาคม'
    }
}


export default config;
import React, { useContext,useLayoutEffect } from 'react'
import Slider from "react-slick";
import  {Link} from 'react-router-dom'
import axios from 'axios';
import $ from 'jquery'
import DataContext from '../Data/DataContext';
const Swal = require('sweetalert2')

const LoginComponent = (props) => {
    const dataC = useContext(DataContext);
    const set_1 = {
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false
      };
    const set_2 = {
        autoplay: false,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2
      };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[]);

    const formSubmit = async (e)=>{
        e.preventDefault()
        const username = e.target.username.value
        const password = e.target.password.value

        const payload = { 
            "p_m_user":username,
            "p_m_pass":password
        }
        dataC.blockUI(true)
        const response = await axios.post(`${dataC.ApiURL}/member/auth`,payload,
            {
                header:{'Content-Type' : 'application/json'}
            }
        ).then(response => {
            // console.log(response)
            const member = response.data.data
            localStorage.setItem('token',response.data.access_token,60000)
            localStorage.setItem('ag_agent_array',member.ag_agent_array,60000)
            localStorage.setItem('ag_id',member.ag_id,60000)
            localStorage.setItem('m_af',member.m_af,60000)
            localStorage.setItem('m_date_register',member.m_date_register,60000)
            localStorage.setItem('m_group',member.m_group,60000)
            localStorage.setItem('m_key_web',member.m_key_web,60000)
            localStorage.setItem('m_money',member.m_money,60000)
            localStorage.setItem('m_money_af',member.m_money_af,60000)
            localStorage.setItem('m_money_af_total',member.m_money_af_total,60000)
            localStorage.setItem('m_name',member.m_name,60000)
            localStorage.setItem('m_phone',member.m_phone,60000)
            localStorage.setItem('m_status',member.m_status,60000)
            localStorage.setItem('m_uid',member.m_uid,60000)
            localStorage.setItem('m_user',member.m_user,60000)
            localStorage.setItem('mb_acc_name',member.mb_acc_name,60000)
            localStorage.setItem('mb_acc_number',member.mb_acc_number,60000)
            localStorage.setItem('mb_bank',member.mb_bank,60000)
            props.auth(response.data.access_token,member.ag_agent_array,member.ag_id,member.m_af,member.m_date_register,member.m_group,member.m_key_web,member.m_money,member.m_money_af,member.m_money_af_total,member.m_name,member.m_phone,member.m_status,member.m_uid,member.m_user,member.mb_acc_name,member.mb_acc_number,member.mb_bank,0)
            setTimeout(()=>{
                dataC.blockUI(false)
            },3000);
        }).catch(error=>{
            Swal.fire({
                title: 'เข้าสู่ระบบไม่สำเร็จ !!',
                text: 'กรุณาตรวจสอบรหัสผู้ใช้และรหัสผ่านให้ถูกต้อง',
                icon: 'warning',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: 'green'
            })
            dataC.blockUI(false)
        });

    }
    return (
    <React.Fragment>
    {/* ======= Hero Section ======= */}
    <section id="login" className="d-flex align-items-center text-dark mt-2" >
        <div className="container">
        <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-1 order-lg-1 d-flex flex-column">
            <div className="icon-box rounded-3">
                <form onSubmit={formSubmit} method="post">
                    <div className="form-group mt-3">
                        <input type="text" name="username" className="form-control" id="username" required placeholder="ชื่อผู้ใช้งานหรือเบอร์โทร" />
                    </div>
                    <div className="form-group mt-3">
                        <input type="password" name="password" className="form-control" id="password" required placeholder="รหัสผ่าน" />
                    </div>
                    <div className="row text-center mt-3">
                        <div className={(localStorage.getItem('ag')!=undefined && localStorage.getItem('ag')!=0 && localStorage.getItem('ref')!=undefined && localStorage.getItem('ref')!=0?'col-lg-6 col-md-6 col-sm-6':'col-lg-12 col-md-12 col-sm-12')+" col-12"}>
                            <button type='submit' className="button-01 w-100 btn mb-1">เข้าสู่ระบบ </button>
                        </div>
                        {localStorage.getItem('ag')!=undefined && localStorage.getItem('ag')!=0 && localStorage.getItem('ref')!=undefined && localStorage.getItem('ref')!=0?
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Link to={`/register/${localStorage.getItem('ag')}/${localStorage.getItem('ref')}`} className="btn button-02 scrollto w-100 mb-1" >สมัครสมาชิก</Link> 
                            </div>
                            :
                            <></>
                        }
                        
                    </div>
                </form>
            </div>
            </div>
            <div className="col-lg-6 order-2 order-lg-2 hero-img">
                <Slider  {...set_1}>
                    <div>
                        <img src={`web/${process.env.REACT_APP_NAME}/banner01.jpg`} alt="s1" style={{width: '100%'}} />
                    </div>
                    <div>
                        <img src={`web/${process.env.REACT_APP_NAME}/banner02.jpg`} alt="s2" style={{width: '100%'}} />
                    </div>
                </Slider>
            </div>
        </div>
        </div>
    </section>{/* End Hero */}
    <main id="main">
        {/* ======= Featured Services Section ======= */}
        <section id="featured-services" className="featured-services">
        <div className="container">
            <Slider  {...set_2}>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite01.jpeg`} alt="s4" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite02.jpeg`} alt="s5" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite01.jpeg`} alt="s6" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite02.jpeg`} alt="s7" style={{width: '98%', margin: 'auto'}} />
                </div>
            </Slider>
        </div>
        </section>{/* End Featured Services Section */}</main>
    </React.Fragment>
  )
}

export default LoginComponent
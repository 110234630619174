import AbstractService from "./Abstract.service";

export default class AuthenticationService extends AbstractService
{
    check_phone(payload:Object): Promise<any>
    {
        return this.post('/member/check-phone',payload);
    }

    register(payload:Object): Promise<any>
    {
        return this.post(`/member/register`,payload);
    }

}
import React, { useContext } from 'react'
import WalletComponent from '../WalletComponent'
import DataContext from '../../Data/DataContext'
import AssetsService from '../../Services/Assets.service'
import Swal from 'sweetalert2'

function ProfileMember() {
    const assetsService = new AssetsService()
    const dataC = useContext(DataContext)
    const logout = ()=>{
        Swal.fire({
            icon: 'question',
            title: 'ออกจากระบบ',
            text: 'คุณต้องการออกจากระบบหรือไม่ ?',
            confirmButtonText: 'ออกจากระบบ',
            confirmButtonColor: 'red',
            showCloseButton: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                localStorage.clear()
                dataC.auth(null)
            }
          })
        
    }
    const bankArr = dataC.arrBank.filter((re)=>dataC.mb_bank==re.id)[0]


    const change_password = (e) => {
        e.preventDefault()
        if(e.target.p_newpass.value != e.target.p_conpass.value){
            Swal.fire({
                text: 'รหัสผ่านไม่ตรงกัน \n กรุณาตรวจสอบรหัสผ่านของคุณอีกครั้ง',
                icon:'warning',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: 'red'
            })
            return;
        }
        const payload = {
            p_m_pass: e.target.p_pass.value,
            p_m_pass_new: e.target.p_newpass.value
        }
        assetsService.change_pass(payload).then((result)=>{
            if(result.success){
                Swal.fire({
                    text: result.message,
                    icon:'success',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'green'
                })
            }else{
                Swal.fire({
                    text: result.message,
                    icon:'warning',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'red'
                })
            }
        })
    }
    return (
        <>
            <WalletComponent />
            <div className="container profile">
                <div className="row">
                    <div className="mt-2 col-lg-6 col-md-6  col-sm-12 col-12">
                        <div className="rounded-top py-2 bg-dark-skin border-bottom">
                            <span>ข้อมูลสมาชิก</span>
                        </div>
                        <div className="rounded-bottom bg-dark-skin p-4 text-start" style={{minHeight:"280px"}}>
                            <div className='row'>
                                <div className='col-5'>
                                    รหัสสมาชิก
                                </div>
                                <div className='col-7'>
                                    :  {dataC.m_user}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5'>
                                    ชื่อสมาชิก
                                </div>
                                <div className='col-7'>
                                    : {dataC.mb_acc_name}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5'>
                                    เบอร์โทรศัพท์
                                </div>
                                <div className='col-7'>
                                    : {dataC.m_phone}
                                </div>
                            </div>
                            <div className="card mt-4  border bg-dark-skin p-2">
                                <div className="card-body p-1">
                                    <i className={`bank bank-${bankArr.b_code} huge float-start me-2`}></i>
                                    <h6 className="card-subtitle mb-1">
                                        {bankArr.b_name}
                                    </h6>
                                    <h5 className="card-title">
                                        <small className='fs-6'>เลขที่บัญชี : </small>
                                        {dataC.mb_acc_number}
                                    </h5>
                                    <h6 className="card-subtitle mb-1">
                                        <small className='fs-normal'>ชื่อบัญชี : </small>
                                        {dataC.mb_acc_name}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="rounded-top py-2 bg-dark-skin border-bottom">
                            <span>เปลี่ยนแปลงรหัสผ่าน</span>
                        </div>
                        <div className="rounded-bottom bg-dark-skin p-2 text-start" style={{minHeight:"280px"}}>
                            <form onSubmit={change_password}>
                                <div className="mb-3">
                                    <small className="form-label">รหัสผ่าน</small>
                                    <input type="password" className="form-control form-control-sm" id="p_pass" placeholder="รหัสผ่าน" required/>
                                </div>
                                <div className="mb-3">
                                    <small className="form-label">รหัสผ่านใหม่</small>
                                    <input type="password" className="form-control form-control-sm" id="p_newpass" placeholder="รหัสผ่านใหม่" required/>
                                </div>
                                <div className="mb-3">
                                    <small className="form-label">ยืนยันรหัสผ่าน</small>
                                    <input type="password" className="form-control form-control-sm" id="p_conpass" placeholder="ยืนยันรหัสผ่าน" required/>
                                </div>
                                <div className="mb-3">
                                    <button type='submit' className='btn btn-sm btn-warning w-100'>เปลี่ยนรหัสผ่าน</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <button className='btn btn-danger mt-3 col-sm-4 col-8 m-auto p-auto' onClick={logout}>
                    <i className="bi bi-box-arrow-right"></i> ออกจากระบบ
                </button>
            </div>

        </>
    )
}

export default ProfileMember
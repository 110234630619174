import AbstractService from "./Abstract.service";

export default class LottoService extends AbstractService
{
    listing_zone(): Promise<any>
    {
        return this.get('/lotzone');
    }

    listing_zone_info(payload:Object): Promise<any>
    {
        return this.postAuth('/lotzone/info',payload);
    }

    getting_info_open(payload:Object): Promise<any>
    {
        return this.postAuth('/lotopen/get-info-open',payload);
    }

    listing_type(): Promise<any>
    {
        return this.getAuth('/lottype');
    }

    listing_lotgroup(): Promise<any>
    {
        return this.getAuth('/lotgroup');
    }

    listing_lot_open(): Promise<any>
    {
        return this.getAuth('/lotopen');
    }

    add_order(payload:Object): Promise<any>
    {
        return this.postAuth('/order/add-order',payload);
    }

    can_order(payload:Object): Promise<any>
    {
        return this.postAuth('/order/can-order',payload);
    }

    listing_lothaft(payload:Object): Promise<any>
    {
        return this.postAuth('/lothaft',payload);
    }

    listing_lotfull(payload:Object): Promise<any>
    {
        return this.postAuth('/lotfull',payload);
    }

    listing_result(payload:Object): Promise<any>
    {
        return this.postAuth('/lotopen/result',payload);
    }

    listing_result2(payload:Object): Promise<any>
    {
        return this.postAuth('/lotopen/result2',payload);
    }

    groupBy(objectArray, property) :Promise<any>
    {
        return objectArray.reduce(function (acc, obj) {
          let key = obj[property]
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(obj)
          return acc
        }, {})
    }


}
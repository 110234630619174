import React,{ useState, useEffect, useContext,useRef} from 'react'
import { useParams,Link } from "react-router-dom";
import $ from 'jquery'
import LottoService  from "../../Services/Lotto.service"; 
import DataContext from "../../Data/DataContext";
// import '../css/LottoPanel.css'
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment-timezone";
import { useNavigate } from 'react-router-dom'

function LottoPanelComponent(props) {
    moment.tz.setDefault("Asia/Bangkok");
    const timeZone = "Asia/Bangkok";
    const dataC = useContext(DataContext)
    const { op_id } = useParams()
    const lottoService = new LottoService()
    // const [ numToggle, setNumToggle ] = useState(false)
    const [ mainZone, setMainZone ] = useState(1)
    const [ numMode, setNumMode ] = useState('0')
    const [lengthNum, setLengthNum] = useState([...Array(100)])
    const [openInfo,setOpenInfo] = useState()
    const [allLottype,setAllLottype] = useState([])
    const [lotTypeOpen,setLottypeOpen] = useState([])

    const [typeSelect,setTypeSelect] = useState([])

    const [toggleStatus,setToggleStatus] = useState(0)

    const [finalLotNumber,setFinalLotNumber] = useState({})
    const [arrNumber,setArrNumber] = useState([])
    const [ stateReload , setStateReload ] = useState(1)

    const [ labelList , setLabelList ] = useState(0)

    const [lotHaft,setLotHaft ] = useState([])
    const [haftType,setHaftType ] = useState([])
    const [tempLotHaft,setTempLotHaft ] = useState([])

    const [lotFull,setLotFull ] = useState([])
    const [fullType,setFullType ] = useState([])
    const [tempLotfull, setTempLotFull] = useState([])

    const [ option, setOption ] = useState(0)

    const [tripleOption, setTripleOption] = useState(0)
    const [door19Option, setDoor19Option] = useState([])
    const [doubleOption, setDoubleOption] = useState(0)
    const [rootStartOption, setRootStartOption] = useState([])
    const [rootEndOption, setRootEndOption] = useState([])

    // useEffect(()=>{
    //     for (var i = 1; i < 999; i++)
    //         window.clearInterval(i);
    // },[])
    
    // const zoneF = useRef(true);
    useEffect(()=>{
        // if (zoneF.current) {
        //     zoneF.current = false;
        //     return;
        // }
        // // console.log('getlotzone')
        getInfoOpen()
        getHaftLotto()
        getFullLotto()
    },[props.launch])

    const typeF = useRef(true);
    useEffect(()=>{
        if (typeF.current) {
            typeF.current = false;
            return;
        }
        // // console.log('getlottype')
        getlottype()
        if(openInfo)
            countDownDate(openInfo.op_date_time_stop,'count-down')
    },[openInfo])

    useEffect(()=>{
        if(!openInfo) return
        const lot_t_p = openInfo.lot_type_open.split(',')
        const lotOpen = lot_t_p.map((p,i)=>{
            return {...allLottype.filter((lot)=>lot.lt_id.toString() === p)[0]}
        })
        let lot_g = []
        lot_g = lottoService.groupBy(lotOpen,'lt_num_length')
        setLottypeOpen(lot_g)
        setMainZone(Object.keys(lot_g).sort().reverse()[0])
        
        // setTypeSelect([lot_g[Object.keys(lot_g).sort().reverse()[0]][0].lt_key.toString()])
        // setTypeSelect([4])
     
        const open = lot_g[Object.keys(lot_g).sort().reverse()[0]].filter((g,i)=>{
            return openInfo.pay_array[g.lt_key] > 0
        })
        if(open.length>0){
            setTypeSelect([open[0].lt_key.toString()])
        }
        setStateReload(uuidv4())
        
    },[allLottype])

    // useEffect(()=>{
    // },[lotTypeOpen])

    // useEffect(()=>{
        
    // },[finalLotNumber,arrNumber])

    // useEffect(()=>{
        
    // },[tempLotHaft])

    useEffect(()=>{
        // setTempLotFull
        var tempFull = []
        typeSelect.map((lt)=>{
            const fullTemp = tempLotfull.filter((full,i)=>{
                return full.lot_type.toString() === lt.toString()
            })
            fullTemp.map((lf)=>{
                tempFull = [...tempFull,lf.lf_number]
            })
        })
        setLotFull(tempFull)

        var tempHaft = []
        typeSelect.map((lt)=>{
            const haftTemp = tempLotHaft.filter((haft,i)=>{
                return haft.lot_type.toString() === lt.toString()
            })
            haftTemp.map((lh)=>{
                tempHaft = [...tempHaft,lh.lh_number]
            })
        })
        setLotHaft(tempHaft)
    },[typeSelect])

    // useEffect(()=>{
    //     console.log(rootStartOption)
    // },[rootStartOption])

    // useEffect(()=>{
    //     console.log(rootEndOption)
    // },[rootEndOption])

    useEffect(()=>{
        $('.btn-mode').removeClass('active')
        $('.btn-mode-'+numMode).addClass('active')
        // searchNumber(numMode)
    },[numMode])


    const getHaftLotto = ()=>{
        if (tempLotHaft.length > 0 ) return;
        lottoService.listing_lothaft({ p_ag_key_web: dataC.m_key_web,  p_op_id: op_id }).then((result)=>{
            if (result.success){
                var h = []
                var t = []
                result.data.map((haft,i)=>{
                    h = [...h,...haft.lh_number]
                    t.push(haft.lot_type)
                })
                setHaftType(t)
                setLotHaft(h)
                setTempLotHaft(result.data)
            }
        });
    }

    const getFullLotto = ()=>{
        if (tempLotfull.length > 0 ) return;
        lottoService.listing_lotfull({ p_ag_key_web: dataC.m_key_web,  p_op_id: op_id }).then((result)=>{
            if (result.success){
                var f = []
                var t = []
                result.data.map((full,i)=>{
                    f = [...f,...full.lf_number]
                    t.push(full.lot_type)
                })
                setFullType(t)
                setLotFull(f)
                setTempLotFull(result.data)
            }
        });
    }

    const getInfoOpen = ()=>{
        lottoService.getting_info_open({p_op_id:op_id}).then((result)=>{
            if (result.success){
                setOpenInfo(result.data)
            }else{
              Swal.fire({
                text: result.message,
                icon: 'warning',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#890000',
              })
            }
        });
    }

    const getlottype = ()=>{
        if (allLottype.length > 0 ) return;
        lottoService.listing_type().then((result)=>{
            if (result.success){
              setAllLottype(result.data)
            }else{
              Swal.fire({
                text: result.message,
                icon: 'warning',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#890000',
              })
            }
        });
    }

    const searchNumber = (n)=>{
        setLengthNum([...Array(1000)])
        var s_num = $('.search-num-'+n).val()?$('.search-num-'+n).val().trim().split(''):[]
        const equal = allAreEqual(s_num)
        if( $('.search-num-'+n).val()===''){
            $('.btn-num').removeClass('btn-hide')
            return;
        } 
        $('.btn-num').addClass('btn-hide')
        if(equal && s_num.length != 1){
            $('.btn-num-'+s_num.join('')).removeClass('btn-hide')
            $('.b'+s_num.join('')).removeClass('btn-hide')
        }else{
            if(s_num.length === 3){
                let numFindToggle = toggleNum(s_num.join(''))
                $(numFindToggle.reduce((num,n)=>num +'.b'+n,'')).removeClass('btn-hide') 
            }else{
                let numFindToggle = toggleNum(s_num.join(''))
                $(numFindToggle.reduce((num,n)=>num +'.b'+n,'')).removeClass('btn-hide') 
                $(s_num.reduce((num,n)=>num +'.b'+n,'')).removeClass('btn-hide') 
            }
           
        }
        
    }

    const btnTypeSelect = (e)=>{
        const t = typeSelect
        if(typeSelect.includes(e.target.value.toString())) {
            if(t.length>1){
                t.splice(t.indexOf(e.target.value.toString()), 1);
                setTypeSelect([...t]);
            }
        }  
        else{
            setTypeSelect([...typeSelect,e.target.value]);
        }
           
    }

    const numberSelected = (e)=>{
        var lotnum = e.target.value
        if(toggleStatus){
            lotnum = toggleNum(e.target.value)
        }else{
            lotnum = [e.target.value]
        }

        addLottoList(lotnum);
    }

    const numberPop = (e)=>{
        
        const nPop = e.target.value
        var arrayNumPop = [nPop]
        if(toggleStatus){
            arrayNumPop = toggleNum(nPop)
        }
        const arrfNum = {...finalLotNumber}
        const arrNum = [...arrNumber]
        arrayNumPop.map((numPop,i)=>{
            typeSelect.map((lt)=>{
                if(arrfNum[lt]){
                    const ni = arrfNum[lt].lot_num.indexOf(numPop)
                    if(ni>=0) arrfNum[lt].lot_num.splice(ni,1)
                    if(arrfNum[lt].lot_num.length<=0){
                        delete arrfNum[lt]
                    } 
                    const ai = arrNum.indexOf(numPop)
                    if(ai>=0) arrNum.splice(ai,1)
                }
            })
        })
        
        setFinalLotNumber(arrfNum)
        setArrNumber(arrNum)
        setStateReload(uuidv4())
        
    }

    const door19 = (e) =>{
        let n = e.target.value;
        let door19o = door19Option;
        let rs = rootStartOption;
        let re = rootEndOption;
        
        let lotnum = [];
        for (let i = 0; i < 20; i++) {
            let l = ''
            if(i < 10){
                l = `${n}${i}`
            }else{
                let a = i - 10
                l = `${a}${n}`
            }
            if(lotnum.includes(l)) continue;
            lotnum.push(l)
        }
        if(door19Option.includes(n)){
            const index = door19Option.indexOf(n);
            door19o.splice(index, 1);
            const indexRs = rootStartOption.indexOf(n);
            rs.splice(indexRs, 1);
            const indexRe = rootEndOption.indexOf(n);
            re.splice(indexRe, 1);
            if(door19Option.length > 0 ){
                door19Option.map((d19)=>{
                    const index1 = lotnum.indexOf(`${d19}${n}`);
                    lotnum.splice(index1, 1);
                    const index2 = lotnum.indexOf(`${n}${d19}`);
                    lotnum.splice(index2, 1);
                })
            }
            if( doubleOption === 1 ){
                const index = lotnum.indexOf(`${n}${n}`);
                if ( index >= 0 ) lotnum.splice(index, 1);
            }
            // if(rootStartOption.length > 0 && rootStartOption.includes(n)){
            //     rootStartOption.map((rs)=>{
            //         for(let i=0; i<10; i++){
            //             const index = lotnum.indexOf(`${rs}${i}`);
            //             if ( index >= 0 ) lotnum.splice(index, 1);
            //         }
            //     })
            // }
            // if(rootEndOption.length > 0 && rootEndOption.includes(n)){
            //     rootEndOption.map((re)=>{
            //         for(let i=0; i<10; i++){
            //             const index = lotnum.indexOf(`${i}${re}`);
            //             if ( index >= 0 ) lotnum.splice(index, 1);
            //         }
            //     })
            // }
            delLottoList(lotnum)
        }else{
            door19o.push(n);
            addLottoList(lotnum); 
            if( !rootStartOption.includes(n) ){
                rs.push(n)
                setRootStartOption(rs) 
            }
            if( !rootEndOption.includes(n) ){
                re.push(n)
                setRootEndOption(re)
            } 
        }
        setDoor19Option(door19o);
        setRootStartOption(rs);
        setRootEndOption(re);
        setStateReload(uuidv4())
        
    }

    const rootStart = (e) =>{
        let n = e.target.value
        let door19o = door19Option
        let rs = rootStartOption
        
        let lotnum = []
        for (let i = 0; i < 10; i++) {
            let l = `${n}${i}`
            lotnum.push(l)
        }
        
        if(rootStartOption.includes(n)){
            const index = rootStartOption.indexOf(n);
            rs.splice(index, 1);
            if(door19Option.length > 0 ){
                if(door19Option.includes(n)){
                    const index = door19Option.indexOf(n);
                    door19o.splice(index, 1);
                    // door19Option.map((d19)=>{
                    //     for(let i=0; i<10; i++){
                    //         const index = lotnum.indexOf(`${d19}${i}`);
                    //         if ( index >= 0 ) lotnum.splice(index, 1);
                    //     }
                    // })
                }else{
                    door19Option.map((d19)=>{
                        const index = lotnum.indexOf(`${n}${d19}`);
                        if ( index >= 0 ) lotnum.splice(index, 1);
                    })
                }
                
                
            }
            if( doubleOption === 1 ){
                const index = lotnum.indexOf(`${n}${n}`);
                if ( index >= 0 ) lotnum.splice(index, 1);
            }
            if(rootEndOption.length > 0){
                rootEndOption.map((re,i)=>{
                    const index = lotnum.indexOf(`${n}${re}`);
                    if ( index >= 0 ) lotnum.splice(index, 1);
                })
            }
            delLottoList(lotnum)
        }else{
            
            if(rootEndOption.includes(n)) {
                if(door19Option.length > 0){
                    if(!door19Option.includes(n)){
                         door19o.push(n)  
                     } 
                }else{
                     door19o.push(n) 
                }
            }
            rs.push(n)
            addLottoList(lotnum);  
        }
        setRootStartOption(rs)
        setDoor19Option(door19o)
        setStateReload(uuidv4())
    }

    const rootEnd = (e) =>{
        let n = e.target.value
        let door19o = door19Option
        let re = rootEndOption
        
        let lotnum = []
        for (let i = 0; i < 10; i++) {
            let l = `${i}${n}`
            lotnum.push(l)
        }
        if(rootEndOption.includes(n)){
            const index = rootEndOption.indexOf(n);
            re.splice(index, 1);
            if(door19Option.length > 0 ){
                if(door19Option.includes(n)){
                    const index = door19Option.indexOf(n);
                    door19o.splice(index, 1);
                    // door19Option.map((d19)=>{
                    //     for(let i=0; i<10; i++){
                    //         const index = lotnum.indexOf(`${i}${d19}`);
                    //         if ( index >= 0 ) lotnum.splice(index, 1);
                    //     }
                        
                    // })
                }else{
                    door19Option.map((d19)=>{
                        const index = lotnum.indexOf(`${d19}${n}`);
                        if ( index >= 0 ) lotnum.splice(index, 1);
                    })
                }
            }
            if( doubleOption === 1 ){
                const index = lotnum.indexOf(`${n}${n}`);
                if ( index >= 0 ) lotnum.splice(index, 1);
            }
            if(rootStartOption.length > 0){
                rootStartOption.map((rs,i)=>{
                    const index = lotnum.indexOf(`${rs}${n}`);
                    if ( index >= 0 ) lotnum.splice(index, 1);
                })
            }
            
            delLottoList(lotnum)
        }else{
            re.push(n)
            addLottoList(lotnum);
            if(rootStartOption.includes(n)) {
                if(door19Option.length > 0){
                   if(!door19Option.includes(n)){
                        door19o.push(n)  
                    } 
                }else{
                    door19o.push(n) 
                }
            }
        }
        setRootEndOption(re)
        setDoor19Option(door19o)
        setStateReload(uuidv4())
    }

    const doubleNumber = (e) =>{
        // let n = e.target.value
        let lotnum = []
        for (let i = 0; i < 10; i++) {
            let l = `${i}${i}`
            lotnum.push(l)
        }
        if(doubleOption === 1){
            setDoubleOption(0);
            for (let i = 0; i< 10 ; i++ ){
                if( door19Option.includes(i.toString()) || rootStartOption.includes(i.toString()) || rootEndOption.includes(i.toString()) ){
                    const index = lotnum.indexOf(`${i}${i}`);
                    if ( index >= 0 ) lotnum.splice(index, 1);
                }
            }
            delLottoList(lotnum);
        }else{
            setDoubleOption(1);
            addLottoList(lotnum);
        }
        
    }

    const tripleNumber = (e) =>{
        let lotnum = []
        for (let i = 0; i < 10; i++) {
            let l = `${i}${i}${i}`
            lotnum.push(l)
        }
        if(tripleOption === 1){
            setTripleOption(0);
            delLottoList(lotnum);
        }else{
            setTripleOption(1);
            addLottoList(lotnum);
        }
    }

    const addLottoList = (lotnum) => {
        var arrLotnum = finalLotNumber
        let arrNum = arrNumber
        typeSelect.map((type,i)=>{

            if(arrLotnum[type]){
                var objnum = arrLotnum[type]
                lotnum.map((l,i)=>{
                    if(objnum.lot_num.includes(l)){
                        return false; 
                    } 
                    objnum.lot_num.push(l)
                })
                // objnum.lot_num = [...objnum.lot_num,...lotnum]
                arrLotnum[type] = objnum
            }else{
                var objnum = {}
                objnum[`${type}`] = {lot_type:type,lot_num:lotnum,lot_amount:1}
                arrLotnum = {...arrLotnum,...objnum}
                // return {lot_type:type,lot_num:lotnum}
            }
        })
        lotnum.map((n,i)=>{
            if(!arrNum.includes(n)){
                
                arrNum.push(n)
            }
        })
        setFinalLotNumber(arrLotnum)
        setArrNumber([...arrNum])
        // setStateReload(uuidv4())
    }
    const delLottoList = (arrayNumPop) => {
        let arrfNum = {...finalLotNumber}
        let arrNum = [...arrNumber]
        arrayNumPop.map((numPop,i)=>{
            typeSelect.map((lt)=>{
                if(arrfNum[lt]){
                    const ni = arrfNum[lt].lot_num.indexOf(numPop)
                    if(ni>=0) arrfNum[lt].lot_num.splice(ni,1)
                    if(arrfNum[lt].lot_num.length<=0){
                        delete arrfNum[lt]
                    } 
                    // const ai = arrNum.indexOf(numPop)
                    // if(ai>=0) arrNum.splice(ai,1)
                    arrNum = arrNum.filter((arr)=>{
                        return arr !== numPop
                    })
                }
            })
        })
        
        setFinalLotNumber(arrfNum)
        setArrNumber(arrNum)
        setStateReload(uuidv4())
    }

    const okSelect = (e)=>{
        var lotnum = $('#n-1').val()+ $('#n-2').val()+ $('#n-3').val()+ $('#n-4').val()
        if(lotnum.length < 4 ) return false;
        // var lotnum = e.target.value
        if(toggleStatus){
            lotnum = toggleNum(lotnum)
        }else{
            lotnum = [lotnum]
        }
        var arrLotnum = finalLotNumber
        typeSelect.map((type,i)=>{
            if(arrLotnum[type]){
                var objnum = arrLotnum[type]
                lotnum.map((l,i)=>{
                    if(objnum.lot_num.includes(l)) return false;
                    objnum.lot_num.push(l)
                })
                // objnum.lot_num = [...objnum.lot_num,...lotnum]
                arrLotnum[type] = objnum
            }else{
                var objnum = {}
                objnum[`${type}`] = {lot_type:type,lot_num:lotnum}
                arrLotnum = {...arrLotnum,...objnum}
                // return {lot_type:type,lot_num:lotnum}
            }
        })
        setFinalLotNumber(arrLotnum)
        setArrNumber([...arrNumber,...lotnum])
        // toggleNum(num)
        $('#n-1').val('')
        $('#n-2').val('')
        $('#n-3').val('')
        $('#n-4').val('')
    }

    // const ToggleNumber = (e)=>{
    //     e.target.classList.toggle('active')
    // }

    const selectBtn = (e)=>{
        e.target.classList.toggle('active')
    }

    const keySelect = (e)=>{
        const num = e.target.value
        if($('#n-1').val() === ''){
            $('#n-1').val(num) 
        }else if($('#n-2').val() === ''){
            $('#n-2').val(num) 
        }else if($('#n-3').val() === ''){
            $('#n-3').val(num) 
        }else if($('#n-4').val() === ''){
            $('#n-4').val(num) 
        }
    }

    const popKeySelect = ()=>{
        const listLotto = finalLotNumber
        const arrN = arrNumber
        lotTypeOpen[4].map((lt,i)=>{
            if(listLotto[lt.lt_key]){
                const numpop = listLotto[lt.lt_key].lot_num.pop()
                const n = arrN.indexOf(numpop)
                if(n>=0){
                    arrN.splice(n, 1);
                }
                if(listLotto[lt.lt_key].lot_num.length<1){
                    delete listLotto[lt.lt_key];
                }
            }

        })
        setFinalLotNumber(listLotto)
        setArrNumber(arrN)
        setStateReload(uuidv4())
    }
    

    const delSelect = (e)=>{
        const num = e.target.value
        if($('#n-4').val() != ''){
            $('#n-4').val('') 
        }else if($('#n-3').val() != ''){
            $('#n-3').val('') 
        }else if($('#n-2').val() != ''){
            $('#n-2').val('') 
        }else if($('#n-1').val() != ''){
            $('#n-1').val('') 
        }
    }

    const toggleNum = (num)=>{
        const arrNum = num.split('').sort()
        if(arrNum.length === 4){
            var t_num = []
            arrNum.map((n,i)=>{
                arrNum.map((m,j)=>{
                    arrNum.map((x,l)=>{
                        arrNum.map((p,r)=>{
                            if(t_num.includes(n.toString()+m.toString()+x.toString()+p.toString())) return false;
                            if(!(arrNum.sort().join('') === [n.toString(),m.toString(),x.toString(),p.toString()].sort().join(''))) return false;
                            t_num.push(n.toString()+m.toString()+x.toString()+p.toString());
                        })
                    })
                })
            })
            return t_num
        }else if(arrNum.length === 3){
            var t_num = []
            arrNum.map((n,i)=>{
                arrNum.map((m,j)=>{
                    arrNum.map((x,l)=>{
                        if(t_num.includes(n.toString()+m.toString()+x.toString())) return false;
                        if(!arrayEquals(arrNum.sort(),[n.toString(),m.toString(),x.toString()].sort())) return false;
                        t_num.push(n.toString()+m.toString()+x.toString());
                    })
                })
            })
            return t_num
        }else if(arrNum.length === 2){
            var t_num = []
            arrNum.map((n,i)=>{
                arrNum.map((m,j)=>{
                    if(i==j) return false;
                    if(t_num.includes(n.toString()+m.toString())) return false;
                    t_num.push(n.toString()+m.toString())
                })
            })
            return t_num
        }else{
            return arrNum;
        }
    }

    function allAreEqual(array) {
        const result = array.every(element => {
          if (element === array[0]) {
            return true;
          }
        });
        return result;
    }

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const cancelBill = ()=>{
        if(Object.keys(finalLotNumber).length>0){
            Swal.fire({
                text: 'คุณต้องการยกเลิกรายการทั้งหมดหรือไม่ ?',
                showCancelButton: true,
                icon:'question',
                confirmButtonText: 'ยืนยัน',
                confirmButtonColor:'red',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    setFinalLotNumber({})
                    setArrNumber([])
                    setTripleOption(0)
                    setDoubleOption(0)
                    setDoor19Option([])
                    setRootStartOption([])
                    setRootEndOption([])
                } 
                $('.btn-close').click();
            })
        }
        
    }

    // const selectOption = (opt) => {
    //     setOption(opt)
    // }

  return (
    <>
        <section id="lotto-panel">
            <div className='container'>
                <div className='text-end'>
                    <Link to={"/lotto_limited/"+op_id} className=" text-warning">
                        <i className="bi bi-info-circle-fill"></i> เลขเต็ม/จ่ายครึ่ง
                    </Link> 
                </div>                
                <div className='lotto-title'>
                    {openInfo?openInfo.lz_name:''}
                </div>
                <div className='lotto-des'>ปิดรับแทง {openInfo?moment(openInfo.op_date_time_stop).format('DD-MM-YYYY HH:mm:ss'):''}</div>
                <div className='lotto-time' id='count-down'> </div>
                
                <div className='panel'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='bg-light p-2'>
                                <div>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        
                                        {Object.keys(lotTypeOpen).sort().reverse().map((type,i)=>{
                                            return (
                                                <li className="nav-item" role="presentation" key={type} style={{width:`${(100/Object.keys(lotTypeOpen).length)}%`}}>
                                                    <button className={`nav-link ${!i?'active':''} w-100`}  id={`pills-${type}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${type}`} type="button" role="tab" aria-controls={`pills-${type}`} aria-selected={!i?'true':''} onClick={()=>{
                                                        setMainZone(type);
                                                        const log_g = lotTypeOpen[type].length?lotTypeOpen[type]:[];
                                                        const open = log_g.filter((g,i)=>{
                                                            return openInfo.pay_array[g.lt_key] > 0
                                                        })
                                                        if(open.length>0){
                                                            setTypeSelect([open[0].lt_key.toString()])
                                                        }else{
                                                            setTypeSelect([]);
                                                        }
                                                        setToggleStatus(0);
                                                        setOption(0);
                                                     }
                                                    }>{type==='1'?'วิ่ง':type+' ตัว'}</button>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {Object.keys(lotTypeOpen).sort().reverse().map((type,i)=>{
                                            return (
                                                <div className={`tab-pane fade show ${!i?'active':''}`} id={`pills-${type}`} role="tabpanel" aria-labelledby={`pills-${type}-tab`} tabIndex={0} key={type}>
                                                    <div className='row'>
                                                        {/* <div className='col-6'>
                                                                <input type="tel" className={'form-control mb-2 text-center search-num search-num-'+type} placeholder="ค้นหาเลข" maxLength={type}  disabled={type==='4'||type==='1'?true:false} onChange={()=>searchNumber(type)}/>
                                                        </div>
                                                        <div className='col-6'>
                                                                <button className='btn btn-outline-primary w-100' onClick={ToggleNumber}><i className="bi bi-arrow-left-right"></i> เลขสลับ</button>
                                                        </div> */}
                                                        {lotTypeOpen[type].map((sublotto,i)=>{
                                                            return (
                                                                <div className="col-sm-6 my-1" key={i}>
                                                                    <div className="btn-group w-100" role="group" aria-label="Basic example">
                                                                        <button type="button" className={`btn btn-outline-${openInfo?(openInfo.pay_array[sublotto.lt_key]>0?'warning':'secondary'):'secondary'} w-75 ${typeSelect.includes(sublotto.lt_key.toString())?'active':''}`} value={sublotto.lt_key} onClick={(e)=>{btnTypeSelect(e);}} disabled={openInfo?(openInfo.pay_array[sublotto.lt_key]>0?false:true):true}>{sublotto.lt_name}</button>
                                                                        <button type="button" className="btn btn-secondary w-15">฿{openInfo?openInfo.pay_array[sublotto.lt_key]:"0.00"}</button>
                                                                    </div>
                                                                </div>  
                                                            ) 
                                                        })}
                                                        <div className='col-12 mt-2'></div>
                                                        <div className='col-6'>
                                                                <input type="tel" className={'form-control mb-2 text-center search-num search-num-'+type} placeholder="ค้นหาเลข" maxLength={type}  disabled={type==='4'||type==='1'?true:false} onChange={()=>{searchNumber(type);}} onFocus={()=>{if(labelList)$('#btn-list-lotto').click()}}/>
                                                        </div>
                                                        <div className='col-6'>
                                                                <button className={`btn btn-outline-primary btn-tod w-100`+(toggleStatus?' active':' ')} onClick={()=>setToggleStatus(!toggleStatus)} disabled={type==='1'}><i className="bi bi-arrow-left-right"></i> เลขสลับ</button>
                                                        </div>
                                                        
                                                        <div className='col-12 mt-2'>
                                                            {{
                                                                3:  <div className='row'>
                                                                        <div className='col-lg-4 col-6 mb-1'><button className={`btn btn-outline-success ${tripleOption===1?'active':''} w-100`} onClick={(e)=>{tripleNumber(e);}}>ตอง</button></div>
                                                                    </div>,
                                                                2:  <div className='row'>
                                                                        <div className='col-lg-4 col-6 mb-1'><button className={`btn btn-outline-success ${doubleOption===1?'active':''} w-100`} onClick={(e)=>{doubleNumber(e)}} >เบิ้ล</button></div>
                                                                    </div>
                                                            }[type]}
                                                        </div>
                                                    </div>
                                                    {{
                                                        2: <div className='row mt-2'>
                                                                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                                                                    <div className=' mt-1 text-start' style={{fontSize:"10pt"}}>
                                                                        <span className="p-1 px-3 rounded-pill bg-success text-light">19 ประตู</span>
                                                                    </div>
                                                                    <div className='bg-light py-2'>
                                                                            {[0,1,2,3,4].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${door19Option.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={(e)=>{door19(e);}}>{x}</button>
                                                                                )
                                                                            })}
                                                                            {[5,6,7,8,9].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${door19Option.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={(e)=>{door19(e);}}>{x}</button>
                                                                                )
                                                                            })}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                                                                    <div className=' mt-1 text-start' style={{fontSize:"10pt"}}>
                                                                        <span className="p-1 px-3 rounded-pill bg-success text-light">รูดหน้า</span>
                                                                    </div>
                                                                    <div className='bg-light py-2'>
                                                                            {[0,1,2,3,4].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${rootStartOption.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={rootStart}>{x}</button>
                                                                                )
                                                                            })}
                                                                            {[5,6,7,8,9].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${rootStartOption.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={rootStart}>{x}</button>
                                                                                )
                                                                            })}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                                                                    <div className=' mt-1 text-start' style={{fontSize:"10pt"}}>
                                                                        <span className="p-1 px-3 rounded-pill bg-success text-light">รูดหลัง</span>
                                                                    </div>
                                                                    <div className='bg-light py-2'>
                                                                            {[0,1,2,3,4].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${rootEndOption.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={rootEnd}>{x}</button>
                                                                                )
                                                                            })}
                                                                            {[5,6,7,8,9].map((x, i) =>{
                                                                                return (
                                                                                    <button className={`btn btn-outline-success ${rootEndOption.includes(x.toString())?'active':''}`} style={{width:'18%',margin:'2px'}} key={x} value={x} onClick={rootEnd}>{x}</button>
                                                                                )
                                                                            })}
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        }[type]
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            
                            <div className={"lotto-number-panel "+(typeSelect.length<=0?'d-none':'')}>
                                    {mainZone==='4'?
                                        <>
                                         <div className='p-2 mt-2 bg-light-skin' style={{fontSize:"16pt"}}>
                                                เลือกเลข
                                            </div>
                                            <div className='bg-light p-2'>
                                                <div className='row my-3'>
                                                    <div className='col-lg-4 col-0'></div>
                                                    <div className='col-lg-1 col-3'>
                                                        <input className='form-control form-control-lg text-center n4' type='text' id='n-1' readOnly/>
                                                    </div>
                                                    <div className='col-lg-1 col-3'>
                                                        <input className='form-control form-control-lg text-center n4' type='text' id='n-2' readOnly/>
                                                    </div>
                                                    <div className='col-lg-1 col-3'>
                                                        <input className='form-control form-control-lg text-center n4' type='text' id='n-3' readOnly/>
                                                    </div>
                                                    <div className='col-lg-1 col-3'>
                                                        <input className='form-control form-control-lg text-center n4' type='text' id='n-4' readOnly/>
                                                    </div>
                                                    <div className='col-lg-4 col-0'></div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-4'>
                                                        <button className='btn btn-danger w-100 mb-1' onClick={delSelect}>ลบ</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='1' onClick={keySelect}>1</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='4' onClick={keySelect}>4</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='7' onClick={keySelect}>7</button>
                                                        <button className='btn btn-secondary w-100 mb-1' disabled>&nbsp;</button>
                                                    </div>
                                                    <div className='col-4' style={{padding:0}}>
                                                        <button className='btn btn-dark w-100 mb-1' onClick={()=>{$('.n4').val('')}}>ล้าง</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='2' onClick={keySelect}>2</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='5' onClick={keySelect}>5</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='8' onClick={keySelect}>8</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='0' onClick={keySelect}>0</button>
                                                    </div>
                                                    <div className='col-4'>
                                                        <button className='btn btn-dark w-100 mb-1' onClick={popKeySelect}>ลบล่าสุด</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='3' onClick={keySelect}>3</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='6' onClick={keySelect}>6</button>
                                                        <button className='btn btn-outline-success w-100 mb-1' value='9' onClick={keySelect}>9</button>
                                                        <button className='btn btn-warning w-100 mb-1' onClick={okSelect}>ตกลง</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    :mainZone==='3'?
                                        <>
                                            <div className='p-2 mt-2 bg-light-skin' style={{fontSize:"16pt"}}>
                                                เลือกหมวดเลข
                                            </div>
                                            <div className='bg-light p-2'>
                                                {['0','1','2','3','4','5','6','7','8','9'].map((x, i) =>{
                                                    return (
                                                        <button className={`btn btn-outline-success m-1 btn-mode btn-mode-${x}`} style={{width:'60px'}} key={i} onClick={()=>{setLengthNum([...Array(100)]);setNumMode(x);}}>{x+'00'}</button>
                                                    )
                                                })}
                                            </div>
                                            <div className='p-2 mt-2 bg-light-skin' style={{fontSize:"16pt"}}>
                                                เลือกเลข
                                            </div>
                                            <div className='bg-light p-2'>
                                                {lengthNum.map((x, i) =>{
                                                    if(lengthNum.length === 100){
                                                         return (
                                                            <button className={`btn ${toggleNum(numMode+(i<10?"0"+i:i).toString()).reduce((v,n)=>{ return v+" b"+n.toString()},'')} T3 ${(lotFull.includes((numMode+(i<10?"0"+i:i)).toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)?'btn-danger':(lotHaft.includes((numMode+(i<10?"0"+i:i)).toString())&&typeSelect.filter(function(n) { return haftType.indexOf(parseInt(n)) !== -1;}).length?'btn-outline-warning':'btn-outline-success')} m-1 btn-num ${arrNumber.includes((numMode+(i<10?"0"+i:i)).toString())?'active':''} ${(numMode+(i<10?"0"+i:i)).split("").reduce((v,e)=>{return v+' b'+e},"")}`} style={{width:'60px'}} key={i} value={numMode+(i<10?"0"+i:i)} onClick={(e)=>{!arrNumber.includes((numMode+(i<10?"0"+i:i)).toString())?numberSelected(e):numberPop(e);}} disabled={(lotFull.includes((numMode+(i<10?"0"+i:i)).toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)}>{numMode+(i<10?"0"+i:i)}</button>
                                                        )
                                                    }else{
                                                        const btnLotNum = (i<10?"00"+i:i<100?"0"+i:i.toString());
                                                        const tong = allAreEqual(btnLotNum.split(""))

                                                        var doubleStart = btnLotNum.split("")
                                                        doubleStart.pop()
                                                        const start = allAreEqual(doubleStart)
                                                        
                                                        var doubleEnd = btnLotNum.split("")
                                                        doubleEnd.shift()
                                                        const end =  allAreEqual(doubleEnd)
                                                        return (
                                                            <button className={`btn ${toggleNum(btnLotNum.toString()).reduce((v,n)=>{ return v+" b"+n.toString()},'')} T3 ${(lotFull.includes(btnLotNum.toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)?'btn-danger':(lotHaft.includes(btnLotNum.toString())&&typeSelect.filter(function(n) { return haftType.indexOf(parseInt(n)) !== -1;}).length?'btn-outline-warning':'btn-outline-success')} m-1 btn-num ${arrNumber.includes(btnLotNum.toString())?'active':''} ${start?'btn-num-'+doubleStart.join(''):''} ${end?'btn-num-'+doubleEnd.join(''):''} ${tong?'btn-num-'+btnLotNum:''} ${btnLotNum.split("").reduce((v,e)=>{return v+' b'+e},"")}`} style={{width:'60px'}} key={i} value={btnLotNum} onClick={(e)=>{!arrNumber.includes(btnLotNum.toString())?numberSelected(e):numberPop(e)}} disabled={(lotFull.includes(btnLotNum.toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)}>{btnLotNum}</button>
                                                        )
                                                    }
                                                })}
                                                
                                            </div>
                                        </>
                                    :mainZone==='2'?
                                        <>
                                            <div className='p-2 mt-2 bg-light-skin' style={{fontSize:"16pt"}}>
                                                เลือกเลข
                                            </div>
                                            <div className='bg-light p-2'>
                                                {[...Array(100)].map((x, i) =>{
                                                    return (
                                                        <button className={`btn ${toggleNum((i<10?"0"+i:i).toString()).reduce((v,n)=>{ return v+" b"+n.toString()},'')} T2  ${(lotFull.includes((i<10?"0"+i:i).toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)?'btn-danger':(lotHaft.includes((i<10?"0"+i:i).toString())&&typeSelect.filter(function(n) { return haftType.indexOf(parseInt(n)) !== -1;}).length?'btn-outline-warning':'btn-outline-success')} btn-lg m-1 btn-num ${arrNumber.includes((i<10?"0"+i:i).toString())?'active':''} ${((i<10?"0"+i:i.toString())).split("").reduce((v,e)=>{return v+' b'+e},"")}`} style={{width:'60px'}} key={i} value={i<10?"0"+i:i} onClick={(e)=>{!arrNumber.includes((i<10?"0"+i:i).toString())?numberSelected(e):numberPop(e)}} disabled={(lotFull.includes((i<10?"0"+i:i).toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)}>{i<10?"0"+i:i}</button>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    :mainZone==='1'?
                                        <>
                                            <div className='p-2 mt-2 bg-light-skin' style={{fontSize:"16pt"}}>
                                                เลือกเลข
                                            </div>
                                            <div className='bg-light p-2'>
                                                {[...Array(10)].map((x,i)=>{
                                                    return (
                                                        <button className={`btn ${(lotFull.includes(i.toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)?'btn-danger':(lotHaft.includes(i.toString())&&typeSelect.filter(function(n) { return haftType.indexOf(parseInt(n)) !== -1;}).length?'btn-outline-warning':'btn-outline-success')} btn-lg m-1 btn-num ${arrNumber.includes(i.toString())?'active':''} btn-num-${i}`} style={{width:'50px'}} key={i} value={i} onClick={(e)=>{!arrNumber.includes((i).toString())?numberSelected(e):numberPop(e)}}  disabled={(lotFull.includes(i.toString())&&typeSelect.filter(function(n) { return fullType.indexOf(parseInt(n)) !== -1;}).length)}>{i}</button> /* onClick={(e)=>{selectBtn(e);numberSelected(e)}} */
                                                    )
                                                })}
                                            </div>
                                        </>  
                                    :
                                    ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{position:'fixed',bottom:0,left:0,right:0,zIndex:996}}>
                    <p className=''>
                        <button id='btn-list-lotto' className="btn btn-warning" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" 
                            onClick={()=>{
                                setLabelList(!labelList)
                                }}>
                           {labelList?"ซ่อน":"แสดง"}รายการแทง 
                        </button>
                        <button className='btn btn-success mx-1' data-bs-toggle="modal" data-bs-target="#confirm-bill" disabled={Object.keys(finalLotNumber).length<=0?true:false}><i className="bi bi-currency-bitcoin"></i> ใส่ราคา </button>
                        <button className='btn bg-wrong' onClick={cancelBill} disabled={Object.keys(finalLotNumber).length<=0?true:false}><i className="bi bi-trash-fill" ></i> ล้างค่า </button>
                    </p>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body bg-dark text-light container overflow-auto" style={{height:'350px'}}>
                           <div className='row'>
                                    {Object.keys(finalLotNumber).map((lt)=>{
                                        const t = allLottype.filter((type,i)=>{
                                            return type.lt_key.toString() === lt
                                        })[0]
                                        return (<div className="mt-1 col-lg-4 col-md-4 col-sm-4" key={lt}>
                                            <div className=" rounded-top py-0 bg-dark-skin">
                                                <span>{t.lt_name}</span>
                                            </div>
                                            <div className=" rounded-bottom bg-light py-2 text-dark">
                                                <b className="">
                                                    {finalLotNumber[lt].lot_num.join('  ')}
                                                </b>
                                            </div>
                                        </div>)
                                    })}
                           </div>
                        </div>
                    </div> 
                </div>
            </div>
            
            
        </section>
        <ComfirmBill 
            finalLotNumber={finalLotNumber} 
            allLottype={allLottype} 
            arrNumber={arrNumber} 
            zone={openInfo} 
            setFinalLotNumber={setFinalLotNumber} 
            setArrNumber={setArrNumber} 
            setStateReload={setStateReload} 
            lotHaft={lotHaft} 
            tempLotHaft={tempLotHaft} 
            setTripleOption={setTripleOption} 
            setDoubleOption={setDoubleOption}
            setDoor19Option={setDoor19Option}
            setRootStartOption={setRootStartOption}
            setRootEndOption={setRootEndOption}
        />
    </>
  )
}

export default LottoPanelComponent


function ComfirmBill(props){
    const dataC = useContext(DataContext)
    const navigate = useNavigate();
    const lottoService = new LottoService()
    const lt_zone = props.zone
    const allLotType = props.allLottype
    var lottoNumber = props.finalLotNumber
    const arrNum = props.arrNumber
    // const haftRate = 50;

    const [totalAmount,setTotalAmount] = useState(0)
    const [totalWin,setTotalWin] = useState(0)
    // let totalWin = 0;

    const [allAmount,setAllAmount] = useState(1)

    const [tempAmount,setTempAmount] = useState({})
    const [tempPaylate,setTempPaylate] = useState({})
    const [tempAllPrice,setTempAllPrice] = useState({})

    useEffect(()=>{
        // sumAmount()
        let amount = tempAmount
        let paylate = tempPaylate
        let allPrice = tempAllPrice
        let paylate_new = {}
        let allPrice_new = {}
        if(Object.keys(props.finalLotNumber).length<=0){
            setTempAmount({})
            setTempPaylate({})
            setTempAllPrice({})
            props.setStateReload(uuidv4())
            sumAmount()
            return;
        }
        Object.keys(props.finalLotNumber).map((lnum,i)=>{
            const lotnum = props.finalLotNumber[lnum]
            allPrice[lotnum.lot_type] = allPrice[lotnum.lot_type]?allPrice[lotnum.lot_type]:{}
            allPrice_new[lotnum.lot_type] = []
            lotnum.lot_num.map((num,i)=>{
                const h = props.tempLotHaft.filter((lh,i)=>{
                    return lh.lh_number === num && lh.lot_type.toString() === lotnum.lot_type
                })
                let haftRate = 0
                if(h.length>0){
                    haftRate = h[0].lh_pay_percent
                }
                amount[num] = amount[num]?amount[num]:1;
                paylate[num] = paylate[num]?paylate[num]:dataC.money.format(1 * (props.lotHaft.includes(num)?((haftRate/100)*lt_zone.pay_array[lotnum.lot_type]):lt_zone.pay_array[lotnum.lot_type]));
                paylate_new[num] = paylate[num];
                allPrice[lotnum.lot_type][num] = allPrice[lotnum.lot_type][num]?allPrice[lotnum.lot_type][num]:{amount:1,payrate:1 * (props.lotHaft.includes(num)?((haftRate/100)*lt_zone.pay_array[lotnum.lot_type]):lt_zone.pay_array[lotnum.lot_type])}
                allPrice_new[lotnum.lot_type][num] = allPrice[lotnum.lot_type][num];
            })
        })
        
        setTempAmount(amount)
        setTempPaylate(paylate_new)
        setTempAllPrice(allPrice_new)
        props.setStateReload(uuidv4())
        sumAmount(undefined,allPrice_new)
        return;
    },[props.arrNumber,props.finalLotNumber])


    useEffect(()=>{
    },[props.finalLotNumber])

    const cancelBill = ()=>{
        if(Object.keys(lottoNumber).length>0){
            Swal.fire({
                text: 'คุณต้องการยกเลิกรายการทั้งหมดหรือไม่ ?',
                showCancelButton: true,
                icon:'question',
                confirmButtonText: 'ยืนยัน',
                confirmButtonColor:'red',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    props.setFinalLotNumber({})
                    props.setArrNumber([])
                    setTempAmount({})
                    setTempPaylate({})
                    setTempAllPrice({})
                    props.setTripleOption(0)
                    props.setDoubleOption(0)
                    props.setDoor19Option([])
                    props.setRootStartOption([])
                    props.setRootEndOption([])
                    props.setStateReload(uuidv4())
                } 
                $('.btn-close').click();
            })
        }
        
    }

    const addAmount = (amount)=>{
        if(amount>0){
            // setAllAmount(amount)
            // $('.lot-amount').val(amount)
            const temp = tempAmount;
            Object.keys(temp).map((num)=>{
                temp[num] = amount
            })
            setTempAmount(temp)
            props.setStateReload(uuidv4())
            const tempPay = tempPaylate;
            let allPrice = tempAllPrice
            Object.keys(lottoNumber).map((ltkey)=>{
                lottoNumber[ltkey].lot_num.map((num)=>{
                    const h = props.tempLotHaft.filter((lh,i)=>{
                        return lh.lh_number === num && lh.lot_type.toString() === ltkey
                    })
                    let haftRate = 0
                    if(h.length>0){
                        haftRate = h[0].lh_pay_percent
                    }
                    tempPay[num] = dataC.money.format(amount * (props.lotHaft.includes(num)?lt_zone.pay_array[ltkey]-((haftRate/100)*lt_zone.pay_array[ltkey]):lt_zone.pay_array[ltkey]));
                    // $('#payRate'+ltkey.toString()+num.toString()).text(dataC.money.format(amount * (props.lotHaft.includes(num)?lt_zone.pay_array[ltkey]-((haftRate/100)*lt_zone.pay_array[ltkey]):lt_zone.pay_array[ltkey])))
                    // total += parseFloat(amount);
                    allPrice[ltkey][num]['amount'] = amount
                    allPrice[ltkey][num]['payrate'] = amount * (props.lotHaft.includes(num)?lt_zone.pay_array[ltkey]-((haftRate/100)*lt_zone.pay_array[ltkey]):lt_zone.pay_array[ltkey])

                })
            })
            setTempPaylate(tempPay)
        }
        sumAmount()
    }

    const sumAmount = (e = undefined,tempprice = tempAllPrice )=>{
            var win = 0
            var total = 0.00
            // Object.keys(lottoNumber).map((ltkey)=>{
            //     lottoNumber[ltkey].lot_num.map((num)=>{
            //         const amt = parseFloat(tempAmount[num]) //$('#amo_'+ltkey.toString()+num.toString()).val()
            //         total += amt
            //         win += amt * (props.lotHaft.includes(num)?lt_zone.pay_array[ltkey]-((haftRate/100)*lt_zone.pay_array[ltkey]):lt_zone.pay_array[ltkey])
            //     })
            // })

            Object.keys(tempprice).map((type,i)=>{
                const lottype = tempprice[type]
                Object.keys(lottype).map((lot,i)=>{
                    const lotprice = lottype[lot]
                    total += parseFloat(lotprice['amount'])
                    win += parseFloat(lotprice['payrate'])
                })
            })
            $('#total-amount').html(dataC.money.format(isNaN(total)?0:total));
            // $('#total-win').html(dataC.money.format(isNaN(win)?0:win));
            // setTotalAmount(total);
            // setTotalWin(win);
            
            if(e) e.focus()
    }

    const numberPop = (lotnum,lottype)=>{
        const index = lottoNumber[lottype].lot_num.indexOf(lotnum)
        if(index>=0){
            var temp = lottoNumber[lottype].lot_num.filter((lot,i)=>{
                return lot != lotnum
            });
            lottoNumber[lottype].lot_num = temp;
            if(lottoNumber[parseInt(lottype)].lot_num.length<=0){
                delete lottoNumber[parseInt(lottype)];
            }
            var chkNum = 0;
            Object.keys(lottoNumber).map((ltkey)=>{
                lottoNumber[ltkey].lot_num.map((num)=>{
                    if(num === lotnum) chkNum =1;
                })
            })
            if(!chkNum){
                const inNum = arrNum.indexOf(lotnum)
                if(inNum>=0){
                    arrNum.splice(inNum,1)
                }
            }
            props.setArrNumber(arrNum)
            props.setFinalLotNumber(lottoNumber)
            props.setStateReload(uuidv4())
        }
        let amo_t = tempAmount
        let pay_t = tempPaylate
        delete amo_t[lotnum];
        delete pay_t[lotnum];
        setTempAmount(amo_t)
        setTempPaylate(pay_t)
        let all_p = tempAllPrice
        delete all_p[lottype][lotnum]
        if(Object.keys(all_p[lottype]).length<=0){
            delete all_p[lottype]
        }
        setTempAllPrice(all_p)

        props.setStateReload(uuidv4())
        sumAmount()
    }

    const submitLotto = (e)=>{
        // ส่งโพย
        e.preventDefault()
        if(Object.keys(lottoNumber).length<=0) return false;
        const payload = {
            "p_lz_key": lt_zone.lz_key,
            "p_num_array": [],
            "p_o_name":e.target.o_name.value

        }
        Object.keys(lottoNumber).map((ltkey)=>{
            lottoNumber[ltkey].lot_num.map((num)=>{
                payload.p_num_array.push({"lot_type":parseInt(ltkey),"num":num,"amount":parseFloat(e.target['amo_'+ltkey+num].value)})
            })
        })
        if( dataC.m_money < totalAmount ){
            Swal.fire({
                text:"ยอดคงเหลือไม่เพียงพอ",
                text:'',
                icon: 'warning',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: 'green',
            })
            return false
        }

        Swal.fire({
            title: 'ยืนยันการซื้อหวย',
            html: 'กรุณาตรวจสอบโพยหวยหลังยื่นยันการซื้อ',
            customClass: {
                title: 'swal-title',
                htmlContainer:'swal-text',
            },
            showCancelButton: true,
            icon:'question',
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor:'green',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                dataC.blockUI(true)
                lottoService.add_order(payload).then((result)=>{
                    if (result.success){
                        dataC.moneyUpdate(result.money)
                        setTimeout(function (){
                            dataC.blockUI(false) 
                        }, 1000);
                        
                        localStorage.setItem('m_money',result.money,60000)
                        var html_success = ''
                        var btnCancel = {showCancelButton: false,cancelButtonText: 'ยกเลิกรายการ',cancelButtonColor: 'red'}
                        const order_id = result.order_id
                        result.data.map((lotto,i)=>{
                            html_success += `
                                <tr class="${lotto.status===6 || lotto.status===8 || lotto.status===9 || lotto.status===10?'text-danger':(lotto.status===7?'text-warning':'')}">
                                    <td>${i+1}</td>
                                    <td>${lotto.name_type}</td>
                                    <td>${lotto.num}</td>
                                    <td>${lotto.amount}</td>
                                    <td>${lotto.message}</td>
                                    <td>${lotto.status===1?
                                         '<i class="bi bi-check-lg text-success"></i>'
                                        :(lotto.status===7?'<i class="bi bi-exclamation-circle text-warning"></i>':'<i class="bi bi-x-lg text-danger"></i>')}</td>
                                </tr>
                            `
                            if(lotto.status !== 1){
                                btnCancel.showCancelButton = true;
                            }
                        })
                        Swal.fire({
                            text: '<strong><i class="bi bi-check-circle text-success"></i> สร้างโพยหวยสำเร็จ</strong>',
                            html:   `<table class="table" style="font-size:0.7em">
                                        <thead>
                                        <tr>
                                            <th scope="col">ลำดับ</th>
                                            <th scope="col">ประเภท</th>
                                            <th scope="col">เลข</th>
                                            <th scope="col">แทง</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        ${html_success}
                                        </tbody>
                                    </table>`,
                            showCloseButton: false,
                            showDenyButton: true,
                            ...btnCancel,
                            focusConfirm: false,
                            denyButtonText:'ไปที่หน้าโพยหวย',
                            denyButtonColor:'#007bff',
                            confirmButtonText: 'แทงต่อ',
                            confirmButtonColor: 'green',
                          }).then((result)=>{
                                $('.btn-close').click();
                                if(result.dismiss === Swal.DismissReason.cancel){
                                    lottoService.can_order({p_o_id:order_id}).then((result)=>{
                                        if (result.success){
                                            Swal.fire({
                                                text: result.message,
                                                text:'',
                                                icon: 'success',
                                                confirmButtonText: 'ตกลง',
                                                confirmButtonColor: 'green',
                                            })
                                        }else {
                                            Swal.fire({
                                                text: result.message,
                                                text:'',
                                                icon: 'warning',
                                                confirmButtonText: 'ตกลง',
                                                confirmButtonColor: '#890000',
                                            })
                                        }
                                    })
                                }

                                if (result.isConfirmed) {
                                    props.setFinalLotNumber({})
                                    props.setArrNumber([])
                                    props.setTripleOption(0)
                                    props.setDoubleOption(0)
                                    props.setDoor19Option([])
                                    props.setRootStartOption([])
                                    props.setRootEndOption([])
                                    $('#o_name').val('')
                                }

                                if (result.isDenied){
                                    navigate("/play-history")
                                    // window.location.replace("/play-history");
                                }


                          })
                    }else{
                        Swal.fire({
                            text: result.message,
                            icon: 'warning',
                            confirmButtonText: 'ตกลง',
                            confirmButtonColor: '#890000',
                        })
                        dataC.blockUI(false) 
                    }
                })
            } 
        })
    }

    const handleKeyPress = (event) => {
        event.preventDefault()
        if(event.key === 'Enter'){
        }
    }

    return (
        <div className="modal fade" id="confirm-bill" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={submitLotto} id="frm-lotto">
                        <div className="modal-header bg-dark-skin">
                            <h5 className="modal-title" id="staticBackdropLabel"><i className="bi bi-cash-coin"></i> ใส่ราคา</h5>
                            <button type="button" className="btn btn-close border bg-light" data-bs-dismiss="modal" aria-label="Close" style={{}} />
                        </div>
                        <div className="modal-body bg-dark-skin-200">
                            {Object.keys(lottoNumber).length<=0?
                                <h2>ไม่มีรายการที่คุณเลือก</h2>
                            :
                                Object.keys(lottoNumber).map((lt_key,x)=>{
                                    const type = allLotType.filter((lt,i)=>{
                                        return lt.lt_key == lt_key
                                    })[0]
                                    const lotNum = lottoNumber[lt_key]
                                    const amountType = tempAllPrice[lt_key]
                                    return (
                                        <div className="mt-1 col-12" key={uuidv4()}>
                                            <div className="rounded-top py-1 px-2 bg-warning text-start">
                                                <span className=''>{type.lt_name}</span>
                                                <span className='float-end'>ส่วนลด : { lt_zone.dis_array[lt_key] }%</span>
                                            </div>
                                            <div className=" rounded-bottom px-1 py-2 text-dark bg-light" >
                                                <div className="row" style={{margin: 0}}>
                                                    {/* <div className="col-3"> no. </div> */}
                                                    <div className="col-3"> เลข </div>
                                                    <div className="col-3"> แทง </div>
                                                    <div className="col-4"> ชนะ </div>
                                                    <div className="col-2"> </div>
                                                </div>
                                                    {lotNum.lot_num.map((lot,i)=>{
                                                        const price = amountType?amountType[lot]:{}
                                                        const h = props.tempLotHaft.filter((lh,i)=>{
                                                            return lh.lh_number === lot && lh.lot_type.toString() === lt_key
                                                        })
                                                        let haftRate = 0
                                                        if(h.length>0){
                                                            haftRate = h[0].lh_pay_percent
                                                        }
                                                        return (
                                                            <b className="" key={uuidv4()}>
                                                                <div className="row cover-list-modal-number my-1" style={{margin: 0}}>
                                                                    {/* <div className='col-1 fw-normal'><small>{i+1}</small></div> */}
                                                                    <div className={"col-3 cover-number "+ (haftRate?'bg-warn':'bg-correct')} style={{ textAlign: 'center'}}>
                                                                        <input className='d-none' defaultValue={lt_key} id={"t_"+lt_key+lot}/>
                                                                        <input type="text" tabIndex={-1}  className="form-control form-control-sm text-center text-light" defaultValue={lot} id={"num_"+lt_key+lot} style={{border: 'none',background:'none'}} readOnly/>
                                                                    </div>
                                                                    <div className="col-3 cover-input-amount border" style={{paddingLeft: 0, paddingRight: 0}}>
                                                                        {/* bet amount */}
                                                                        <input type="tel" tabIndex={0} className="form-control form-control-sm text-center lot-amount" defaultValue={price?price['amount']:0} id={"amo_"+lt_key+lot} style={{border: 'none'}} maxLength={7} onChange={(e)=>{
                                                                            let allPrice = tempAllPrice
                                                                            allPrice[lt_key][lot]['amount'] = e.target.value;
                                                                            allPrice[lt_key][lot]['payrate'] = e.target.value * (props.lotHaft.includes(lot)?((haftRate/100)*lt_zone.pay_array[lt_key]):lt_zone.pay_array[lt_key])
                                                                            setTempAllPrice(allPrice);
                                                                            $('#payRate'+lt_key+lot).html(dataC.money.format(isNaN(allPrice[lt_key][lot]['payrate'])?0:allPrice[lt_key][lot]['payrate']))
                                                                            // props.setStateReload(uuidv4())
                                                                            sumAmount(e.target);
                                                                            // e.target.focus()
                                                                        }} pattern="[0-9]+" title="กรุณากรอกจำนวนเต็ม" />
                                                                    </div>
                                                                    <div className="col-4 cover-amount-paid fw-normal bg-color-1" style={{ paddingTop: 6}}>
                                                                        <span id={'payRate'+lt_key+lot}>{dataC.money.format(price?price['payrate']:0)}</span>
                                                                    </div>
                                                                    <div className="col-2 cover-button-delete-number">
                                                                        <button type="button" tabIndex={-1} className="button-delete-number btn btn-sm bg-wrong px-0" onClick={()=>{numberPop(lot,lt_key)}} style={{borderRadius: 0, width: '100%'}}>ลบ</button>
                                                                    </div>
                                                                </div>
                                                            </b>
                                                        )
                                                    })}                                            
                                            </div>
                                        </div>)
                                    })
                                }
                           
                                <div className={'bg-light rounded mt-1 text-dark '}>
                                    <div className={"row m-0 "}>
                                        <div className="col-3">  </div>
                                        <div className="col-3">  </div>
                                        <div className="col-4 fw-bold" id="total-win"></div>
                                    </div>
                                </div>
                            
                            
                            <div className="row">
                                <div className="col col-12 col-lg-6">
                                    <div className="input-group my-2 mt-3">
                                        <input type="number" className="form-control" id="key-num" placeholder="เดิมพันราคาเท่ากัน" aria-describedby="button-addon2" />
                                        <button className="btn btn-warning" type="button" id="button-addon2" style={{borderRadius:"0px !important"}} onClick={()=>{addAmount(parseInt($('#key-num').val()))}}>ใส่ราคาทั้งหมด</button>
                                    </div>
                                    <div className='row my-1 px-2'>
                                        <div className='col px-1'>
                                            <button type="button" className='btn btn-warning w-100 px-0' onClick={()=>{addAmount(5)}}>5</button>
                                        </div>
                                        <div className='col px-1'>
                                            <button type="button" className='btn btn-warning w-100 px-0' onClick={()=>{addAmount(10)}}>10</button>
                                        </div>
                                        <div className='col px-1'>
                                            <button type="button" className='btn btn-warning w-100 px-0' onClick={()=>{addAmount(15)}}>15</button>
                                        </div>
                                        <div className='col px-1'>
                                            <button type="button" className='btn btn-warning w-100 px-0' onClick={()=>{addAmount(20)}}>20</button>
                                        </div>
                                        <div className='col px-1'>
                                            <button type="button" className='btn btn-warning w-100 px-0' onClick={()=>{addAmount(50)}}>50</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-lg-6">
                                    <div className="my-2 mt-3">
                                        <input type="text" className="form-control" id="o_name" name="o_name" placeholder="กรอกชื่อผู้ซื้อ (ไม่จำเป็น)" />
                                    </div>
                                </div>
                            </div>
                            <div className='row px-0'>
                                <div className='col-6 bg-dark-skin '>
                                    <h5><span className="badge rounded-pill bg-light w-100 text-dark mt-2">ยอดคงเหลือ</span></h5>
                                    <h5 className="text-light">฿ {dataC.money.format(dataC.m_money)} </h5>
                                </div>
                                <div className='col-6 bg-dark-skin'>
                                    <h5><span className="badge rounded-pill bg-light w-100 text-dark mt-2">ยอดซื้อสุทธิ</span></h5>
                                    {/* <h5>฿ {dataC.money.format(totalAmount)} </h5> */}
                                    <h5 className="text-light">฿ <span id='total-amount'></span> </h5>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer d-block bg-dark-skin-200">
                            <div className='row'>
                                <div className='col'>
                                    <button type="button" className="btn bg-wrong w-100" onClick={cancelBill}> <i className="bi bi-trash"></i> ล้างค่า</button>
                                </div>
                                <div className='col'>
                                    <button type="submit" className="btn btn-warning w-100"><i className="bi bi-send-plus"></i> ส่งโพยหวย</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

function countDownDate(date,_id){
    var x = setInterval(function() {
  
      const timeZone = "Asia/Bangkok";
      // Set the date we're counting down to
      // var stopDate = new Date(date).getTime();
      const stopDate = moment.tz(date, timeZone);

      // Get today's date and time
      // var now = new Date().getTime();
      const now = moment.tz(timeZone);
        
      // Find the distance between now and the count down date
      var distance = stopDate - now;
        
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
      if(!document.getElementById(_id)){
        clearInterval(x);
        return false;
      }
        
      // Output the result in an element with id="demo"
      document.getElementById(_id).innerHTML = (days>0?(days + " วัน "):'') + (hours.toString().length<2?'0'+hours.toString():hours.toString()) + ":" + (minutes.toString().length<2?'0'+minutes.toString():minutes.toString()) + ":" + (seconds.toString().length<2?'0'+seconds.toString():seconds.toString()) + "";
        
      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        document.getElementById(_id).innerHTML = "ปิดรับแทง";
      }
    }, 1000);
  }

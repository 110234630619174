import React from 'react'
import moment from 'moment';

function TransferRecordComponent(props) {
    const money = Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2
    });
    const transferData = props.data
    return transferData.map((row,i)=>{
         return (
            <div className="card mb-2" key={i}>
                <div className="card-body mx-2 py-1">
                    <div className='float-start'>
                        <small style={{fontSize:'8pt'}}>{moment(row.tf_time).format('DD-MM-YYYY HH:mm:ss')}</small>
                        <br/>
                        {row.tf_type_name}
                    </div> 
                    <div className='float-end text-center' style={{fontSize:'10pt'}}>
                        {row.tf_type===1?
                            <span className='rounded-pill bg-success px-2'>+{money.format(row.tf_money)}</span>
                        :
                            <span className='rounded-pill bg-danger px-2'>-{money.format(row.tf_money)}</span>
                        } 
                        <br/>
                        <span className={`badge rounded-pill ${row.tf_status==0?'bg-warning text-dark':row.tf_status==1?'bg-success':row.tf_status==2?'bg-danger':''} mt-1`}>{row.tf_status_name}</span>                       
                    </div> 
                </div>
            </div>
        )
    })
 
}

export default TransferRecordComponent
import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery'
import TransferService from '../../Services/Transfer.service'
import DataContext from '../../Data/DataContext'
// import '../css/Transaction.css'
import CreditBalance from './CreditBalance'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import Resizer from 'react-image-file-resizer'
import 'react-toastify/dist/ReactToastify.css';
import { scanFile } from "@openhealthnz-credentials/pdf-image-qr-scanner";
import { ToastContainer, toast } from 'react-toastify';

function DepositComponent() {
  const transferService = new TransferService()
  const dataC = useContext(DataContext)
  const navigate = useNavigate();

  const [bankList,setBankList] = useState([])
  const [bankID,setBankID] = useState();
  const [depositType,setDapositType] = useState(2);
  const [depositAmount,setDepositAmount] = useState()
  const [statusMatch,setStatusMatch] = useState()
  const [ QRcode, setQRcode ] = useState('')

  const [match,setMatch] = useState({})
  const [reStatus,setReStatus] = useState()

  const [min,setMin] = useState(0)
  const [sec,setSec] = useState(0)

  useEffect(()=>{
    getBank()
    checkMatch()
  },[])

  useEffect(()=>{
    // console.log(depositAmount)
  },[depositAmount])

  const uploadSlip = (e)=>{
      const imgBase64 = $('#img-base64').val()
      if(imgBase64){
        dataC.blockUI(true)
        transferService.upload_slip({p_image_slip:imgBase64.split(',')[1],p_qrcode_slip:QRcode}).then((result)=>{
          setTimeout(function (){
              dataC.blockUI(false) 
          }, 1000);
          if(result.success){
            Swal.fire({
              text: result.message,
              icon:'success',
              confirmButtonText: 'ตกลง',
              confirmButtonColor: 'green'
            }).then((result) => {
                checkMatch()
                setStatusMatch(undefined)
                setDepositAmount(undefined)
                setBankID(undefined)
                if(result.isConfirmed){
                  navigate("/transfer/deposit") // window.location.replace("/transfer");
                }
            })
          }else{
            toast.error(result.message);
          }
        })
      }
  }

  const depositMatch = (e) => {
    const payload = { 
      "p_amount": depositAmount, 
      "p_dep_type": depositType, 
      "p_b_id": bankID 
    }
    dataC.blockUI(true)
    transferService.depositing(payload).then((result)=>{
      setTimeout(function (){
          dataC.blockUI(false) 
      }, 1000);
      // console.log(result)
      if(result.success){
        // checkMatch()
        setMatch(result.data)
        if(Object.keys(result.data).length) countDownTime(result.data.time_count)
        setStatusMatch(1)
      }
    })
  }

  const getBank = () => {
    dataC.blockUI(true)
    transferService.listing_bank().then((result)=>{
      setTimeout(function (){
          dataC.blockUI(false) 
      }, 1000);
      if(result.success){
          setBankList(result.data)
      }
    })
  }

  const checkMatch = ()=>{
    transferService.getting_match().then((result)=>{
      if(result.success){
        setMatch(result.data)
        if(Object.keys(result.data).length) countDownTime(result.data.time_count)
      }
    })
  }

  const countDownTime = (time) => {
      let _min = parseInt(time/60)
      let _sec = time%60
      clearInterval(x);
      var x = setInterval(function() {
        if(_min <= 0 && _sec <= 0){
          clearInterval(x);
          checkMatch()
        }
        $('.s-time').html('0'+_min+':'+(_sec.toString().length<2?'0'+_sec.toString():_sec.toString()))
        if($('.s-time').length == 0){
          clearInterval(x);
        }
        _sec -= 1
        if(_sec<=0){
          _min-=1
          if(_min>=0) _sec = 59
        }
      },1000)
  }

  async function encodeImageFileAsURL(element) {
    var file = element.target.files[0];
    let img = await resizeFile(file);
    $('.img-slip').attr('src',img);
    $('#img-base64').val(img);
    try {
      const qr = await scanFile(file);
      setQRcode(qr)
      // console.log('QRcode', qrCode)
    } catch (e) {
        if (e?.name === "InvalidPDFException") {
            console.log("Invalid PDF");
        } else if (e instanceof Event) {
            console.log("Invalid Image");
        } else {
            console.log("Unknown error:", e);
        }
    }
    // var reader = new FileReader();
    // reader.onloadend = function() {
    //   $('.img-slip').attr('src',reader.result);
    //   $('#img-base64').val(reader.result);
    // }
    // reader.readAsDataURL(file);
  }

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
  });

  const slipCancel = () => {
    $('.img-slip').attr('src','images/slip-demo.png')
    $('#img-base64').val('')
  }

  const backToBank = ()=>{
    setBankID(undefined)
    setDepositAmount(undefined)
  }

  const chkAmount = (e)=>{
    setDepositAmount(e.target.value)
    setReStatus(uuidv4())
    // console.log(e.target.value)
  }

  const copyLink = (text)=>{
    let copyText = text;
    navigator.clipboard.writeText(copyText);
    toast.success("คัดลอกสำเร็จ",{position: "top-center"});
  }

  return (
    <section id="deposit" className="mt-5 pt-5">
        <div className="container">
          <CreditBalance />
          <hr/>
          {(Object.keys(match).length? 
            <>
              <div className="card text-on-background mb-3 text-center">
                  <div className="card-header text-on-background">กรุณาฝากเงินเข้าบัญชีที่ท่านได้เลือกไว้</div>
                  <div className="card-body text-on-background">
                      <h5 className="card-title">จำนวนที่ต้องฝาก {match.tm_money}</h5>
                      <div className="card-text">
                        <div className='col-sm-4 m-auto p-auto'>
                          <div className="card m-1 mb-0 text-on-background" style={{cursor:'pointer'}}>
                            <div className="card-body">
                              <i className={`bank bank-${dataC.arrBank.filter((re)=>match.b_bank==re.id.toString())[0].b_code} huge float-start me-2`}></i>
                              <p className="card-subtitle mb-2 ">{match.b_bank_name}</p>
                              <h5 className="card-title text-warning">{match.b_acc_number}</h5>
                              <p className="card-subtitle ">{match.b_acc_name}</p>
                            </div>
                            <span className='badge rounded-pill bg-warning text-dark mx-2 mb-2' style={{cursor:'pointer'}} onClick={()=>{copyLink(match.b_acc_number)}}> คัดลอกหมายเลขบัญชี</span>
                          </div>
                        </div>
                        <p className='fs-5 mt-2'>กรุณาฝากเงินภายใน <span className="s-time">00:00</span> นาที</p>
                        <p>**หากไม่ได้รับเครดิต หลังเติมเงิน 2 วินาที โปรดแจ้งส่งสลิปโอนให้แอดมิน</p>
                      </div>
                  </div>
              </div>
              <div className='row text-on-background'>
                    <div className='col col-sm-4'>
                      <div className="card mb-3 p-2" style={{backgroundColor:"#0b2c31"}}>
                        <img src={`web/${process.env.REACT_APP_NAME}/slip-demo.png`} className="img-fluid rounded-start w-100 rounded-3 img-slip" alt="..."/>
                      </div>
                    </div>
                    <div className='col col-sm-4'>
                      <p>อัพโหลดสลิปการโอนเงิน</p>
                      <form onSubmit={uploadSlip}>
                        <div className="mb-3 text-left">
                          <input type="text" id="img-base64" style={{display:'none'}} />
                          <input type="file" className="form-control" id="file-slip" style={{display:'none'}} onChange={encodeImageFileAsURL}/>
                          <a href="#" className="btn btn-warning" onClick={()=>$('#file-slip').click()}><i className="bi bi-images"></i> เลือกสลิปการโอน</a>
                          <div id="emailHelp" className="form-text text-danger">(กรุณาแนบสลิปจริง และสลิปต้องไม่เกิน 5 นาที)</div>
                        </div>
                        <button type="button" className="btn bg-dark-skin-100 mb-2 btn-upload" onClick={uploadSlip}><i className="bi bi-cloud-upload-fill" /> อัพโหลดรูปภาพ</button>
                        {/* <button type="button" className="btn btn-danger mb-2 mx-2 btn-cancel" onClick={slipCancel} ><i className="bi bi-x-lg"></i> ยกเลิก</button> */}
                      </form>
                    </div>
                  </div>
            </>
          :!bankID?
            <>
              <h4 className='text-center text-on-background'>เลือกบัญชีธนาคารของเราตามที่ท่านสะดวกโอน</h4>
              <div className='row'>
                {bankList.map((bank,i)=>{
                    const bankArr = dataC.arrBank.filter((re)=>bank.b_bank==re.id)[0]
                    // console.log(bankArr)
                    return (
                      <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
                        <div className="card m-1 bg-dark-skin" style={{cursor:'pointer'}} onClick={()=>{setBankID(bank.b_id)}}>
                          <div className="card-body">
                            <i className={`bank bank-${bankArr.b_code} huge float-start me-2`}></i>
                            <h6 className="card-subtitle mb-2 text-light">{bank.b_bank_name}</h6>
                            <h5 className="card-title text-warning">{bank.b_acc_number}</h5>
                            <h6 className="card-subtitle mb-2 text-light">{bank.b_acc_name}</h6>
                          </div>
                        </div>
                      </div>

                    )
                })}
              </div>
            </>
          :
          (
            !depositType?
            <>
              <div className='text-center'>
                <h4>เลือกวิธีการฝากเงิน</h4>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <button className="btn btn-success w-100" onClick={()=>{setDapositType(1)}}> <i className="bi bi-cash-coin"></i> ฝากแบบทศนิยม</button>
                  </div>
                  <div className='col-6'>
                    <button className="btn btn-success w-100" onClick={()=>{setDapositType(2)}}> <i className="bi bi-file-text-fill"></i> ฝากแบบแจ้งสลิป</button>
                  </div>
                </div>
              </div>
            </>
            :
            (depositType === 2 && !statusMatch?
              <>
              <div className='text-center mb-3'>
                <button className='btn btn-sm btn-warning m-auto p-auto' onClick={backToBank}><i className="bi bi-bank2"></i> เลือกบัญชีธนาคาร</button>
              </div>
                <div className='row'>
                  <div className='col-sm-3'/>
                  <div className='col-sm-6 text-on-background'>
                      <h4 className='text-center'>ฝากแบบแจ้งสลิป</h4>
                      <div className="mb-3">
                        <label className="form-label">จำนวนเงินที่ต้องการฝาก</label>
                        <input type="tel" className="form-control text-center" id="p_amount" placeholder="0.00" defaultValue={depositAmount} onChange={(e)=>{setDepositAmount(e.target.value)}}/>
                      </div>
                      <div className='row'>
                        <div className='col-4 pe-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={50} onClick={chkAmount}>50</button>
                        </div>
                        <div className='col-4 px-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={100}  onClick={chkAmount}>100</button>
                        </div>
                        <div className='col-4 ps-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={200}  onClick={chkAmount}>200</button>
                        </div>
                        <div className='col-4 pe-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={500}  onClick={chkAmount}>500</button>
                        </div>
                        <div className='col-4 px-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={1000}  onClick={chkAmount}>1,000</button>
                        </div>
                        <div className='col-4 ps-1 mb-1'>
                          <button className='btn btn-outline-success rounded w-100' value={2000}  onClick={chkAmount}>2,000</button>
                        </div>
                      </div>
                      <div className="my-2 text-center">
                        <label className="form-label fs-6">ฝากขั้นต่ำ 1 ขึ้นไปเท่านั้น</label>
                      </div>
                      <div className="my-2 text-center">
                        <button type="submit" id="btn-deposit" className="btn btn-spotlight-1 mb-5 col-lg-4 col-md-4 col-sm-12 col-12" onClick={depositMatch}><i className="bi bi-cash-coin"></i> ฝากเงิน</button>
                      </div>
                  </div>
                  <div className='col-sm-3'/>
                </div>
              </>
            :
              <></>
            )
          )
        )}
        </div>  
        <ToastContainer theme="colored" autoClose={1000} />                          
    </section>
  )
}

export default DepositComponent


import React, { useState, useEffect } from 'react'
import Slider from "react-slick";

function PromotionComponent() {
    const width = window.innerWidth
    const slidesToShow = (width >= 768 ? 2 : 1); 
    const set_2 = {
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow
      };
    const [promotion,setPromotion] = useState([
        // {_id:1,p_name:"โปรฝากแรก ฟรี 10%",p_des:"โปรฝากแรก ฟรี 10% สมัครสมาชิกใหม่ ฝากครั้งแรก รับเครดิคฟรี 10% ทันที",p_txt:''}
    ])
    
    
  return (
    <section id="promotion" className="text-light text-left">
        <div className="container">
        <Slider  {...set_2}>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite01.jpeg`} alt="s4" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite02.jpeg`} alt="s5" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite01.jpeg`} alt="s6" style={{width: '98%', margin: 'auto'}} />
                </div>
                <div>
                    <img src={`web/${process.env.REACT_APP_NAME}/invite02.jpeg`} alt="s7" style={{width: '98%', margin: 'auto'}} />
                </div>
            </Slider>
            <hr/>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {promotion.map((pro,i)=>{
                    return (
                        <div className="col" key={i}>
                            <div className="card h-100" style={{backgroundColor:'#01736b',textAlign:'left'}} >
                                <img src="images/200x200.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">{pro.p_name}</h5>
                                    <p className="card-text">{pro.p_des}</p>
                                    <button className='btn btn-sm btn-success' style={{borderRadius:'50px'}}>อ่านเพิ่มเติม</button>
                                </div>
                            </div>
                        </div>
                    )
                })}
                
            </div>  
        </div>
    </section>
  )
}

export default PromotionComponent
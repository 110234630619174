import React,{useContext} from 'react'
import  {Link} from 'react-router-dom'
import DataContext from '../Data/DataContext'
const Swal = require('sweetalert2')

function MenuComponent() {
    const dataC = useContext(DataContext)
    const logout = ()=>{
        Swal.fire({
            icon: 'question',
            title: 'ออกจากระบบ',
            text: 'คุณต้องการออกจากระบบหรือไม่ ?',
            confirmButtonText: 'ออกจากระบบ',
            confirmButtonColor: 'red',
            showCloseButton: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                localStorage.clear()
                dataC.auth(null)
            }
          })
        
    }
    return (
        <ul>
                        <li><Link to={'/'} className='list-menu'>หน้าหลัก</Link></li>
                        {dataC.token?
                        <>
                            <li><hr className="hrMenu" /><Link className="nav-link" to={"/play"}>เข้าเล่น</Link></li>
                            {dataC.ag_con_outside == 1?<></>:
                                <li><hr className="hrMenu" /><Link className="nav-link" to={"/transfer/all"}>ฝาก-ถอน</Link></li>
                            }
                            <li><hr className="hrMenu" /><Link className="nav-link" to={"/play-history"}>โพยหวยวันนี้</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link" to={"/awards"}>ผลรางวัล</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link" to={"/history"}>ประวัติการเล่น</Link></li>
                            {dataC.ag_con_outside == 1?<></>:
                                <>
                                    <li><hr className="hrMenu" /><Link className="nav-link" to={"/af"}>ชวนเพื่อน</Link></li>
                                    <li><hr className="hrMenu" /><Link className="nav-link" to={"/profile"}>โปรไฟล์</Link></li>
                                </>
                            }
                            {/* <li><hr className="hrMenu" /><button className="getstarted"  onClick={logout} style={{"--bs-btn-padding-y":" .25rem","--bs-btn-padding-x":".5rem","--bs-btn-font-size":".75rem;"}}><i className="bi bi-box-arrow-right"></i> logout</button></li> */}
                        </>:
                        <>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="/#h1">หวยรัฐบาลไทย</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h2">หวยธกส</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto " to="#h3">หวยออมสิน</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h4">หุ้นไทย</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h7">หวยมาเลเซีย</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h9">หวยลาว</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h18">หวยฮานอย</Link></li>
                            <li><hr className="hrMenu" /><Link className="nav-link scrollto" to="#h28">หวยต่างประเทศ</Link></li>
                        </>}
                        {dataC.ag_con_outside == 1
                            ?<li>
                                <button type="button" className="btn btn btn-danger ms-5"  onClick={logout}>
                                    <i className="bi bi-power text-light"></i>
                                </button>
                            </li>
                            :<li><hr className="hrMenu" /><a className="getstarted" href={process.env.REACT_APP_LINE} target={'_blank'}><i className="bi bi-line" /> &nbsp; ติดต่อเรา</a></li>
                        }
                    </ul>
    )
}

export default MenuComponent
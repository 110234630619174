import React,{ useContext,useState,useEffect } from 'react'
import { useParams,Link } from "react-router-dom";
import $ from 'jquery'
import LottoService  from "../../Services/Lotto.service"; 
import DataContext from "../../Data/DataContext";
import moment from 'moment';

function LottoLimitedNumber() {
    const dataC = useContext(DataContext)
    const lottoService = new LottoService()
    const { op_id } = useParams()

    const [lotInfo,setLotInfo] = useState([])
    const [lotHaft,setLotHaft] = useState([])
    const [lotFull,setLotFull] = useState([])

    useEffect(()=>{
        getInfoOpen()
        getHaftLotto()
        getFullLotto()
    },[])

    const getInfoOpen = ()=>{
        lottoService.getting_info_open({p_op_id:op_id}).then((result)=>{
            if (result.success){
                setLotInfo(result.data)
            }
        });
    }

    const getHaftLotto = ()=>{
        lottoService.listing_lothaft({ p_ag_key_web: dataC.m_key_web,  p_op_id: op_id }).then((result)=>{
            if (result.success){
                setLotHaft(result.data)
            }
        });
    }

    const getFullLotto = ()=>{
        
        lottoService.listing_lotfull({ p_ag_key_web: dataC.m_key_web,  p_op_id: op_id }).then((result)=>{
            if (result.success){
                setLotFull(result.data)
            }
        });
    }

    return (
        <section id="play-history" className="mt-5 pt-5">
            <div className="container">
                <div className='row'>
                    <div className='col-8 text-start text-on-background'>
                        <h3>{lotInfo.lz_name}</h3> 
                    </div>
                    <div className='col-4 text-end'>
                        <Link to={"/launch_game/"+op_id} className="btn btn-sm btn-warning mb-2">
                            <i className="bi bi-chevron-double-left"></i> ย้อนหลับ
                        </Link>
                    </div>
                </div>
                
                <div className="card mb-3 bg-warning" >
                    <div className="card-header text-dark">
                        <h5><i className="bi bi-circle-half"></i> เลขจ่ายครึ่ง</h5>
                    </div>
                    <div className="card-body bg-light">
                        <table className='table table-border text-dark'>
                            <thead>
                                <tr>
                                    <th>ประเภทหวย</th>
                                    <th>เลข</th>
                                    <th>อัตราจ่าย</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lotHaft.map((lot,i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>{lot.lt_name}</td>
                                            <td>{lot.lh_number}</td>
                                            <td>{dataC.money.format(lotInfo.pay_array?lotInfo.pay_array[lot.lot_type]*(lot.lh_pay_percent/100):0)}</td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card mb-3 bg-danger" >
                    <div className="card-header">
                        <h5><i className="bi bi-x-circle-fill"></i> เลขเต็ม</h5>
                    </div>
                    <div className="card-body bg-light">
                        <table className='table table-border text-dark'>
                            <thead>
                                <tr>
                                    <th>ประเภทหวย</th>
                                    <th>เลข</th>
                                    {/* <th>อัตราจ่าย</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {lotFull.map((lot,i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>{lot.lt_name}</td>
                                            <td>{lot.lf_number}</td>
                                            {/* <td>{dataC.money.format(lotInfo.pay_array[lot.lot_type]*(lot.lf_pay_percent/100))}</td> */}
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LottoLimitedNumber
import React , { useContext, useState }from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import DataContext from '../Data/DataContext'
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import play from "./../play";
// import './css/MenuMobile.css'
import Swal from 'sweetalert2'

function MenuMobile() {
    const dataC = useContext(DataContext)
    const logout = ()=>{
        Swal.fire({
            icon: 'question',
            title: 'ออกจากระบบ',
            text: 'คุณต้องการออกจากระบบหรือไม่ ?',
            confirmButtonText: 'ออกจากระบบ',
            confirmButtonColor: 'red',
            showCloseButton: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                localStorage.clear()
                dataC.auth(null)
            }
          })
        
    }
    const closeMenu = ()=>{
        $('.btn-close').click();
    }
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: play().default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <div className="modal fade" id="menu-mobile" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel"><i className="bi bi-menu-button-fill"></i> เมนู</h5>
                        <button type="button" className="btn btn-close border bg-light" data-bs-dismiss="modal" aria-label="Close" style={{}} />
                    </div>
                    <div className="modal-body">
                    {dataC.token?
                      <div className='row'>
                        <div className='col-6 px-2'>
                            <Link to="/" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon">
                                        {/* <i className="bi bi-house-door-fill fs-1"></i> */}
                                        <img src={`/web/${process.env.REACT_APP_NAME}/icon/home.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                    </div>
                                    <h4>หน้าหลัก</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 px-2'>
                            <Link to="/play" onClick={closeMenu}>
                                <div className="icon-box pb-1 rounded-3 play-box" style={{paddingTop:'2px'}}>
                                    <div className="icon">
                                        {/* <i className="bi bi-play-circle-fill fs-1"></i> */}
                                        <Lottie options={defaultOptions} height={85} width={85} />
                                    </div>
                                    <h4>เข้าเล่น</h4>
                                </div>
                            </Link>
                        </div>
                        {/* <div className='col-12 px-2'>
                            <div className="icon-box p-2 rounded-3 mb-2 mt-3 title-box">
                                <h5 className='mb-0'>เครดิต</h5>
                            </div>
                        </div> */}
                        {dataC.ag_con_outside == 1?<></>:
                            <div className='col-6 px-2'>
                                <Link to="/transfer/all" onClick={closeMenu}>
                                    <div className="icon-box p-2 rounded-3 mb-2">
                                        <div className="icon">
                                            {/* <i className="bi bi-cash-coin fs-1"></i> */}
                                            <img src={`/web/${process.env.REACT_APP_NAME}/icon/money.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                        </div>
                                        <h4>ฝาก-ถอน</h4>
                                    </div>
                                </Link>
                            </div>
                        }
                        {/* <div className='col-12 px-2'>
                            <div className="icon-box p-2 rounded-3 mb-2 mt-3 title-box">
                                <h5 className='mb-0'>รายการหวย</h5>
                            </div>
                        </div> */}
                        <div className='col-6 px-2'>
                            <Link to="/play-history" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon">
                                        {/* <i className="bi bi-list-stars fs-1"></i> */}
                                        <img src={`/web/${process.env.REACT_APP_NAME}/icon/history.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                    </div>
                                    <h4>โพยหวยวันนี้</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 px-2'>
                            <Link to="/awards" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon">
                                        {/* <i className="bi bi-trophy-fill fs-1"></i> */}
                                        <img src={`/web/${process.env.REACT_APP_NAME}/icon/award.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                    </div>
                                    <h4>ผลรางวัล</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 px-2'>
                            <Link to="/history" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon">
                                        {/* <i className="bi bi-card-checklist fs-1"></i> */}
                                        <img src={`/web/${process.env.REACT_APP_NAME}/icon/list.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                    </div>
                                    <h4>ประวัติการเล่น</h4>
                                </div>
                            </Link>
                        </div>
                        {/* <div className='col-12 px-2'>
                            <div className="icon-box p-2 rounded-3 mb-2 mt-3 title-box">
                                <h5 className='mb-0'>แนะนำเพื่อน</h5>
                            </div>
                        </div> */}
                        {dataC.ag_con_outside == 1?<></>:
                            <div className='col-6 px-2'>
                                <Link to="/af" onClick={closeMenu}>
                                    <div className="icon-box p-2 rounded-3 mb-2">
                                        <div className="icon">
                                            {/* <i className="bi bi-people-fill fs-1"></i> */}
                                            <img src={`/web/${process.env.REACT_APP_NAME}/icon/member.png`}  alt="" style={{width:'45px',margin:'15px'}} />
                                        </div>
                                        <h4>ชวนเพื่อน</h4>
                                    </div>
                                </Link>
                            </div>
                        }
                        {/* <div className='col-12 px-2'>
                            <div className="icon-box p-2 rounded-3 mb-2 mt-3 title-box">
                                <h5 className='mb-0'>ตั้งค่าบัญชี</h5>
                            </div>
                        </div> */}
                        {dataC.ag_con_outside == 1?<></>:
                            <div className='col-6 px-2'>
                                <Link to="/profile" onClick={closeMenu}>
                                    <div className="icon-box p-2 rounded-3 mb-2">
                                        <div className="icon">
                                            {/* <i className="bi bi-person-circle fs-1"></i> */}
                                            <img src={`/web/${process.env.REACT_APP_NAME}/icon/profile.png`} alt="" style={{width:'45px',margin:'15px'}} />
                                        </div>
                                        <h4>โปรไฟล์</h4>
                                    </div>
                                </Link>
                            </div>
                        }
                        {/* <div className='col-12 px-2 mt-3'>
                            <div className="p-2 rounded-3 bg-success" onClick={()=>{logout();closeMenu();}}>
                                <h5 className='mb-0'><i className="bi bi-line"></i> ติดต่อเรา</h5>
                            </div>
                        </div> */}
                      </div>
                      :
                      <div className='row'>
                        <div className='col-6 px-2'>
                            <a href="#h1" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/1.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยรัฐบาลไทย</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h2" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/2.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยธกส</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h3" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/3.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยออมสิน</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h4" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/4.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หุ้นไทย</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h8" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/8.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยมาเลเซีย</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h9" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/9.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยลาว</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h24" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/24.png`}  width={32} />
                                    </div>
                                    <h4 className='m-0'>หวยฮานอย</h4>
                                </div>
                            </a>
                        </div>
                        <div className='col-6 px-2'>
                            <a href="#h28" onClick={closeMenu}>
                                <div className="icon-box p-2 rounded-3 mb-2">
                                    <div className="icon mb-1">
                                        <img alt="true"  className="mr-2 mt-1" src={`/images/icon/28.png`}  width={32} />
                                    </div>
                                    <h5 className='m-0'>หวยต่างประเทศ</h5>
                                </div>
                            </a>
                        </div>
                      </div>
                    }

                    </div>
                    <div className="modal-footer d-block">
                        <div className='row'>
                            {dataC.ag_con_outside == 1?<></>:
                                <div className='col col-12 pb-2'>
                                    <a type="submit" href={process.env.REACT_APP_LINE} target={'_blank'} className="btn bg-contact w-100"><i className="bi bi-line"></i> ติดต่อเรา</a>
                                </div>
                            }
                            {dataC.token?
                                <div className='col col-12 pb-2'>
                                    <button className="btn btn-danger  w-100"  onClick={logout}><i className="bi bi-power text-light"></i> ออกจากระบบ </button>
                                </div>
                            :<></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default MenuMobile
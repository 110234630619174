import AbstractService from "./Abstract.service";

export default class HistoryService extends AbstractService
{
    get_sun_today(): Promise<any>
    {
        return this.getAuth('/order/sum-today');
    }

    listting_bill(payload:Object): Promise<any>
    {
        return this.postAuth('/order/play',payload);
    }

    listting_bill_detail(payload:Object): Promise<any>
    {
        return this.postAuth('/order/play-list',payload);
    }

    listting_history(payload:Object): Promise<any>
    {
        return this.postAuth('/order/history',payload);
    }

    listting_history_detail(payload:Object): Promise<any>
    {
        return this.postAuth('/order/history-list',payload);
    }

    get_by_id(id:Int): Promise<any>
    {
        return this.get(`/order/id/`+id);
    }
}
import React, { useEffect, useState, useContext } from "react";
import * as maintenance from "./../../maintenance.json";
import DataContext from "./../../Data/DataContext";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";

function Maintenance() {

    const dataC = useContext(DataContext)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: maintenance.default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
        <div id="maintenance" style={{
            textAlign:'center',
            paddingTop:'10%',
            minHeight:'100vh'
            }} className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12 m-auto p-auto" >
                        {/* <img src="/ma.png" className="float-start w-100"/> */}
                        <Lottie options={defaultOptions} style={{width:'100%',padding:'-500px'}} />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <p className="title"> ขออภัย ! เว็ปไซต์อยู่ระหว่างปิดปรับปรุง </p>
                        <p className="message">{dataC.message_ma}</p>
                    </div>
                </div>
            
            
        </div>
    )
}

export default Maintenance
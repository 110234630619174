import React, { useContext } from "react";
import DataContext from '../Data/DataContext';
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as loadingData from "../totop.json";

function FooterComponent() {
  const dataC = useContext(DataContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
    <div className="container">
        <div className="text-center mt-0">
        
        <div className="sc-BeQoi jkXzlW text-on-background" style={{ fontSize: "0.9rem" }}>
          <p className="mb-1">
           
          {dataC.AppName} เว็บหวยออนไลน์ ที่ได้รับความนิยมสูงสุด ให้บริการแทงหวย ทุกประเภทอย่างครบวงจร ทั้งตรวจผลหวย เลขเด็ด เลขดัง ทุกเลข แทงหวยออนไลน์ ระบบที่ดีที่สุดในไทย เร็วที่สุดแทงง่ายไม่ซับซ้อน เสถียรที่สุดออกแบบมาให้ผู้ซื้อใช้งานได้ง่ายที่สุดลดการแทงผิดพลาดมากที่สุด
          </p>
          <p className="mb-1">
           
          จ่ายสูงสุดในไทย เล่นง่ายๆ ได้เงินแสนจริงๆ  พัฒนาออกแบบมาเพื่อการใช้งานให้สะดวกที่สุดสำหรับผู้ซื้อ ซื้อง่าย จ่ายจริง รองรับทุกอุปกรณ์ของท่าน ไม่ว่าท่านจะใช้ iOs หรือ Android ก็สามารถใช้งานได้อย่างสะดวกสะบาย</p>
          <br />
          
        </div>
      </div>
    </div>
    <div className="py-4" id="footer">
      <small>Copyright © 2022 แทงหวยออนไลน์  by {dataC.AppName} All Rights Reserved.</small>
    </div>
    <a href="#top" className="back-to-top d-flex align-items-center justify-content-center active"><Lottie options={defaultOptions} height={50} width={50} /></a>
    {/* <i className="bi bi-arrow-up-short"></i> */}
    </>
  );
}

export default FooterComponent;

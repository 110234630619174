import react, { Component } from "react";
import axios from "axios";
import  {Routes,Route,Navigate} from 'react-router-dom'
import $ from 'jquery'
import "./App.css";
import "./banks.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import config from "./Data/config";
import HeaderComponent from "./Component/HeaderComponent";
import LoginComponent from "./Component/LoginComponent";
import AwardComponent from "./Component/AwardComponent";
import FooterComponent from "./Component/FooterComponent";
import WalletComponent from "./Component/WalletComponent";
import ShortcutMenuComponent from "./Component/ShortcutMenuComponent";
import GameListComponent from "./Component/Play/GameListComponent";
import LottoPanelComponent from "./Component/Play/LottoPanelComponent";
import DepositComponent from "./Component/Transaction/DepositComponent";
import WithdrawComponent from "./Component/Transaction/WithdrawComponent";
import TransferComponent from "./Component/Transaction/TransferComponent";
import DataContext from "./Data/DataContext";
import PlayHistory from "./Component/Play/PlayHistory";
import PromotionComponent from "./Component/Promotion/PromotionComponent";
import AfComponent from "./Component/AF/AfComponent";
import RegisterComponent from "./Component/RegisterComponent";
import History from "./Component/Play/History";
import Loading from "./Loading";
import ProfileMember from "./Component/Profile/ProfileMember";
import LottoLimitedNumber from "./Component/Play/LottoLimitedNumber";
import Maintenance from "./Component/Pages/Maintenance";
import AdsPopup from "./Component/AdsPopup";
import AutoSignIn from "./Component/AutoSignIn";
import RecordHistory from "./Component/Play/RecordHistory";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('token') ,
      ag_agent_array: localStorage.getItem('ag_agent_array'),
      ag_id: localStorage.getItem('ag_id'),
      ag_con_outside: localStorage.getItem('ag_con_outside'),
      m_af: localStorage.getItem('m_af'),
      m_date_register: localStorage.getItem('m_date_register'),
      m_group: localStorage.getItem('m_group'),
      m_key_web: localStorage.getItem('m_key_web'),
      m_money: localStorage.getItem('m_money'),
      m_money_af:localStorage.getItem('m_money_af'),
      m_money_af_total:localStorage.getItem('m_money_af_total'),
      m_name: localStorage.getItem('m_name'),
      m_phone: localStorage.getItem('m_phone'),
      m_status: localStorage.getItem('m_status'),
      m_uid: localStorage.getItem('m_uid'),
      m_user: localStorage.getItem('m_user'),
      mb_acc_name: localStorage.getItem('mb_acc_name'),
      mb_acc_number: localStorage.getItem('mb_acc_number'),
      mb_bank: localStorage.getItem('mb_bank'),
      ref:localStorage.getItem('ref'),
      ag:localStorage.getItem('ag'),
      lotZone:{},
      maintenance:false,
      message_ma:''
    };
  }

  render() {
    const authen = (token,ag_agent_array,ag_id,m_af,m_date_register,m_group,m_key_web,m_money,m_money_af,m_money_af_total,m_name,m_phone,m_status,m_uid,m_user,mb_acc_name,mb_acc_number,mb_bank,ag_con_outside)=>{
      this.setState({ 
        ...this.state,
        token: token,
        ag_con_outside: ag_con_outside,
        ag_agent_array: ag_agent_array,
        ag_id: ag_id,
        m_af: m_af,
        m_date_register: m_date_register,
        m_group: m_group,
        m_key_web: m_key_web,
        m_money: m_money,
        m_money_af: m_money_af,
        m_money_af_total: m_money_af_total,
        m_name: m_name,
        m_phone: m_phone,
        m_status: m_status,
        m_uid: m_uid,
        m_user: m_user,
        mb_acc_name: mb_acc_name ,
        mb_acc_number: mb_acc_number ,
        mb_bank: mb_bank,
        loading:false
      })
    }

    const moneyUpdate = (money)=>{
      this.setState({ 
        ...this.state,m_money:money,
      })
    }

    const afAction = (ref,ag)=>{
      this.setState({ 
        ...this.state,
        ref:ref ,
        ag:ag ,
      })
    }

    const selectZone = (zone)=>{
      this.setState({ 
        ...this.state,
        lotZone:zone 
      })
    }

    const setCredit = (money,af,af_total,af_link="")=>{
      this.setState({ 
        ...this.state,
        m_money:money,
        m_money_af:af,
        m_money_af_total:af_total
      })
    }

    const setAffLink = (af_link)=>{
      this.setState({ 
        ...this.state,
        m_af:af_link,
      })
    }

    const setMaintenance = (ma,message)=>{
      setTimeout(()=>{
        this.setState({ 
          ...this.state,
          maintenance:ma,
          message_ma:message
        })
      },500)
    }

    const blockUI = (status)=>{
      this.setState({ 
        ...this.state,
        loading:status,
      })
    }

    return (
      <DataContext.Provider value={{...this.state,auth:authen,selectZone:selectZone,moneyUpdate:moneyUpdate,setCredit:setCredit,setAffLink:setAffLink,blockUI:blockUI,setMaintenance:setMaintenance,...config}}>
        <div className="App">
        {this.state.maintenance?
          <Maintenance/>
        :
          <div className="content">
            {/* <Routes>
              <Route path="/history/:bill_id"  element={<RecordHistory />} exact/>
            </Routes> */}
              <Routes>
              <Route path="/history/:bill_id"  element={<RecordHistory />} exact/>
              <Route path="/*"  element={<>

                <HeaderComponent />
            <div style={{minHeight:'75vh'}}>
            <Routes>
                {/* <Route path="/history/:bill_id"  element={<RecordHistory />} /> */}
                <Route path={"/register/:m_id/:ag_id"}  element={this.state.token?<Navigate to="/"/>:
                    <>
                      <RegisterComponent afAction={afAction}/>
                      <hr/>
                    </>
                  }/>
                <Route path="/" exact element={!this.state.token?
                  <>
                    <LoginComponent auth={authen}/>
                    <AwardComponent />
                    <AdsPopup/>
                    <hr/>
                  </>:
                  <>
                    <WalletComponent />
                    <ShortcutMenuComponent/>
                    <PromotionComponent/>
                    <AdsPopup/>
                    <hr/>
                  </>
                } />
                <Route path="/mauto/auth/:pCode"  element={<AutoSignIn auth={authen}/>}/>
                <Route path="/deposit"  element={!this.state.token|| this.state.ag_con_outside==1 ?<Navigate to="/"/>:
                  <>
                    <DepositComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/withdraw"  element={!this.state.token || this.state.ag_con_outside==1?<Navigate to="/"/>:
                  <>
                    <WithdrawComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/transfer/:tType"  element={!this.state.token || this.state.ag_con_outside==1?<Navigate to="/"/>:
                  <>
                    <WalletComponent/>
                    <TransferComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/play"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <GameListComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/play/:yiki"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <GameListComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/play-history"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <PlayHistory />
                    <hr/>
                  </>
                }/>
                <Route path="/history"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <History />
                    <hr/>
                  </>
                }/>
                <Route path="/launch_game/:op_id"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <LottoPanelComponent launch="game"/>
                    <hr/>
                  </>
                }/>
                <Route path="/lotto_limited/:op_id"  element={!this.state.token?<Navigate to="/"/>:
                  <>
                    <LottoLimitedNumber />
                    <hr/>
                  </>
                }/>
                <Route path="/af"  element={!this.state.token || this.state.ag_con_outside==1?<Navigate to="/"/>:
                  <>
                    <AfComponent/>
                    <hr/>
                  </>
                }/>
                <Route path="/awards"  element={!this.state.token?<Navigate to="/"/>:
                  <div className="mt-5 pt-4">
                    <AwardComponent/>
                    <hr/>
                  </div>
                }/>
                <Route path="/profile"  element={!this.state.token || this.state.ag_con_outside==1?<Navigate to="/"/>:
                  <div className="">
                    <ProfileMember />
                    <hr/>
                  </div>
                }/>
                <Route path="/*"  element={
                  <>
                  <section id="404" className="mt-5 pt-5">
                    <h2>ไม่พบหน้าที่คุณต้องการ !!</h2>
                  </section>
                    <hr/>
                  </>
                }/>
                </Routes>
            </div>


              </>} />
              
            
              </Routes>
            {this.state.loading?<Loading />:''}
          </div>
        }
            <FooterComponent />
        </div>
      </DataContext.Provider>
    );
  }

  componentDidMount() {
     
    /** Check Maintenance **/
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.state.token}`
    }
    axios
      .create({
        headers: headers
    }).get(`${config.ApiURL}/config`).then((response) => {
        setTimeout(()=>{
          this.setState({ 
            ...this.state,
            maintenance:response.data.data.config_open==0?true:false,
            message_ma:response.data.data.config_message_ma
          })
        },100)
    });

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
          return document.querySelector(el);
        
      }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select("#navbar .scrollto", true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };
    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);

  //   /**
  //    * Scrolls to an element with header offset
  //    */
  //   const scrollto = (el) => {
  //     let header = select("#header");
  //     let offset = header.offsetHeight;

  //     if (!header.classList.contains("header-scrolled")) {
  //       offset -= 16;
  //     }

  //     let elementPos = select(el).offsetTop;
  //     window.scrollTo({
  //       top: elementPos - offset,
  //       behavior: "smooth",
  //     });
  //   };

  //   /**
  //    * Toggle .header-scrolled class to #header when page is scrolled
  //    */
  //   let selectHeader = select("#header");
  //   if (selectHeader) {
  //     const headerScrolled = () => {
  //       if (window.scrollY > 100) {
  //         selectHeader.classList.add("header-scrolled");
  //       } else {
  //         selectHeader.classList.remove("header-scrolled");
  //       }
  //     };
  //     window.addEventListener("load", headerScrolled);
  //     onscroll(document, headerScrolled);
  //   }

    /**
     * Back to top button
     */
    let backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

  //   /**
  //    * Mobile nav toggle
  //    */
  //   on("click", ".mobile-nav-toggle", function (e) {
  //     if (this.first) {
  //       this.first = false;
  //       return;
  //     }
  //     this.first = true;
  //     select("#navbar").classList.toggle("navbar-mobile");
  //     $(".hrMenu").toggleClass("mobile-li");
  //     this.classList.toggle("bi-list");
  //     this.classList.toggle("bi-x");
  //   });

  //   on("click", ".list-menu", function (e) {
  //     if (this.first) {
  //       this.first = false;
  //       return;
  //     }
  //     this.first = true;
  //     $("#navbar").removeClass("navbar-mobile");
  //     $(".hrMenu").removeClass("mobile-li");
  //     $(".mobile-nav-toggle").removeClass("bi-list");
  //     $(".mobile-nav-toggle").removeClass("bi-x");
  //     $(".mobile-nav-toggle").addClass("bi-list");
  //   });

  //   /**
  //    * Mobile nav dropdowns activate
  //    */
  //   on(
  //     "click",
  //     ".navbar .dropdown > a",
  //     function (e) {
  //       if (select("#navbar").classList.contains("navbar-mobile")) {
  //         e.preventDefault();
  //         if (this.first) {
  //           this.first = false;
  //           return;
  //         }
  //         this.first = true;
  //         this.nextElementSibling.classList.toggle("dropdown-active");
  //       }
  //     },
  //     true
  //   );

  //   /**
  //    * Scrool with ofset on links with a class name .scrollto
  //    */
  //   on(
  //     "click",
  //     ".scrollto",
  //     function (e) {
  //       if (select(this.hash)) {
  //         e.preventDefault();

  //         let navbar = select("#navbar");
  //         if (navbar.classList.contains("navbar-mobile")) {
  //           navbar.classList.remove("navbar-mobile");
  //           let navbarToggle = select(".mobile-nav-toggle");
  //           navbarToggle.classList.toggle("bi-list");
  //           navbarToggle.classList.toggle("bi-x");
  //         }
  //         scrollto(this.hash);
  //       }
  //     },
  //     true
  //   );

  //   /**
  //    * Scroll with ofset on page load with hash links in the url
  //    */
  //   window.addEventListener("load", () => {
  //     if (window.location.hash) {
  //       if (select(window.location.hash)) {
  //         scrollto(window.location.hash);
  //       }
  //     }
  //   });
  }
}

export default App;

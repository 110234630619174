import React,{useContext} from 'react';
import axios from 'axios';
import {empty} from "../Common/Util/functions";
import dataConf from "../Data/config";
import DataContext from "../Data/DataContext";

export default class AbstractService
{   
    DATAC = useContext(DataContext)
    ROOT_API = dataConf.ApiURL;
    TOKEN = this.DATAC.token;
    
    get(path: string): Promise<any>
    {
        const self = this;
        return new Promise((resolve) => {
            axios
                .create({
                    headers: {'Content-Type': 'application/json'}
                })
                .get(self.ROOT_API + path)
                .then(resp => {
                    resp.data.success = true;
                    resolve(resp.data);
                })
                .catch(function (error) {
                    //https://www.pluralsight.com/guides/all-need-to-know-about-axios
                    const result = AbstractService.errorMessage(error);
                    resolve(result);
                });
        });
    }

    getAuth(path: string): Promise<any>
    {
        const self = this;
        return new Promise((resolve) => {
            axios
                .create({
                    headers: {'Content-Type': 'application/json','Authorization':`Bearer ${self.TOKEN}`}
                })
                .get(self.ROOT_API + path)
                .then(resp => {
                    resp.data.success = true;
                    resolve(resp.data);
                })
                .catch(function (error) {
                    //https://www.pluralsight.com/guides/all-need-to-know-about-axios
                    const result = AbstractService.errorMessage(error);
                    resolve(result);
                });
        });
    }

    post(path: string, params?: any, files?: any): Promise<any>
    {
        const self = this;

        if(!empty(files)){
            let formData: FormData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(files[i].param_name, files[i].file, files[i].file.name);
            }
            if(!empty(params)) {
                formData.append('data', JSON.stringify(params));
            }

            params = formData;
        }

        return new Promise((resolve) => {
            axios
                .create({
                    headers: {'Content-Type': 'application/json'}
                })
                .post(self.ROOT_API + path, params)
                .then(resp => {
                    resp.data.success = true;
                    resolve(resp.data);
                })
                .catch(function (error) {
                    const result = AbstractService.errorMessage(error);
                    resolve(result);
                });
        });
    }

    postAuth(path: string, params?: any, files?: any): Promise<any>
    {
        const self = this;

        if(!empty(files)){
            let formData: FormData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(files[i].param_name, files[i].file, files[i].file.name);
            }
            if(!empty(params)) {
                formData.append('data', JSON.stringify(params));
            }

            params = formData;
        }

        return new Promise((resolve) => {
            axios
                .create({
                    headers: {'Content-Type': 'application/json','Authorization':`Bearer ${self.TOKEN}`}
                })
                .post(self.ROOT_API + path, params)
                .then(resp => {
                    resp.data.success = true;
                    resolve(resp.data);
                })
                .catch(function (error) {
                    const result = AbstractService.errorMessage(error);
                    resolve(result);
                });
        });
    }

    static errorMessage(error: any): any
    {
        let text = '';
        let status = null;
        if(error){
            status = error.response.status;
            text = error.response.statusText;
            //Service can return error message in json format
            if(status >= 300 || status < 400){
                text = error.response.data.message; //'Server return redirect status code: '
            }
            else if(status >= 400 || status < 500){
                if(status === 400){
                    text = 'Requested service with parameters, can not be processed.';
                }
                else if(status === 401){
                    text = 'User not passed authentication.';
                }
                else if(status === 403){
                    text = 'No permission to access requested data.';
                }
                else if(status === 404){
                    text = 'Requested service not existing : ' + error.response.config.url;
                }
            }
            else if(status >= 500){
                if(status === 500){
                    text = 'ERROR CODE:  ' + status + '. STATUS TEXT: ' + error.response.status;
                }
                else if(status === 501){
                    text = 'Requested service is not implemented.';
                }
                else if(status === 502){
                    text = 'Bad Gateway.';
                }
                else if(status === 503){
                    text = 'Service Unavailable.';
                }
                else if(status === 504){
                    text = 'Gateway Timeout.';
                }
                else if(status === 505){
                    text = 'HTTP Version Not Supported.';
                }
            }

            if(text === '') {
                text = 'ERROR CODE: ' + status + '.  STATUS TEXT: ' + error.statusText;
            }
        }

        return {
            success: false,
            message: text,
            status: status
        };
    }

}
import React from 'react'
import * as playpink from "./BtnPlay/pink.json";
import * as playOrange from "./BtnPlay/orange.json";
import * as playWhite from "./BtnPlay/white.json";
import * as playRed from "./BtnPlay/red.json";

function play() {
  let btnPlay;
  if (process.env.REACT_APP_NAME == 'RICH365'){
    btnPlay = playpink
  }if (process.env.REACT_APP_NAME == 'MELEK289'){
    btnPlay = playWhite
  }else {
    btnPlay = playRed
  }
  return btnPlay
}

export default play 

import React, { useEffect, useState } from "react";
import * as loadingData from "./loading.json";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";


function Loading() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingData.default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <div style={{
            backgroundColor:"#3D00007c",
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex:1060,
            paddingTop:'40vh'
            }}>
            {/* <FadeIn  transitionDuration="100"> */}
                <Lottie options={defaultOptions} height={140} width={140} />
            {/* </FadeIn> */}
        </div>
    )
}

export default Loading
export function empty(value)
{
    if(typeof value === 'undefined') {
        return true;
    }
    else if(Number.isNaN(value)) {
        return true;
    }
    else if(value === null || value===false || value===0 || value==='0' || value==='') {
        return true;
    }
    else if(typeof value ==='object' && value instanceof Array ) {
        return value.length < 1;
    }
    else{
        return false;
    }
}

export function intVal(strVal)
{
    if(strVal && strVal.length > 0) {
        return parseInt(strVal, 10);
    }
    else{
        return strVal;
    }
}

export function decimal(number)
{
    if(number && number.length > 0) {
        return Number(number).toFixed(1);
    }
    else{
        return number;
    }
}
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import captcha0 from "../images/captcha/0.png"
import captcha1 from '../images/captcha/1.png'
import captcha2 from '../images/captcha/2.png'
import captcha3 from '../images/captcha/3.png'
import captcha4 from '../images/captcha/4.png'
import captcha5 from '../images/captcha/5.png'
import captcha6 from '../images/captcha/6.png'
import captcha7 from '../images/captcha/7.png'
import captcha8 from '../images/captcha/8.png'
import captcha9 from '../images/captcha/9.png'
import bg from '../images/captcha/bg.png'
import Authentication from '../Services/Authentication.service';
import axios from 'axios';
// import './css/register.css';
import { useParams } from "react-router-dom";
import DataContext from '../Data/DataContext';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function RegisterComponent(props) {
    const dataC = useContext(DataContext);
    const authentication = new Authentication();
    const captchaImg = [captcha0,captcha1,captcha2,captcha3,captcha4,captcha5, captcha6, captcha7,captcha8,captcha9]

    var myCanvas = useRef();
    const [captcha,setCaptcha] = useState('8888')
    const captchaRef = useRef(null)

    const [step,setStep] = useState(1)

    const { m_id , ag_id  } = useParams();
    useEffect(()=>{
        // console.log(dataC.ref)
        // console.log(dataC.ag)
        // numberCaptcha()
        if((m_id != '0'  || ag_id != '0') || (dataC.ref === null || dataC.ag === null ) ){
            props.afAction(m_id,ag_id);
            localStorage.setItem("ref", m_id)
            localStorage.setItem("ag", ag_id)
        }

        
    },[])

    useEffect(()=>{
        numberCaptcha()
    },[step])
    // console.log(ag_id,m_id)

    // useEffect(()=>{
    //     if(step == 2) {
    //         setTimeout(() => {
    //             numberCaptcha()
    //         }, 1000);
    //     }
    // },[step])

    const [memberRegis, setMemberRegis] = useState({})
    const [chkPhone_f,setChkPhone] = useState(false)
    const [chkBank_f,setChkBank] = useState(false)
    const [chkPass_f,setChkPass] = useState(false)

    const [regisStatus, setRegisStatus] = useState(false)
    const [member, setMember] = useState({p_m_user:'',p_m_pass:''})

    const [reCapcha, setReCapcha] = useState(false)

    const chkPhone = (e)=>{
        e.preventDefault()
        // check dup phone number 
        const payload = {p_ag_id:parseInt(dataC.ag),p_m_af:parseInt(dataC.ref),p_m_phone:e.target.m_phone.value,p_mb_bank:parseInt(e.target.m_bank.value),p_mb_acc_number:e.target.m_bank_ac.value,p_mb_acc_name:e.target.m_name.value}
        authentication.check_phone(payload).then((result) => {
            if (result.success){
                if(result.status === 200){
                    setMemberRegis({...payload})   
                    e.target.reset()
                    setChkPhone(true)
                    setStep(2)
                    // numberCaptcha()
                }else{
                    // Swal.fire({
                    //     text: result.message,
                    //     icon: 'warning',
                    //     confirmButtonText: 'ตกลง',
                    //     confirmButtonColor: '#890000',
                    // })
                    toast.error(result.message,{position: "top-center"});
                    setStep(1)
                    return
                } 
                
            }else{
                // Swal.fire({
                //     text: result.message,
                //     icon: 'warning',
                //     confirmButtonText: 'ตกลง',
                //     confirmButtonColor: '#890000',
                // })
                toast.error(result.message,{position: "top-center"});
                setStep(1)
                return
            }
        });
        
    }

    const registerSubmit = async (e)=>{
        e.preventDefault()
        const password = e.target.m_pass.value
        const password_comfrim = e.target.m_pass_comfrim.value
        const m_captcha = e.target.m_captcha.value
        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();
        // console.log(token)

        if(m_captcha !== captcha){
            // Swal.fire({
            //     text: 'รหัสตรวจสอบไม่ถูกต้อง',
            //     icon: 'warning',
            //     confirmButtonText: 'ตกลง',
            //     confirmButtonColor: 'green'
            //   })
            toast.error('รหัสตรวจสอบไม่ถูกต้อง',{position: "top-center"});
              numberCaptcha()
            return
        }

        // console.log(password,password_comfrim)
        if(password !== password_comfrim){
            // Swal.fire({
            //     text: 'รหัสผ่านไม่ตรงกัน',
            //     icon: 'warning',
            //     confirmButtonText: 'ตกลง',
            //     confirmButtonColor: 'green'
            //   })
            toast.error('รหัสผ่านไม่ตรงกัน',{position: "top-center"});
              numberCaptcha()
              return
        }
        const payload = {...memberRegis,p_m_pass:password}
        // console.log(payload)
        // return
        authentication.register(payload).then((result) => {
            e.target.reset()
            if (result.success){
                // console.log(result)
                if(result.status === 200){
                    setMemberRegis({...memberRegis,p_m_pass:password})
                    setChkPass(true)
                    setRegisStatus(true)
                    setStep(3)
                    setMember({p_m_user:result.username,p_m_pass:result.password})
                }else{
                    // Swal.fire({
                    //     text: result.message,
                    //     icon: 'warning',
                    //     confirmButtonText: 'ตกลง',
                    //     confirmButtonColor: '#890000',
                    // })
                    toast.error(result.message,{position: "top-center"});
                    setStep(1)
                    setReCapcha(false)
                }
            }
            else{
            //   Swal.fire({
            //     text: result.message,
            //     icon: 'warning',
            //     confirmButtonText: 'ตกลง',
            //     confirmButtonColor: '#890000',
            //     })
            toast.error(result.message,{position: "top-center"});
                setStep(1)
                setChkPhone(false)
                setReCapcha(false)
            }
        });
    }

    const loginAccess = async ()=>{
        const response = await axios.post(`${dataC.ApiURL}/member/auth`,member,
            {
                header:{'Content-Type' : 'application/json'}
            }
        ).then(response => {
            // console.log(response)
            const mem = response.data.data
            localStorage.setItem('token',response.data.access_token,60000)
            localStorage.setItem('ag_agent_array',mem.ag_agent_array,60000)
            localStorage.setItem('ag_id',mem.ag_id,60000)
            localStorage.setItem('m_af',mem.m_af,60000)
            localStorage.setItem('m_date_register',mem.m_date_register,60000)
            localStorage.setItem('m_group',mem.m_group,60000)
            localStorage.setItem('m_key_web',mem.m_key_web,60000)
            localStorage.setItem('m_money',mem.m_money,60000)
            localStorage.setItem('m_money_af',mem.m_money_af,60000)
            localStorage.setItem('m_money_af_total',mem.m_money_af_total,60000)
            localStorage.setItem('m_name',mem.m_name,60000)
            localStorage.setItem('m_phone',mem.m_phone,60000)
            localStorage.setItem('m_status',mem.m_status,60000)
            localStorage.setItem('m_uid',mem.m_uid,60000)
            localStorage.setItem('m_user',mem.m_user,60000)
            localStorage.setItem('mb_acc_name',mem.mb_acc_name,60000)
            localStorage.setItem('mb_acc_number',mem.mb_acc_number,60000)
            localStorage.setItem('mb_bank',mem.mb_bank,60000)
            dataC.auth(response.data.access_token,mem.ag_agent_array,mem.ag_id,mem.m_af,mem.m_date_register,mem.m_group,mem.m_key_web,mem.m_money,mem.m_money_af,mem.m_money_af_total,mem.m_name,mem.m_phone,mem.m_status,mem.m_uid,mem.m_user,mem.mb_acc_name,mem.mb_acc_number,mem.mb_bank)
        })
    }

    useEffect(()=>{
        // console.log(memberRegis)
    },[memberRegis])

    const onChange = (e)=>{
        setReCapcha(!reCapcha)
    }

    // function onSubmit(token) {
    //     document.getElementById("demo-form").submit();
    // }

    const numberCaptcha = ()=>{
        
        let c = `${randomInt(0, 9)}${randomInt(0, 9)}${randomInt(0, 9)}${randomInt(0, 9)}`
        // const context = captchaRef.current.getContext("2d");
        
        // const image = new Image();
        // const image1 = new Image();
        // const image2 = new Image();
        // const image3 = new Image();
        // const image4 = new Image();
        // image.src =  "/images/captcha/bg.png";
        // image1.src =  `/images/captcha/${c.split('')[0]}.png`;
        // image2.src =  `/images/captcha/${c.split('')[1]}.png`;
        // image3.src =  `/images/captcha/${c.split('')[2]}.png`;
        // image4.src =  `/images/captcha/${c.split('')[3]}.png`;
        // image.onload = () => {
        //     context.drawImage(image, 0, 0, 150, 50);
        //     context.drawImage(image1, 0, 0, 50, 50);
        //     context.drawImage(image2, 30, 0, 50, 50);
        //     context.drawImage(image3, 60, 0, 50, 50);
        //     context.drawImage(image4, 90, 0, 50, 50);
        // };
        setCaptcha(c)
        // console.log(c)
    }

    function randomInt(min, max) {
        // 👇️ get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

  return (
    <section id="register" className='mt-5 pt-5' style={{minHeight:'500px'}}>
        <div className='container text-on-background'>
            <div className='img-cover'>
               <img src={`/web/${process.env.REACT_APP_NAME}/banner-regis.png`} alt="s1"  className='logo-sexy' /> 
            </div>
            {/* {{
                1 : <> */}
                        <form onSubmit={chkPhone} style={{display:step==1?'block':'none'}}>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>เบอร์โทรศัพท์</label>
                                <input type="tel" className="form-control" id="m_phone" placeholder="กรอกเบอร์โทรศัพท์" pattern='[0-9]{10}' required/>
                            </div>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>ธนาคาร</label>
                                <select className="form-control" id="m_bank" required>
                                    <option value="">เลือกธนาคาร</option>
                                    {dataC.arrBank.map((b,i)=>{
                                        return (
                                        <option value={b.id} key={i}>{b.b_name}</option> 
                                        )
                                    })}
                                    
                                </select>
                            </div>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>เลขบัญชีธนาคาร</label>
                                <input type="tel" className="form-control" id="m_bank_ac" placeholder="กรอกเลขบัญชีธนาคาร" pattern="^([0-9]{10}|[0-9]{12}|[0-9]{13})$" required/>
                            </div>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>ชื่อ-นามสกุล</label>
                                <input type="text" className="form-control" id="m_name" placeholder="กรอกชื่อ-นามสกุล" required/>
                            </div>
                            <button type='submit' className="btn button-01 mt-3 mb-5" >ถัดไป <i className="bi bi-arrow-right-circle-fill"></i></button>
                        </form>
                    {/* </>,
                2 : <> */}
                       <form onSubmit={registerSubmit} style={{display:step==2?'block':'none'}}>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>รหัสผ่าน</label>
                                <input type="password" className="form-control" id="m_pass" placeholder="กรอกรหัสผ่าน" required/>
                            </div>
                            <div className="mb-3 text-left form-d">
                                <label className='mx-3'>ยืนยันรหัสผ่าน</label>
                                <input type="password" className="form-control" id="m_pass_comfrim" placeholder="กรอกรหัสผ่าน" defaultValue={''} required/>
                            </div>
                            <div className="mb-3 captcha-row text-center form-d">
                                    <div className='row m-auto p-auto'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-5 text-end pt-3'>
                                                    <span className=' px-2 rounded-pill bg-light text-dark' onClick={()=>numberCaptcha()} style={{cursor:'pointer'}}>
                                                        <i className='bi bi-arrow-clockwise'></i> รูปอื่น
                                                    </span>
                                                </div>
                                                <div className='col-7 m-auto p-auto disabled'>
                                                    <div className='p-2' style={{ borderRadius:'15px',border:'solid 1px #FFF', width:'150px',backgroundImage:`url(${bg})`}}>
                                                        {captcha.split('').map((c,i)=>{
                                                            return <img src={captchaImg[c]} alt="" style={{width:'25%'}} key={i}/>
                                                        })}
                                                    </div>
                                                    <canvas className='mt-1' ref={captchaRef} width={150} height={50} style={{ borderRadius:'15px',border:'solid 1px #FFF',display:'none'}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <label className='mx-3'>รหัสตรวจสอบ</label>
                                            <input type="text" className="form-control" id="m_captcha" placeholder="กรอกรหัสตามภาพ" defaultValue={''} required/>
                                        </div>
                                    </div>
                            </div>
                            <button type='submit' className={`btn button-01 mt-3 mb-5`} >สมัครสมาชิก</button> 
                        </form> 
                    {/* </>
            // }[step]} */}
            
            

            {/* {!chkPhone_f?
                <form onSubmit={chkPhone}>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>เบอร์โทรศัพท์</label>
                        <input type="tel" className="form-control" id="m_phone" placeholder="กรอกเบอร์โทรศัพท์" pattern='[0-9]{10}' required/>
                    </div>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>ธนาคาร</label>
                        <select className="form-control" id="m_bank" required>
                            <option value="">เลือกธนาคาร</option>
                            {dataC.arrBank.map((b,i)=>{
                                return (
                                   <option value={b.id} key={i}>{b.b_name}</option> 
                                )
                            })}
                            
                        </select>
                    </div>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>เลขบัญชีธนาคาร</label>
                        <input type="tel" className="form-control" id="m_bank_ac" placeholder="กรอกเลขบัญชีธนาคาร" pattern="^([0-9]{10}|[0-9]{12}|[0-9]{13})$" required/>
                    </div>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>ชื่อ-นามสกุล</label>
                        <input type="text" className="form-control" id="m_name" placeholder="กรอกชื่อ-นามสกุล" required/>
                    </div>
                    <button type='submit' className="btn-bright mt-3 mb-5" >ถัดไป <i className="bi bi-arrow-right-circle-fill"></i></button>
                </form>
            :(!chkPass_f?
                <form onSubmit={registerSubmit}>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>รหัสผ่าน</label>
                        <input type="password" className="form-control" id="m_pass" placeholder="กรอกรหัสผ่าน" required/>
                    </div>
                    <div className="mb-3 text-left form-d">
                        <label className='mx-3'>ยืนยันรหัสผ่าน</label>
                        <input type="password" className="form-control" id="m_pass_comfrim" placeholder="กรอกรหัสผ่าน" defaultValue={''} required/>
                    </div>
                    <div className="captcha-row text-center">
                        <div style={{width:'340px'}} className="m-auto p-auto">
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={"6LdmYdggAAAAAJCNW7DgEK0WI-R3CxHPgvXkNSNf"}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <button type='submit' className={`btn-bright mt-3 mb-5 ${!reCapcha?'btn-disable':''} `} disabled={!reCapcha}>สมัครสมาชิก</button> 
                    
                </form>
            :
                <></>
            )
            } */}
            {regisStatus?
                <div className="card my-3 m-auto p-auto bg-dark-skin">
                    <div className="card-body">
                        <h6 className="full-name mb-3">
                            <i className="bi bi-award text-warning"></i> สมัครสมาชิกสำเร็จ 
                        </h6>
                        <h5><i className="bi bi-person-fill"></i> : {member.p_m_user}</h5>
                        <h5><i className="bi bi-lock-fill"></i> : {member.p_m_pass}</h5>
                        <button className={`btn button-01 mt-3 mb-3 `} onClick={loginAccess}>เข้าสู่ระบบ</button>
                    </div>
                </div>
            :
                <></>}
                {/* <div className="spinner-border text-lg" role="status">
                    <span className="sr-only"></span>
                </div> */}
        </div>
        {!regisStatus?<Link to="/#top" className="btn button-02 mb-5"><i className="bi bi-box-arrow-in-right"></i> เข้าสู่ระบบ </Link>:<></>}
        <ToastContainer theme="colored" autoClose={1000} />    
    </section>
  )
}

export default RegisterComponent
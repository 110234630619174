import React,{useContext, useEffect, useState} from "react";
import DataContext from "../Data/DataContext";
import LottoService from "../Services/Lotto.service";
import moment from "moment-timezone";
import $ from 'jquery'
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import "./css/Award.css";

const AwardComponent = () => {
  moment.tz.setDefault("Asia/Bangkok");
  const timeZone = "Asia/Bangkok";
  const lottoService = new LottoService()
  const dataC = useContext(DataContext)
  const localSet = {
    format: 'DD-MM-YYYY',
    "separator": " - ",
    "applyLabel": "ตกลง",
    "cancelLabel": "ยกเลิก",
    "fromLabel": "จาก",
    "toLabel": "ถึง",
    "monthNames": [  "มกราคม", "กุมภาพันธ์", "มีนาคม",  "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ],
    "daysOfWeek": ['อา','จ','อ','พ','พฤ','ศ','ส']
  }

  // const [dateFilter,setDateFilter] = useState(moment().format('YYYY-MM-DD'))
  const [awards,setAwards] = useState([])

  useEffect(()=>{
    fetchResult()
  },[])

  // useEffect(()=>{
  //   console.log(dateFilter)
  //   fetchResult(dateFilter)
  // },[dateFilter])

  const fetchResult = (date = moment().format('YYYY-MM-DD')) => {
    dataC.blockUI(true)
    // console.log(dataC.token)
    if(dataC.token){
      lottoService.listing_result2({p_date_open:date}).then((result)=>{
        dataC.blockUI(false)
        setAwards(result.data)
      })
    }
    else{
      lottoService.listing_result({p_date_open:date}).then((result)=>{
        dataC.blockUI(false)
        setAwards(result.data)
      })
    }
    
  }

  return (
    <>
      <section id="about" className="about">
        <div id="h-thai"></div>
        <div className="container" >
          <div className="col-lg-3 col-md-4 col-sm-4 m-auto p-auto">
            <div className="mt-2 input-group" >
              <DateRangePicker initialSettings={{singleDatePicker: true,showDropdowns: true,startDate: moment().toDate(), locale: localSet}} >
                {/* <input type="text" className="form-control text-center text-light bg-dark-skin" id="regis-date" /> */}
                <input type="text" className="form-control text-light bg-dark-skin text-center"  id="regis-date" aria-describedby="button-addon2" readOnly/>
              </DateRangePicker> 
              <button className="btn btn-warning" type="button" id="button-addon2" onClick={(e)=>fetchResult(moment($('#regis-date').val(),'DD-MM-YYYY').format('YYYY-MM-DD') )}>ค้นหา</button>
            </div>
          </div>
          
          <div>
            {awards.length<1?
            <h3 className="mt-5 text-on-background">ไม่มีผลรางวันสำหรับวันนี้</h3>
            :''}
            {awards.map((result,i)=>{
              return (
                <div key={i} id={'h'+result.lz_key}>
                  <div className="mt-3">
                    <div className="title-a">
                      <div className="d-flex justify-content-center">
                        <img alt="true"  className="mr-2 mt-1" height={24} src={`/images/icon/${parseInt(result.lz_key)>=101 && parseInt(result.lz_key)<=188 ? 'yiki':result.lz_key}.png`}  width={24} /> &nbsp; <h4 className="mb-1 cursor">{result.lz_name}</h4>
                      </div>
                      <div className="a-date">
                        <span>{moment(result.date_open).format('DD')} {dataC.monthThai[moment(result.date_open).format('MM')]} {parseInt(moment(result.date_open).format('YYYY'))+543}</span>
                      </div>
                    </div>
                  </div>
                  {result.num6tor?
                    <div className="a-frist mt-0">
                      <div className="justify-content-center row no-gutters">
                        <div>
                          {result.num6tor.split('').map((n,i)=>{
                            return <button className="a-number bg-light"  key={i}>{n}</button>
                          })}
                        </div>
                      </div>
                    </div>
                  :''}
                  
                  <div className=" a-second">
                    <div className="px-2">
                      <div className="px-1 row">
                        {result.num3Na1?
                          <div className="p-1 col" style={{  borderBottom: "1px solid rgb(255, 255, 255)",  borderRight: "1px solid rgb(255, 255, 255)" }}>
                            3 ตัวหน้า
                          </div>
                        :''}
                        {result.num3La1?
                          <div className="p-1 col" style={{ borderBottom: "1px solid rgb(255, 255, 255)",borderRight: "1px solid rgb(255, 255, 255)" }}>
                            3 ตัวหลัง
                          </div>
                        :''}
                        {result.num3tor?
                          <div className="p-1 col" style={{  borderBottom: "1px solid rgb(255, 255, 255)",  borderRight: "1px solid rgb(255, 255, 255)" }}>
                            {result.num3tor.split('').length==4?'4 ตัว':'3 ตัว'}
                          </div>
                        :''}
                        {result.num2tor?
                          <div className="p-1 col" style={{ borderBottom: "1px solid rgb(255, 255, 255)" }} >
                            2 ตัว{result.num3La1?'ล่าง':''}
                          </div>
                        :''}
                      </div>
                      <div className="px-1 row">
                        {result.num3Na1?
                          <div className="d-flex justify-content-center pt-2 px-1 col" style={{ borderRight: "1px solid rgb(255, 255, 255)" }}>
                            <button className="a-number bg-light px-2">{result.num3Na1==='*'?'คืนเงิน':result.num3Na1}</button>
                            <button className="a-number bg-light px-2">{result.num3Na2==='*'?'คืนเงิน':result.num3Na2}</button>
                          </div>
                        :''}
                        {result.num3La1?
                          <div className="d-flex justify-content-center pt-2 px-1 col" style={{ borderRight: "1px solid rgb(255, 255, 255)" }}>
                            <button className="a-number bg-light px-2">{result.num3La1==='*'?'คืนเงิน':result.num3La1}</button>
                            <button className="a-number bg-light px-2">{result.num3La2==='*'?'คืนเงิน':result.num3La2}</button>
                          </div>
                        :''}
                        {result.num3tor?
                          <div className="d-flex justify-content-center pt-2 px-1 col" style={{ borderRight: "1px solid rgb(255, 255, 255)" }}>
                            <button className="a-number bg-light px-2">{result.num3tor==='*'?'คืนเงิน':result.num3tor.split('').join(' ')}</button>
                          </div>
                        :''}
                        {result.num2tor?
                          <div className="d-flex justify-content-center pt-2 px-1 col">
                            <button className="a-number bg-light px-2">{result.num2tor==='*'?'คืนเงิน':result.num2tor.split('').join(' ')}</button>
                          </div>
                        :''}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        
      </section>
      {/* End About Section */}
    </>
  );
};

export default AwardComponent;

import React , {useState, useContext} from "react";
import  {Link} from 'react-router-dom'
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import play from "./../play";
import DataContext from '../Data/DataContext'

function ShortcutMenuComponent() {
  
  const dataC = useContext(DataContext)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: play().default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
 
  return (
    <>
      <section id="short-menu" className="">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <Link to="/play">
                <div className="icon-box play-box">
                  <div className="icon">
                  {/* <i className="bi bi-play-circle-fill"></i> */}
                  <Lottie options={defaultOptions} height={140} width={140} />
                  </div>
                  <h2>เข้าเล่น</h2>
                </div>
              </Link>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="row mx-0">
                {dataC.ag_con_outside == 1?<></>:
                  <div className="col px-1">
                    <Link to="/transfer/all">
                      <div className="icon-box ">
                        <div className="icon">
                          <i className="bi bi-cash-coin"></i>
                        </div>
                        <h5>ฝาก-ถอน</h5>
                      </div>
                    </Link>
                  </div>
                }
                <div className="col px-1">
                  <Link to="/play-history">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-list-stars"></i>
                      </div>
                      <h5>โพยหวย</h5>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col px-1">
                  <Link to="/awards">
                      <div className="icon-box">
                        <div className="icon">
                          <i className="bi bi-trophy-fill"></i>
                        </div>
                        <h5>ผลรางวัล</h5>
                      </div>
                    </Link>
                  </div>
                  {dataC.ag_con_outside == 1?<></>:
                    <>
                      <div className="col px-1">
                        <Link to="/af">
                          <div className="icon-box">
                            <div className="icon">
                              <i className="bi bi-people-fill"></i>
                            </div>
                            <h5>ชวนเพื่อน</h5>
                          </div>
                        </Link>
                      </div>
                      <div className="col px-1">
                        <a href={process.env.REACT_APP_LINE} target={'_blank'}>
                          <div className="icon-box">
                            <div className="icon">
                              <i className="bi bi-line"></i>
                            </div>
                            <h5>ติดต่อเรา</h5>
                          </div>
                        </a>
                      </div>
                    </>
                  }
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

export default ShortcutMenuComponent;

import AbstractService from "./Abstract.service";

export default class AssetsService extends AbstractService
{
    get_credit(): Promise<any>
    {
        return this.getAuth('/member/get-credit');
    }

    get_sum_aff(): Promise<any>
    {
        return this.getAuth('/member/sum-aff');
    }

    change_pass(payload:Object): Promise<any>
    {
        return this.postAuth('/member/change-pass',payload);
    }

}
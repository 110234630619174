import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import imgPopuo1 from './../images/pro1.jpg'

function AdsPopup() {
    const [notShowAgent, setNotShowAgent] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);  
    }

    useEffect(() => {
        // handleShow()
    }, [])
    


  return (
    <Modal show={show} onHide={handleClose} className='ads-modal'>
        <Modal.Body className='p-0'>
            <img src={imgPopuo1} alt='' className='w-100'/>
            <div className='text-center'>
              <button className='btn btn-sm btn-light m-2' onClick={handleClose}><i className="bi bi-x-circle"></i> ปิดหน้าต่าง</button>  
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default AdsPopup
import React, { useContext, useEffect, useState } from 'react'
import { useParams, Navigate,redirect,useNavigate } from "react-router-dom";
import DataContext from "../Data/DataContext";
import * as loadingData from "./../loading-sso.json";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import axios from 'axios';
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
const Swal = require('sweetalert2')

function AutoSignin(props) {
    const params = useParams();
    const dataC = useContext(DataContext)

    const navigate = useNavigate();
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingData.default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        localStorage.clear()
        dataC.auth(null)
        ssoLogin(params.pCode)
    }, [])

    const ssoLogin = async (p) =>{
        // console.log(encodeURIComponent(p))
        const response = await axios.get(`${dataC.ApiURL}/mauto/auth?p=${encodeURIComponent(p)}`,{},
            {
                header:{'Content-Type' : 'application/json'}
            }
        ).then(response => {
            // console.log(response.data)
            const member = response.data.data
            localStorage.setItem('token',response.data.access_token,60000)
            localStorage.setItem('ag_con_outside',member.ag_con_outside,60000)
            localStorage.setItem('ag_id',member.ag_id,60000)
            localStorage.setItem('m_af',member.m_af,60000)
            // localStorage.setItem('m_date_register',member.m_date_register,60000)
            // localStorage.setItem('m_group',member.m_group,60000)
            localStorage.setItem('m_key_web',member.m_key_web,60000)
            localStorage.setItem('m_money',member.m_money,60000)
            localStorage.setItem('m_money_af',member.m_money_af,60000)
            localStorage.setItem('m_money_af_total',member.m_money_af_total,60000)
            // localStorage.setItem('m_name',member.m_name,60000)
            // localStorage.setItem('m_phone',member.m_phone,60000)
            localStorage.setItem('m_status',member.m_status,60000)
            // localStorage.setItem('m_uid',member.m_uid,60000)
            localStorage.setItem('m_user',member.m_user,60000)
            // localStorage.setItem('mb_acc_name',member.mb_acc_name,60000)
            // localStorage.setItem('mb_acc_number',member.mb_acc_number,60000)
            // localStorage.setItem('mb_bank',member.mb_bank,60000)
            // dataC.blockUI(true)
            // toast.success("เข้าสู่ระบบสำเร็จ !!",{position: "top-center"});
            setTimeout(()=>{
                dataC.auth(response.data.access_token,member.ag_agent_array,member.ag_id,member.m_af,member.m_date_register,member.m_group,member.m_key_web,member.m_money,member.m_money_af,member.m_money_af_total,member.m_name,member.m_phone,member.m_status,member.m_uid,member.m_user,member.mb_acc_name,member.mb_acc_number,member.mb_bank,member.ag_con_outside)
                // dataC.blockUI(false)
                // redirect("/");
                // window.location.href = "/play";
                navigate("/play",{replace:true})
            },1000);
            
        }).catch(error=>{
            // console.log(error)
            // Swal.fire({
            //     title: 'เข้าสู่ระบบไม่สำเร็จ !!',
            //     text: 'กรุณาตรวจสอบรหัสผู้ใช้และรหัสผ่านให้ถูกต้อง',
            //     icon: 'warning',
            //     confirmButtonText: 'ตกลง',
            //     confirmButtonColor: 'green'
            // })
            toast.warning("เข้าสู่ระบบไม่สำเร็จ !!",{position: "top-center"});
            // window.location.href = "/login";
            // dataC.blockUI(true)
            setTimeout(()=>{
                // window.location.href = "/";
                window.history.back()
            },2000);
        });
    }
    
  return (
    <section id="login" className="d-flex align-items-center text-ligth mt-2" >
        
        <div className="container pt-5">
            <div className='row'>
                <div className='col-sm-8 col-12 text-left'>
                    <div className='my-5'>
                        <h2>
                            <div className="spinner-border text-warning me-3" role="status">
                                <span className="sr-only"></span>
                            </div>
                            กรุณารอสักครู่ ระบบกำลังพาท่านเข้าสู่ระบบ
                        </h2>
                    <img src={`/web/${process.env.REACT_APP_NAME}/logo_wide-v2.png`} alt="logo" className='w-100' style={{}}/>
                    </div>
                </div>
                {/* <div className='col-sm-4 col-8 p-auto m-auto'>
                    <Lottie options={defaultOptions} style={{width:'100%',zIndex:'0',marginTop:'-70px'}}/>
                </div> */}
            </div>
        </div>
        <ToastContainer theme="colored" autoClose={1000} />
    </section>
  )
}

export default AutoSignin

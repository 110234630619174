import React, { useState, useEffect, useContext } from 'react'
import $ from 'jquery'
import DataContext from '../../Data/DataContext';
import AssetsService from "../../Services/Assets.service";
import TransferService from '../../Services/Transfer.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import moment from 'moment';

function AfComponent() {
    const dataC = useContext(DataContext);
    const assetsService = new AssetsService()
    const transferService = new TransferService()

    const [ sumaff , setSumAff ] = useState({ "money_total_af": 0.00,"money_af": 0.00,"register_aff": "","user_af": []})
    const [ transferAff , setTransferAff ] = useState([])
    const [offset,setOffset] = useState(0)

    useEffect(()=>{
        getSumAff()
        fetchAffTransfer()
    },[])

    useEffect(()=>{
       // console.log(transferAff)
    },[transferAff])

    const getSumAff = ()=>{
        dataC.blockUI(true)
        assetsService.get_sum_aff().then((result)=>{
            dataC.blockUI(false)
            if(result.success){
              setSumAff(result.data)
              localStorage.setItem('m_af',result.data.register_aff,60000)
            }
        })
    }

    const fetchAffTransfer = (p_offset = 0) => {
        dataC.blockUI(true)
        transferService.listing({p_offset:p_offset,p_tf_type:3}).then((result)=>{
            dataC.blockUI(false)
            if(result.success){
                setTransferAff(result.data)
                setOffset(result.offset)
            }
        })
    }

    const fetchAffTransferMore = (p_offset = offset) => {
        dataC.blockUI(true)
        transferService.listing({p_offset:p_offset,p_tf_type:3}).then((result)=>{
            dataC.blockUI(false)
            if(result.success){
                setTransferAff([...transferAff,...result.data])
                setOffset(result.offset)
            }
        })
    }

    const withdrawAff = (e) => {
        transferService.withdraw_aff().then((result)=>{
            if(result.success){
                Swal.fire({
                    text: result.message,
                    icon:'success',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'green'
                })
                getSumAff()
            }else{
                Swal.fire({
                    text: result.message,
                    icon:'warning',
                    confirmButtonText: 'ตกลง',
                    confirmButtonColor: 'green'
                })
            }
        })
    }

    const copyLink = (textlink)=>{
        var copyText = textlink;
        navigator.clipboard.writeText(copyText);
        toast.success("คัดลอกสำเร็จ",{position: "top-center"});
    }


  return (
    <section id="transfer" className="mt-5 pt-5">
        <div className="container">
            <div className="text-center pb-3 pt-3 rounded-3 bg-dark-skin" >
                <span>แนะนำเพื่อน</span>
            </div>
            <div className="row">
                <div className="mt-2 col-lg-6 col-md-6 col-sm-6 col-6 col">
                    <div className="sc-fWIMVQ eZWyLw rounded-top py-2 bg-head-card" >
                        <span>จำนวนที่แนะนำ</span>
                    </div>
                    <div className="sc-fWIMVQ eZWyLw rounded-bottom bg-light py-2" style={{}}>
                        <b className="text-dark">{sumaff.user_af.length}</b>
                    </div>
                </div>
                <div className="mt-2 col-lg-6 col-md-6 col-sm-6 col-6 col">
                    <div className="sc-fWIMVQ eZWyLw rounded-top py-2 bg-head-card" >
                        <span>รายได้</span>
                    </div>
                    <div className="sc-fWIMVQ eZWyLw rounded-bottom bg-light py-2" style={{}}>
                        <b className="text-dark"> ฿ {dataC.money.format(sumaff.money_total_af)}</b>
                    </div>
                </div>
            </div>

            <div className="pt-2 pb-5">
                <div className="text-center bg-light p-3 text-dark" style={{borderTopLeftRadius: 8, borderTopRightRadius: 8, textOverflow: 'ellipsis', overflow: 'hidden'}}>
                    <small>{sumaff.register_aff}</small>
                </div>
                <div className="text-center p-2 btn-af-copy" style={{ cursor: 'pointer'}}>
                    <button type="button" className="text-light btn btn-link btn-block btn-sm" onClick={(e)=>copyLink(sumaff.register_aff)}>
                        <i aria-hidden="true" className="fa fa-files-o" /> 
                        <small>คัดลอกลิงค์แนะนำเพื่อน</small>
                    </button>
                </div>
                <a href={"https://www.facebook.com/sharer/sharer.php?u="+sumaff.register_aff} target="_blank">
                    <div className="text-center p-2 mb-3 btn-facebook">
                        <small className="text-light"> <i aria-hidden="true" className="bi bi-facebook" /> แชร์ Facebook</small>
                    </div>
                </a>
                <a href={"https://social-plugins.line.me/lineit/share?url="+sumaff.register_aff} target="_blank">
                    <div className="text-center p-2 mb-3 btn-line" >
                        <small className="text-light"><i aria-hidden="true" className="bi bi-line" /> แชร์ Line</small>
                    </div>
                </a>
            </div>

           <div className="p-3 bg-light text-dark" style={{borderRadius:'8px'}}>
               <div className="text-left py-2 p-3" style={{fontSize: '1.4em'}}>
                   <span className="pl-2">แจ้งถอนรายได้</span>
                </div>
                <hr className="mt-0 mb-3" />
                <small className="text-muted labelAlertReferal form-text">รายได้ ระบบแนะนำ จะถอนเข้าเป็นเงินเครดิต หากสงสัย <br />โปรดติดต่อเจ้าหน้าที่ตลอด 24 ชม.</small>
                <div className="mt-4">
                    <div className="mb-1">รายได้ปัจจุบัน - คงเหลือ</div>
                    <h4 className="thb text-primary">
                        <span><b>฿ {dataC.money.format(sumaff.money_af)}</b></span>
                    </h4>
                </div>
                <button type="submit" className="mt-0 rounded-0 btn bg-dark-skin btn-block" onClick={withdrawAff}>แจ้งถอนรายได้</button>
                <div>
                    <div className="text-left pt-0 pb-2 mt-3" style={{fontSize: '1.4em'}}>
                        {/* <span className="pl-2">แจ้งถอนรายได้</span> */}
                    </div>
                    {transferAff.length>0?
                        <div className="table-responsive">
                            <table className="table table-hover fs-6">
                                <thead>
                                    <tr style={{fontSize: '0.8em'}}>
                                        <th className="text-left" style={{width: "10%"}}>ลำดับ</th>
                                        <th className="text-left" style={{width: '35%'}}>วัน-เวลาการถอน</th>
                                        <th className="text-right" style={{width: '35%'}}>จำนวนเงินที่ถอน</th>
                                        <th className="text-right" style={{width: '20%'}}>สถานะ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferAff.map((transfer,i)=>{
                                        return (
                                            <tr key={i} style={{fontSize: '0.8em'}}>
                                                <td>{i+1}</td>
                                                <td>{moment(transfer.tf_time).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                <td>{dataC.money.format(transfer.tf_money)}</td>
                                                <td><span className={`badge text-${transfer.tf_status==1?'success':'warning'}`}>{transfer.tf_status_name}</span></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    :
                        <p className="mt-4">ไม่พบข้อมูล</p>
                    }
                    <button className='btn btn-sm btn-warning' onClick={(e)=>{fetchAffTransferMore(offset)}}>แสดงข้อมูลเพิ่มเติม</button>
                </div>
            </div>

        </div>
        <ToastContainer theme="colored" autoClose={1000}/>
    </section>
  )
}

export default AfComponent
import AbstractService from "./Abstract.service";

export default class TransferService extends AbstractService
{
    listing(payload:Object): Promise<any>
    {
        return this.postAuth('/transfer',payload);
    }

    listing_bank(): Promise<any>
    {
        return this.getAuth('/deposit/bank');
    }

    getting_match(): Promise<any>
    {
        return this.getAuth('/deposit/check-match');
    }

    depositing(payload:Object): Promise<any>
    {
        return this.postAuth('/deposit/match',payload);
    }

    upload_slip(payload:Object): Promise<any>
    {
        return this.postAuth('/deposit/up-slip',payload);
    }

    withdrawing(payload:Object): Promise<any>
    {
        return this.postAuth('/withdraw/make-with',payload);
    }

    withdraw_aff(): Promise<any>
    {
        return this.postAuth('/withdraw/aff');
    }
}
import React,{useContext,useState} from 'react'
import  {Link} from 'react-router-dom'
import $ from 'jquery'
import MenuComponent from './MenuComponent';
import MenuMobile from './MenuMobile';
import DataContext from '../Data/DataContext'
import AssetsService from "../Services/Assets.service";

const HeaderComponent = () => {
    const dataC = useContext(DataContext)
    const assetsService = new AssetsService()
    const [ spinner,setSpinner ] =  useState(false);
    const getCredit= (e)=>{
        setSpinner(true)
        assetsService.get_credit().then((result)=>{
          if(result.success){
            localStorage.setItem('m_money',result.data.data.m_money,60000)
            localStorage.setItem('m_money_af',result.data.data.m_money_af,60000)
            localStorage.setItem('m_money_af_total',result.data.data.m_money_af_total,60000)
            dataC.setCredit(result.data.data.m_money,result.data.data.m_money_af,result.data.data.m_money_af_total)
          }
          setTimeout(function (){
            setSpinner(false) 
          }, 100);
        })
      }
    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className="fixed-top">
            <div className={`container${dataC.ag_con_outside == 1?'':'-fluid'} d-flex align-items-center justify-content-between`}>
                {/* <h1 className="logo">
                    <a href="index.html">
                    <span className="text-red">Madam</span>
                    <span className="text-green">Huay</span>
                    </a>
                </h1> */}
                <Link to="/">
                    <div className="logo">
                        <img src={`/web/${process.env.REACT_APP_NAME}/logo_wide-v2.png`} alt={"logo"}/>
                    </div>
                </Link>
                {/* Uncomment below if you prefer to use an image logo */}
                {/* <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>*/}
                <nav id="navbar" className="navbar">
                        {dataC.token?
                            <span className="navbar-brand" href="#">
                                    <span className="btn btn-light btn-sm">
                                        &nbsp;{dataC.money.format(dataC.m_money)}
                                        {spinner?
                                        <small> <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </small>
                                            : <> <span className=""><i className="bi bi-arrow-repeat" onClick={getCredit}></i></span></>
                                        }
                                    </span>
                            </span>
                        :<></>}
                        <MenuComponent/>
                        <i className="bi bi-list mobile-nav-toggle"  data-bs-toggle="modal" data-bs-target="#menu-mobile"/>
                    </nav>{/* .navbar */}
            </div>
            </header>{/* End Header */}
            <MenuMobile/>
        </>
    );
}

export default HeaderComponent;
import React,{ useEffect, useState, useContext,useRef } from 'react'
// import '../css/Transaction.css'
import TransferRecordComponent from './TransferRecordComponent'
import TransferService from '../../Services/Transfer.service'
import DataContext from "../../Data/DataContext";
import { useParams} from 'react-router-dom'

function TransferComponent() {
    const transferService = new TransferService()
    const dataC = useContext(DataContext)
    const [type,setType] = useState()
    const [transferData,serTransfer] = useState([])
    const [pageOffset,setPageOffset] = useState(0)

    const { tType } = useParams()

    const [ transferType,setTransferType ] = useState(tType)

    // const firstUpdate = useRef(true);
    useEffect(()=>{
        // if (firstUpdate.current) {
        //     firstUpdate.current = false;
        //     return;
        // }
        fetchTransfer(0,transferType=='all'?0:(transferType=='deposit'?1:(transferType=='withdraw'?2:0)))
    },[])

    useEffect(()=>{
        if(type != undefined){
            fetchTransfer(0,type)
        }  
    },[type])

    const fetchTransfer = (page = 0,tf_type=0 )=>{
        const payload = {
            p_offset: page,
            p_tf_type: tf_type
        }
        dataC.blockUI(true)
        transferService.listing(payload).then((result)=>{
            // dataC.blockUI(false)
            // console.log(result)
            setTimeout(function (){
                dataC.blockUI(false) 
            }, 500);
            if(result.success){
                serTransfer(result.data)
                setPageOffset(result.offset)
            }
        })
    }

    const fetchTransferMore = (page = 0,tf_type=0 )=>{
        const payload = {
            p_offset: page,
            p_tf_type: tf_type
        }
        dataC.blockUI(true)
        transferService.listing(payload).then((result)=>{
            dataC.blockUI(false)
            // console.log(result)
            if(result.success){
                let t = transferData
                t = [...t,...result.data]
                serTransfer(t)
                setPageOffset(result.offset)
            }
        })
    }
  return (
    <section id="transfer" className="mt-3">
        <div className="container">
            <div>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={"nav-link w-100 "+(transferType=='all'?'active':'')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={()=>{setType(0)}}><i className="bi bi-cash-stack"></i> ทั้งหมด</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={"nav-link "+(transferType=='deposit'?'active':'')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={()=>{setType(1)}}><i className="bi bi-cash-coin"></i> ฝาก</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={"nav-link "+(transferType=='withdraw'?'active':'')} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={()=>{setType(2)}}><i className="bi bi-cash"></i> ถอน</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className={"tab-pane fade "+(transferType=='all'?'show active':'')} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        {/* All */}
                        <TransferRecordComponent data={transferData}/>
                    </div>
                    <div className={"tab-pane fade "+(transferType=='deposit'?'show active':'')} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        {/* deposit history */}
                        <TransferRecordComponent data={transferData}/>
                    </div>
                    <div className={"tab-pane fade "+(transferType=='withdraw'?'show active':'')} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        {/* withdraw history */}
                        <TransferRecordComponent data={transferData}/>
                    </div>
                </div>
            </div>
            <button className='btn btn-warning my-2' onClick={()=>{fetchTransferMore(pageOffset,type)}}>แสดงรายการเพิ่มเติม</button>
        </div>
    </section>
  )
}

export default TransferComponent
import React,{ useState,useContext, useEffect} from "react";
import DataContext from "../../Data/DataContext";
import  {Link} from 'react-router-dom'
import AssetsService from "../../Services/Assets.service";
// import "./../css/Wallet.css";

function CreditBalance() {
    const dataC = useContext(DataContext)
    const assetsService = new AssetsService()

    const [ spinner,setSpinner ] =  useState(false)

    const bank = dataC.arrBank.filter((b,i)=>{
      return b.id == dataC.mb_bank
    })[0]

    useEffect(()=>{
        getCredit()
        // console.log(bank)
    },[])
    
    const getCredit= (e)=>{
        setSpinner(true)
        assetsService.get_credit().then((result)=>{
          setSpinner(false)
          if(result.success){
            localStorage.setItem('m_money',result.data.data.m_money,60000)
            localStorage.setItem('m_money_af',result.data.data.m_money_af,60000)
            localStorage.setItem('m_money_af_total',result.data.data.m_money_af_total,60000)
            dataC.setCredit(result.data.data.m_money,result.data.data.m_money_af,result.data.data.m_money_af_total)
          }
        })
    }

  return (
    <section id="credit" className="">
        <div className="container ">
            <div className="row">
              <div className="col-md-12 col-12 box-credit" >
                <div className="wallet-card text-center bg-gradient-main p-3 rounded-3 min-vh-50">
                    <div>ยอดเครดิตคงเหลือ 
                    {spinner?
                      <> <div className="spinner-border spinner-border-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </>
                        : <> <span className=""><i className="bi bi-arrow-repeat" onClick={getCredit}></i></span></>
                      }
                    </div>
                    <h1>฿ {dataC.money.format(dataC.m_money)} </h1>
                    <div className="btn-wallet row">
                        <div className="col col-6"><Link to={'/deposit'} className="btn btn-warning" > <i className="bi bi-cash-coin"></i> ฝากเงิน</Link></div>
                        <div className="col col-6"><Link to={'/withdraw'} className="btn btn-warning"> <i className="bi bi-dash-circle"></i> ถอนเงิน</Link></div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default CreditBalance
import React, {useContext, useEffect, useState, useRef} from "react";
import $ from 'jquery'
import  {Link} from 'react-router-dom'
import DataContext from "../../Data/DataContext";
// import moment from "moment";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
// import { format, zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

function GameTypeComponent(props) {
  // moment.tz("Asia/Bangkok");
  moment.tz.setDefault("Asia/Bangkok"); 
  const timeZone = "Asia/Bangkok";
  const dataC = useContext(DataContext)
  const LottoZone = props.gametype;
  const [showYiki, setShowYiki] = useState(props.params.yiki)
  const yiki = useRef(null)
  const back = useRef(null)
  // const [lotYiki ,setLotYiki] = useState([])
  useEffect(()=>{
        if(showYiki){
          // yiki.current.scrollIntoView();
          window.scrollTo(0, 0);
        }
  },[showYiki])

  const backToList = () => {
      // back.current.scrollIntoView();
  }

  // const game_panel = LottoZone.map((zone) => {
    
   
    // var start_date = '';
    // var stop_date =  '';
    // var current_date = '';

    

    

    // console.log(start_date,stop_date,current_date)

    

    // const executeScroll = () => yiki.current.scrollIntoView();
 
    
    return (
      <div ref={yiki}>
        {/* {moment().tz("America/Los_Angeles").format()} */}
      {!showYiki?
        //TODO #####  หวยอื่น
        (LottoZone.map((games,index)=>
          (
            <div className="row game-panal " key={uuidv4()}>
              <div><p className="game-title"><img src={`/images/g-icon/${games.GameTypeID}.png`}/>{games.GameTypeName}</p></div>
              {
                games.GameTypeID == '9'?
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 px-1 " ref={back} key={uuidv4()}>
                  <Link to={'#'} onClick={()=>{setShowYiki(true); }}>
                    <div className="cover-box-huay2" style={{}}>
                      <div
                        className={"cover-top-box-huay2 "}
                        style={{
                          backgroundImage: "url('/images/lotbg/yiki.webp?v=2`)"
                        }}
                      >
                        <div className=" top-box-huay" style={{ display: "flex" }}>
                          <div className=" cover-flag">
                            <img
                              alt="logo"
                              className="imgLotto"
                              src={`/images/icon/yiki.png`}
                            />
                          </div>
                          <div className=" cover-text"  style={{ width: "100%", textAlign: "right" }}  >
                            <div className="textNameHuay" >
                              <span className="font-zone">เข้าเล่น {games.GameTypeName}</span>
                            </div>
                            <div className="font-size-small">
                            
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="count-down">
                        <span>
                          <span> เข้าเล่น </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                  </>
                :<></>
              }
              {games.GameTypeItem.map((zone,i)=>{
                  if(zone.lg_id == '9' ) {
                    return <></>
                  }
                  var start_date = new Date(zone.op_date_time_start).getTime();
                  var stop_date = new Date(zone.op_date_time_stop).getTime();
                  var current_date = new Date().getTime();

                  if(current_date>=start_date&&current_date<=stop_date){
                    countDownDate(zone.op_date_time_stop,'time_'+zone.lz_key+zone.op_id);
                  }else{
                    $('#time_'+zone.lz_key+zone.op_id).html('ปิดรับแทง');
                  }
                return (
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 px-1" key={uuidv4()}>
                  <Link to={(current_date>=start_date&&current_date<=stop_date)?`/launch_game/${zone.op_id}`:"#"} onClick={()=>{dataC.selectZone(zone)}}>
                    <div className={"cover-box-huay2 time_"+ zone.lz_key+zone.op_id} style={{filter:(current_date>=start_date&&current_date<=stop_date)?"grayscale(0)":"grayscale(1)"}}>
                      <div
                        className={"cover-top-box-huay2 "+(zone.lg_id===7?"h-vip":'')}
                        style={{
                          backgroundImage: "url('"+zone.lotimg+"')"
                        }}
                      >
                        <div className=" top-box-huay" style={{ display: "flex" }}>
                          <div className=" cover-flag">
                            <img
                              alt="logo"
                              className="imgLotto"
                              src={zone.loticon}
                            />
                          </div>
                          <div className=" cover-text"  style={{ width: "100%", textAlign: "right" }}  >
                            <div className="textNameHuay" >
                              {/* style={{filter: "drop-shadow(black 2px 2px 1px)",textShadow:"black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px", whiteSpace: "nowrap"}} */}
                              <span className="font-zone">{zone.lz_name}</span>
                              <div className="font-size-small">เริ่ม : {moment(zone.op_date_time_start).format('DD-MM-YYYY HH:mm')}</div>
                              <div className="font-size-small">ปิด : {moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm')}</div>
                            </div>
                            <div className="font-size-small">
                            {zone.g_des}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="count-down">
                        <span>
                          <span id={'time_'+zone.lz_key+zone.op_id}>{!zone.op_closed?moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm:ss'):"ปิดรับแทง"} </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                )
              })}
              
              
            </div>
          
          )
        )
        )
        :
        //TODO #####  หวยยี่กี่
        (LottoZone.map((games,index)=>{
          if(games.GameTypeID != 9) return;


          return (
            <div className="row game-panal "  key={uuidv4()}>
              <div>
                <button className="btn btn-sm btn-warning float-end" onClick={()=>{setShowYiki(false);backToList();}}><i className="bi bi-chevron-double-left"></i> ย้อนกลับ</button>
                <p className="game-title"><img src={`/images/g-icon/${games.GameTypeID}.png`}/>{games.GameTypeName}</p>
              </div>
              {games.GameTypeItem.map((zone,i)=>{
                  var start_date = moment.tz(zone.op_date_time_start, timeZone);
                  var stop_date = moment.tz(zone.op_date_time_stop, timeZone);
                  var current_date = moment.tz(timeZone);

                  if(!(current_date>=start_date&&current_date<=stop_date)) return;

                  if(current_date>=start_date&&current_date<=stop_date){
                    countDownDate(zone.op_date_time_stop,'time_'+zone.lz_key+zone.op_id);
                  }else{
                    $('#time_'+zone.lz_key+zone.op_id).html('ปิดรับแทง');
                  }
                return (
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 px-1" key={uuidv4()}>
                  <Link to={(current_date>=start_date&&current_date<=stop_date)?`/launch_game/${zone.op_id}`:"#"} onClick={()=>{dataC.selectZone(zone)}}>
                    <div className={"cover-box-huay2 time_"+ zone.lz_key+zone.op_id} style={{filter:(current_date>=start_date&&current_date<=stop_date)?"grayscale(0)":"grayscale(1)"}}>
                      <div
                        className={"cover-top-box-huay2 "+(zone.lg_id===7?"h-vip":'')}
                        style={{
                          backgroundImage: "url('"+zone.lotimg+"')"
                        }}
                      >
                        <div className=" top-box-huay" style={{ display: "flex" }}>
                          <div className=" cover-flag">
                            <img
                              alt="logo"
                              className="imgLotto"
                              src={zone.loticon}
                            />
                          </div>
                          <div className=" cover-text"  style={{ width: "100%", textAlign: "right" }}  >
                            <div className="textNameHuay" >
                              {/* style={{filter: "drop-shadow(black 2px 2px 1px)",textShadow:"black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px", whiteSpace: "nowrap"}} */}
                              <span className="font-zone">{zone.lz_name}</span>
                              <div className="font-size-small">เริ่ม : {moment(zone.op_date_time_start).format('DD-MM-YYYY HH:mm')}</div>
                              <div className="font-size-small">ปิด : {moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm')}</div>
                            </div>
                            <div className="font-size-small">
                            {zone.g_des}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="count-down">
                        <span>
                          <span id={'time_'+zone.lz_key+zone.op_id}>{!zone.op_closed?moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm:ss'):"ปิดรับแทง"} </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                )
              })}
              {games.GameTypeItem.map((zone,i)=>{
                  var start_date = moment.tz(zone.op_date_time_start, timeZone);
                  var stop_date = moment.tz(zone.op_date_time_stop, timeZone);
                  var current_date = moment.tz(timeZone);

                  if((current_date>=start_date&&current_date<=stop_date)) return;

                  if(current_date>=start_date&&current_date<=stop_date){
                    countDownDate(zone.op_date_time_stop,'time_'+zone.lz_key+zone.op_id);
                  }else{
                    $('#time_'+zone.lz_key+zone.op_id).html('ปิดรับแทง');
                  }
                return (
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 px-1" key={uuidv4()}>
                  <Link to={(current_date>=start_date&&current_date<=stop_date)?`/launch_game/${zone.op_id}`:"#"} onClick={()=>{dataC.selectZone(zone)}}>
                    <div className={"cover-box-huay2 time_"+ zone.lz_key+zone.op_id} style={{filter:(current_date>=start_date&&current_date<=stop_date)?"grayscale(0)":"grayscale(1)"}}>
                      <div
                        className={"cover-top-box-huay2 "+(zone.lg_id===7?"h-vip":'')}
                        style={{
                          backgroundImage: "url('"+zone.lotimg+"')"
                        }}
                      >
                        <div className=" top-box-huay" style={{ display: "flex" }}>
                          <div className=" cover-flag">
                            <img
                              alt="logo"
                              className="imgLotto"
                              src={zone.loticon}
                            />
                          </div>
                          <div className=" cover-text"  style={{ width: "100%", textAlign: "right" }}  >
                            <div className="textNameHuay" >
                              {/* style={{filter: "drop-shadow(black 2px 2px 1px)",textShadow:"black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px", whiteSpace: "nowrap"}} */}
                              <span className="font-zone">{zone.lz_name}</span>
                              <div className="font-size-small">เริ่ม : {moment(zone.op_date_time_start).format('DD-MM-YYYY HH:mm')}</div>
                              <div className="font-size-small">ปิด : {moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm')}</div>
                            </div>
                            <div className="font-size-small">
                            {zone.g_des}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="count-down">
                        <span>
                          <span id={'time_'+zone.lz_key+zone.op_id}>{!zone.op_closed?moment(zone.op_date_time_stop).format('DD-MM-YYYY HH:mm:ss'):"ปิดรับแทง"} </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                )
              })}
              
            </div>
          
          )
        }
          
        )
        )}
        <div ref={back}></div>
      </div>
      
    );
  // })
  //   return game_panel;
}

export default GameTypeComponent;


function countDownDate(date,_id){
  var x = setInterval(function() {

    const timeZone = "Asia/Bangkok";
    // Set the date we're counting down to
    // var stopDate = new Date(date).getTime();
    const stopDate = moment.tz(date, timeZone);

    
    // Get today's date and time
    // var now = new Date().getTime();
    const now = moment.tz(timeZone);
    
    
      
    // Find the distance between now and the count down date
    var distance = stopDate - now;
    // console.log(distance)
      
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if(!document.getElementById(_id)){
      clearInterval(x);
      return false;
    }
      
    // Output the result in an element with id="demo"
    document.getElementById(_id).innerHTML = (days>0?(days + " วัน "):'') + (hours.toString().length<2?'0'+hours.toString():hours.toString()) + ":" + (minutes.toString().length<2?'0'+minutes.toString():minutes.toString()) + ":" + (seconds.toString().length<2?'0'+seconds.toString():seconds.toString()) + "";
    
    // If the count down is over, write some text 
    if (distance < 0) {
      clearInterval(x);
      document.getElementById(_id).innerHTML = "ปิดรับแทง";
      $('.'+_id).attr('style','filter: grayscale(1);')
    }
  }, 1000);
}

import React,{ useState,useContext, useEffect} from "react";
import $ from 'jquery'
import DataContext from "../Data/DataContext";
import  {Link} from 'react-router-dom'
import AssetsService from "../Services/Assets.service";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function WalletComponent() {
  const dataC = useContext(DataContext)
  const assetsService = new AssetsService()

  const [ spinner,setSpinner ] =  useState(false)

  useEffect(()=>{
    getCredit()
    getSumAff()
  },[])

  const getCredit= (e)=>{
    setSpinner(true)
    assetsService.get_credit().then((result)=>{
      if(result.success){
        localStorage.setItem('m_money',result.data.data.m_money,60000)
        localStorage.setItem('m_money_af',result.data.data.m_money_af,60000)
        localStorage.setItem('m_money_af_total',result.data.data.m_money_af_total,60000)
        dataC.setCredit(result.data.data.m_money,result.data.data.m_money_af,result.data.data.m_money_af_total)
      }
      setTimeout(function (){
        setSpinner(false) 
      }, 100);
    })
  }

  const getSumAff = ()=>{
    assetsService.get_sum_aff().then((result)=>{
        if(result.success){
          localStorage.setItem('m_af',result.data.register_aff,60000)
          dataC.setAffLink(result.data.register_aff)
        }
    })
}

const copyLink = (text)=>{
  let copyText = text;
  navigator.clipboard.writeText(copyText);
  toast.success("คัดลอกสำเร็จ",{position: "top-center"});
}
  return (
    <>
      
      <section id="wallet" className="">
        <div className="container">
          <div className="wallet-card">
            <div className="row">
                <div className="col-lg-6 col-md-6 box-credit">
                    <div>ยอดเครดิตคงเหลือ 
                      {spinner?
                      <> <div className="spinner-border spinner-border-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </>
                        : <> <span className=""><i className="bi bi-arrow-repeat" onClick={getCredit}></i></span></>
                      }
                    </div>
                    <h1>฿ {dataC.money.format(dataC.m_money)} </h1>
                    {dataC.ag_con_outside == 1?<></>:
                    <div className="btn-wallet row">
                        <div className="col col-6"><Link to={'/deposit'} className="btn btn-warning" > <i className="bi bi-cash-coin"></i> ฝากเงิน</Link></div>
                        <div className="col col-6"><Link to={'/withdraw'} className="btn btn-warning"> <i className="bi bi-dash-circle"></i> ถอนเงิน</Link></div>
                    </div>
}
                </div>
              <div className={"col-lg-6 col-md-6 box-info "+(dataC.ag_con_outside == 1?'mb-0':'')}>
                    
                    <h3 className={"full-name "+(window.innerWidth>768&&dataC.ag_con_outside == 1?'mt-4':'')}><i className="bi bi-award"></i> {dataC.ag_con_outside == 1?dataC.m_user:dataC.mb_acc_name}</h3>
                    {/* <p><span className="btn btn-sm btn-warning rounded-4  w-100" onClick={()=>{copyLink(dataC.m_user)}} style={{cursor:'pointer'}}><i className="bi bi-person-fill"></i> : {dataC.m_user}</span></p> */}
                    {/* <p className="text-break" style={{borderTopLeftRadius: 8, borderTopRightRadius: 8, textOverflow: 'ellipsis', overflow: 'hidden'}}>
                      {dataC.m_af} 
                      <span className="badge badge-success" onClick={()=>{copyLink(dataC.m_af)}} style={{cursor:'pointer'}}><i className="bi bi-files" ></i> คัดลอก</span>
                    </p> */}
                    {dataC.ag_con_outside == 1?<></>:
                      <>
                        <p><span className="btn btn-sm btn-warning rounded-4  w-100" onClick={()=>{copyLink(dataC.m_user)}} style={{cursor:'pointer'}}><i className="bi bi-person-fill"></i> : {dataC.m_user}</span></p>
                        <div className="text-center p-1 af-link text-break" style={{borderTopLeftRadius: 8, borderTopRightRadius: 8, textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            <small>{dataC.m_af} </small>
                        </div>
                        <div className="text-center p-0 btn-af-copy" style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, cursor: 'pointer'}}>
                            <button type="button" className="text-light btn btn-link btn-block btn-sm" onClick={(e)=>{copyLink(dataC.m_af)}}>
                                <i aria-hidden="true" className="fa fa-files-o" /> 
                                <small>คัดลอกลิงค์ชวนเพื่อน</small>
                            </button>
                        </div>
                      </>
                    }
              </div>
            </div>
          </div>
        </div>
        <ToastContainer theme="colored" autoClose={1000} />
      </section>
    </>
  );
}

export default WalletComponent;

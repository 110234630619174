import React, {useEffect, useState,useContext,useRef} from "react";
import { useParams } from "react-router-dom";
// import "../css/GameList.css";
import GameTypeComponent from "./GameTypeComponent";
import LottoService  from "../../Services/Lotto.service"; 
import DataContext from "../../Data/DataContext";
const Swal = require('sweetalert2')

function GameListComponent() {
  const dataC = useContext(DataContext)
  const lottoService = new LottoService()
  const [GameTypeState,setGameTypeState] = useState([]);
  const [lotGroup,setLotGroup] = useState([]);

  const params = useParams();
  
  // const firstUpdate = useRef(true);
  useEffect(()=>{
    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   return;
    // }
    fetchLotgroup()
    console.log(params)
  },[])

  useEffect(()=>{
    // fetchLotzone()
    if(lotGroup.length>0){
      fetchLotOpen()
    }
  },[lotGroup])

  const fetchLotgroup = ()=>{
    dataC.blockUI(true)
    lottoService.listing_lotgroup().then((result)=>{
        // dataC.blockUI(false)
        // // console.log(result)
        setLotGroup(result.data)
    })
  }

  // const fetchLotzone = ()=>{
  //     // console.log('getting')
  //     lottoService.listing_zone().then((result)=>{
  //       if (result.success){
  //         // console.log(result)
  //         const group_lottoZone = []
  //         result.data.map((lotzone)=>{
  //             group_lottoZone[lotzone.lg_id] = []
  //         })
  //         result.data.map((lotzone)=>{
  //             group_lottoZone[lotzone.lg_id].push(lotzone)
  //         })
  //         setGameTypeState(group_lottoZone.map((g_lotto)=>{
  //             // // console.log(g_lotto)
  //             const lg_od = lotGroup.filter((re,i)=>{
  //                 return re.lg_id == g_lotto[0].lg_id
  //             })[0]
  //             // // console.log(lg_od)
  //             return { 
  //               GameTypeName : lg_od.lg_name,
  //               GameTypeItem : g_lotto.map((lotzone)=>{
  //                 return {...lotzone,loticon:`/images/icon/${lotzone.lz_key}.png?v=1`,lotimg:`/images/lotbg/${lotzone.lz_key}.webp?v=1`}
  //               })
  //             }
  //         }))
  //       }else{
  //         Swal.fire({
  //           title: result.message,
  //           text:'',
  //           icon: 'warning',
  //           confirmButtonText: 'ตกลง',
  //           confirmButtonColor: '#890000',
  //         })
  //       }
  //   });
  // }

  const fetchLotOpen = ()=>{
    dataC.blockUI(true)
    lottoService.listing_lot_open().then((result)=>{
      setTimeout(function (){
          dataC.blockUI(false) 
      }, 1000);
      if (result.success){
        // console.log(result)
        const group_lottoOpen = []
        // result.data.map((lotopen)=>{
        //   group_lottoOpen[lotopen.lg_id] = []
        // })
        lotGroup.map((group)=>{
          // console.log(group.lg_id)
          const item = result.data.filter((re,i)=> re.lg_id == group.lg_id)
          group_lottoOpen.push({GameTypeName:group.lg_name, GameTypeID:group.lg_id, GameTypeItem:item})
        })
        // result.data.map((lotopen)=>{
        //   group_lottoOpen[lotopen.lg_id]['GameTypeItem'].push(lotopen)
        // })
        
        // console.log(group_lottoOpen)
        const lp = group_lottoOpen.map((g_lotto)=>{
            // const lg_od = lotGroup.filter((re,i)=>{
            //     return re.lg_id == g_lotto[0].lg_id
            // })[0]
          // console.log(lg_od)
            return { 
              ...g_lotto,
              GameTypeItem : g_lotto.GameTypeItem.map((l_open)=>{
                return {...l_open,loticon:`/images/icon/${parseInt(l_open.lz_key)>=101 && parseInt(l_open.lz_key)<=188?'yiki':l_open.lz_key}.png?v=2`,lotimg:`/images/lotbg/${parseInt(l_open.lz_key)>=101 && parseInt(l_open.lz_key)<=188?'yiki':l_open.lz_key}.webp?v=2`}
              })
            }
        })
        setGameTypeState(lp)
        // console.log(GameTypeState)
        
      }else{
        Swal.fire({
          text: result.message,
          icon: 'warning',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#890000',
        })
      }
  });
}
  
  // const renderGameList =  GameTypeState.map((games,index)=>
  //   (
     
  //           <div className="row game-panal" key={index}>
  //             <div><p className="game-title">{games.GameTypeName}</p></div>
  //             <GameTypeComponent gametype={games.GameTypeItem} />
  //           </div>
     
  //   )
  // )

  return (
    <section id="game-list" className="">
          <div className="container">
            {/* {GameTypeState.map((games,index)=>
                (
                  <div className="row game-panal" key={index}>
                    <div><p className="game-title">{games.GameTypeName}</p></div>
                    <GameTypeComponent gametype={games.GameTypeItem} />
                  </div>
                
                )
              )} */}
              <GameTypeComponent gametype={GameTypeState} params={params} />
          </div>
        </section>
  )
}

export default GameListComponent;
